
// Tipo de equipamento de cada empresa
export interface EquipmentType {
  id: string;
  reference: string | null;
  checkbox: boolean;
}

// Instalação de cada empresa
export class Installation {
  constructor(
    public id: string | null,
    public reference: string | null
  ) { }
}

// Gateway de cada empresa
export class Gateway {
  constructor(
    public id: string | null,
    public reference: string | null
  ) { }
}

// Comandos da tabela
export class Command {
  constructor(
    public id: string,
    public parameter: string | null,
    public queryType: string | null,
    public requestDt: string,
    public type: string,
    public user: User,
    public equipmentsQueued: number,
    public value: string | null,
    public equipments: Array<Equipment>
  ) { }
}

// Usuário que requeriu cada comando ou consulta
export class User {
  constructor(
    public firstName: string | null,
    public lastName: string | null
  ) { }
}

// Equipamentos existentes em cada comando
export class Equipment {
  constructor(
    public id: string | null,
    public reference: string | null,
    public parameterReference: string | null,
    public macAddress: string | null,
    public status: string | null,
    public responseGenerationDt: string,
    public responseReceiptDt: string,
    public commandReference: string | null,
    public layout: string | null,
    public responseData: ResponseData,
    public commands: Command,
  ) { }
}
/** Status especificos de alguns comandos **/
export class ResponseData {
  constructor(
    public relay: string | null,
    public ir: number | null,
    public tga: number | null,
    public tge: number | null,
    public tgm: number | null,
    public tc: number | null,
    public ts: number | null,
    public has_digital_measurement: string | null,
    public has_ldr: string | null,
    public ldr_priority: string | null,
    public lux_switch_on_value: number | null,
    public lux_switch_off_value: number | null,
    public ldr_check_interval: string | null,
    public lamp_status_detection: string | null,
    public message_bitmask: number | null,
    public dissociation_interval: string | null,
    public ts_app: number | null,
    public dimming: number | null,
    public current_consumption: number | null,
    public has_current_consumption: string | null,
    public light_sensor_value: number | null,
    public operating_voltage: number | null,
    public meter_current_consumption: number | null,
    public active_power: number | null,
    public reactive_power: number | null,
    public active_energy: number | null,
    public power_factor: string | null,
    public ts_reference: string | null,
    public major: number | null,
    public minor: number | null,
    public rev: number | null,
    public serial_number: number | null,
    public switch_on_time: string | null,
    public switch_off_time: string | null,
    public nacks_count: number | null,
    public acks_count: number | null,
    public reboots: number | null,
    public command_fail_count: number | null,
    public internal_temperature: number | null,
    public lamp_control_mode: number | null,
  ) { }


}
/** Utilizado para exportar as informações de paginação **/
export class PaginationInfo {
  constructor(
    public total: number,
    public count: number,
    public hasNextPage: boolean,
    public hasPreviousPage: boolean,
    public startCursor: string | null,
    public endCursor: string | null
  ) { }

}
