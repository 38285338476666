export class Gateway {
    constructor(
        public id: string,
        public reference: string,
        public serialNumber: string
    ) { }
}

export class CircuitBox {
    constructor(
        public id: string,
        public reference: string,
        public serialNumber: string,
        public device_mac_ipc: string,
        public device_mac_corte: string,
        public device_mac_gateway: string,
        public device_serial_ipc: string,
        public device_serial_corte: string,
        public device_serial_gateway: string
    ) { }
}

export class Division {
    constructor(
        public id: string,
        public reference: string
    ) { }
}

export class Alert {
    constructor(
        public id: string,
        public reference: string,
        public timestamp: string,
        public status: string
    ) { }
}

export class GatewayManager {
    constructor(
        public serialNumber: string | null = null,
        public receivedDatetime: Date | null = null,
        public realTimeClockDatetime: Date | null = null,
        public lastReboot: string | null = null,
        public reboots: string[] | null = null,
        public uptime: number | null = null,
        public transmissionDatetime: Date | null = null,
        public cpuPercent: number | null = null,
        public cpuCount: number | null = null,
        public ramTotal: number | null = null,
        public ramUsed: number | null = null,
        public diskTotal: number | null = null,
        public diskUsed: number | null = null,
        public diskFree: number | null = null,
        public diskPercent: number | null = null,
        public temperatureCurrent: number | null = null,
        public temperatureHigh: number | null = null,
        public alive: boolean | null = null,
        public devices: string[] | null = null,
        public ovpnDateTime: Date | null = null
    ) { }
}
export class Equipment {
    constructor(
        public major: string,
        public minor: string,
        public revision: string
    ) { }
}
