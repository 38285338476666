import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { CREATE_EQUIPMENT_TYPE, UPDATE_EQUIPMENT_TYPE, FILTER_ALL_EQUIPMENT_TYPES } from 'src/app/graphql/equipment-type.graphql';
import { ConfigCommands, QueryCommands } from '../components/register/pages/equipment-type/equipment-type-modal/model';
import { FILTER_PARAMETER } from 'src/app/graphql/parameters.graphql';
import { FILTER_PROPERTIES } from 'src/app/graphql/properties.grapqhl';
import { CREATE_HARDWARES, FILTER_HARDWARES } from 'src/app/graphql/hardwares.queries';
import { CREATE_FIRMWARES, FILTER_COMPATIBLE_FIRMWARE, FILTER_FIRMWARES } from 'src/app/graphql/firmwares.queries';


@Injectable({
  providedIn: 'root'
})
export class EquipmentTypeService {

  constructor(public apollo: Apollo) { }
  /**
    * Função responsável por filtrar os tipos de equipamentos
    */
  EquipmentTypeFilter(
    reference: string | null,
    major: number | null,
    minor: number | null,
    revision: number | null,
    cursor: string | null,
    pageSize: number | null
  ): any {
    // Faz a requisição
    return this.apollo.watchQuery({
      query: FILTER_ALL_EQUIPMENT_TYPES,
      fetchPolicy: 'network-only',
      variables: {
        reference,
        major,
        minor,
        revision,
        cursor,
        pageSize

      }
    })
  }

  /**
    * Função responsável por fazer o filtro de parâmetros
    */
  filterParameter(): any {
    // Faz a requisição
    return this.apollo.watchQuery({
      query: FILTER_PARAMETER,
      fetchPolicy: 'network-only',
      variables: {
        pageSize: 100
      }
    });
  }

  /**
    * Função responsável por fazer o filtro das propriedades de resposta dos comandos
    */
  filterProperty(): any {
    // Faz a requisição
    return this.apollo.watchQuery({
      query: FILTER_PROPERTIES,
      fetchPolicy: 'network-only',
      variables: {
        pageSize: 200
      }
    });
  }

  /**
    * Função responsável por fazer o filtro de hardwares
    */
  filterHardware(): any {
    // Faz a requisição
    return this.apollo.watchQuery({
      query: FILTER_HARDWARES,
      fetchPolicy: 'network-only',
      variables: {
        pageSize: 100
      }
    })
  }

  /**
    * Função responsável por fazer o filtro de fimrwares compativeis com o hardware selecionado pelo usuário
    */
  filterCompatibleFirmware(id: string | null): any {
    // Faz a requisição
    return this.apollo.watchQuery({
      query: FILTER_COMPATIBLE_FIRMWARE,
      fetchPolicy: 'network-only',
      variables: {
        id
      }
    })
  }

  /**
   * Função responsavel por criar tipos de equipamentos
   */
  createEquipmentType(
    reference: string,
    major: number,
    minor: number,
    revision: number,
    description: string,
    releaseDate: string,
    hardware: string,
    firmwares: string[],
    hasActuation: boolean,
    hasClockSync: boolean,
    hasPowerQuality: boolean,
    oldCrc: boolean,
    queryCommands: QueryCommands[],
    parameters: ConfigCommands[]
  ) {
    // Envia a mutation
    return this.apollo.mutate({
      mutation: CREATE_EQUIPMENT_TYPE,
      variables: {
        reference,
        description,
        major,
        minor,
        revision,
        releaseDate,
        firmwares,
        hardware,
        commandSheet: {
          channelsCount: 0,
          hasActuation,
          hasClockSync,
          hasPowerQuality,
          oldCrc,
          schedulingSlotsCount: 0,
          schedulingSlotsCountPerChannel: 0,
          queryCommands,
          parameters
        }
      }
    })
  }


  /** Requisição resposável por criar Hardwares */
  createHardware(
    name: any,
    major: any,
    minor: any,
    revision: any,
    description: any,
    releaseDate: any,
    compatibleFirmwares: string[]
  ) {
    /** Envia a requisição */
    return this.apollo.mutate({
      mutation: CREATE_HARDWARES,
      variables: {
        name,
        major,
        minor,
        revision,
        description,
        releaseDate,
        compatibleFirmwares
      }
    })
  }

  // Requisição responsável por filtrar firmwares
  filterFirmware() {
    return this.apollo.watchQuery({
      query: FILTER_FIRMWARES,
      fetchPolicy: "network-only",
      variables: {
        pageSize: 100
      }
    })
  }

  /** Requisição resposável por criar firmwares */
  createFirmware(
    name: any,
    major: any,
    minor: any,
    revision: any,
    description: any,
    releaseDate: any
  ) {
    /** Envia a requisição */
    return this.apollo.mutate({
      mutation: CREATE_FIRMWARES,
      variables: {
        name,
        major,
        minor,
        revision,
        description,
        releaseDate
      }
    })
  }

  updateEquipmentType(
    id: string,
    description: string,
    releaseDate: Date,
    hardware: string,
    firmwares: string[],
    hasActuation: boolean = false,
    hasClockSync: boolean = false,
    hasPowerQuality: boolean = false,
    oldCrc: boolean = false,
    queryCommands: QueryCommands[] | null,
    parameters: ConfigCommands[] | null
  ) {
    return this.apollo.mutate({
      mutation: UPDATE_EQUIPMENT_TYPE,
      variables: {
        id,
        description,
        releaseDate,
        equipmentParts: [{
          hardware,
          firmwares
        }],
        commandSheet: {
          hasActuation,
          hasClockSync,
          hasPowerQuality,
          oldCrc,
          queryCommands,
          parameters,
          schedulingSlotsCount: 0,
          schedulingSlotsCountPerChannel: 0,
          channelsCount: 0
        }
      }
    })
  }

  // updateEquipmentType(
  //   id: string,
  //   description: string | null,
  //   releaseDate: Date,
  //   reference: string | null,
  //   major: number,
  //   minor: number,
  //   revision: number,
  //   hardware: string[],
  //   firmwares: string[],
  //   channelsCount: number,
  //   schedulingSlotsCount: number,
  //   schedulingSlotsCountPerChannel: number,
  //   hasClockSync: boolean,
  //   hasActuation: boolean,
  //   hasPowerQuality: boolean,
  //   oldCrc: boolean,
  //   parametersType: string | null,
  //   parametersReference: string | null,
  //   parametersTargetReference: string | null,
  //   queryCommandsType: string | null,
  //   queryCommandsResponseProperties: string | null
  // ) {
  //   return this.apollo.mutate({
  //     mutation: UPDATE_EQUIPMENT_TYPE,
  //     variables: {
  //       id,
  //       description,
  //       releaseDate,
  //       reference,
  //       major,
  //       minor,
  //       revision,
  //       hardware,
  //       firmwares,
  //       commandSheet: {
  //         channelsCount,
  //         schedulingSlotsCount,
  //         schedulingSlotsCountPerChannel,
  //         hasClockSync,
  //         hasActuation,
  //         hasPowerQuality,
  //         oldCrc
  //       },
  //       parameters: [
  //         {
  //           reference: parametersReference,
  //           targetReference: parametersTargetReference,
  //           type: parametersType,
  //         }
  //       ],
  //       queryCommands: [
  //         {
  //           type: queryCommandsType,
  //           responseProperties: queryCommandsResponseProperties
  //         }
  //       ]
  //     }
  //   })
  // }
}


