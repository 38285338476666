<div class="component-main-container">
  <app-page-header parentTitle="{{ 'report-charts.report-charts-label' | translate }}"
    pageTitle="{{ 'report-charts.generate-report-charts-label' | translate }}">
  </app-page-header>
  <div class="row">
    <!-- Caixa do formulário -->
    <div class="col-lg-12 col-xl-12 col-sm-12">
      <div class="component-forms-box">
        <div class="form-fields-box" [ngClass]="!createChartBox ? 'closed' : ''">
          <div class="gen-chart">
            <h3 (click)="createChartBoxOpen()">
              {{ "report-charts.create-form-title" | translate }}
            </h3>
            <hr [ngClass]="createChartBox ? 'hr-open' : ''" />
            <!-- Passo 1 - Buscar instalação -->
            <div class="installation-step">
              <div class="chart-creation-step">
                <div class="circle-number" [ngClass]="step1IsConcluded ? 'circle-number-ready' : ''">
                  1
                </div>
                <mat-form-field class="example-form-field" appearance="fill">
                  <mat-label>
                    {{ "report-charts.filter-label" | translate }}
                  </mat-label>
                  <input (keydown.enter)="searchInstallations()" [formControl]="installationReferenceForm" matInput
                    type="text" />
                  <button matSuffix mat-icon-button aria-label="Clear" class="search-icon-button"
                    (click)="searchInstallations()">
                    <mat-icon svgIcon="bx-search"></mat-icon>
                  </button>
                </mat-form-field>
              </div>
              <mat-spinner *ngIf="isLoadingInstallationSearch" [diameter]="30"></mat-spinner>

              <!-- Passo 2 - Escolher instalação -->
              <div class="chart-creation-step" *ngIf="
                  step1IsConcluded &&
                  isThereInstallationListed &&
                  !noInstallationWarning
                ">
                <div class="circle-number" [ngClass]="step2IsConcluded ? 'circle-number-ready' : ''">
                  2
                </div>
                <mat-form-field class="example-form-field" appearance="fill">
                  <mat-label>
                    {{ "report-charts.select-installation" | translate }}
                  </mat-label>
                  <mat-select [formControl]="selectedInstallationForm" (selectionChange)="isStep2Concluded()">
                    <mat-option>
                      {{ "report-charts.select-installation" | translate }}</mat-option>
                    <mat-option *ngFor="let installation of filteredInstallations" [value]="installation.id">
                      {{ installation.reference }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <!-- Passo 3 - Escolhe range de data -->
              <div class="chart-creation-step" *ngIf="step2IsConcluded">
                <div class="circle-number" [ngClass]="step3IsConcluded ? 'circle-number-ready' : ''">
                  3
                </div>
                <mat-form-field appearance="outline">
                  <mat-label>Enter a date range</mat-label>
                  <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                    <input matStartDate formControlName="startDate" placeholder="Start date" />
                    <input (dateChange)="isStep3Concluded()" matEndDate formControlName="endDate"
                      placeholder="End date" />
                  </mat-date-range-input>

                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
              </div>

              <p *ngIf="step1IsConcluded && selectedInstallations.length != 0" class="font-weight-light hint-text">
                {{ "report-charts.all-installations-selected" | translate }}
              </p>
              <p *ngIf="noInstallationWarning" class="font-weight-light hint-text">
                {{ "report-charts.installation-not-found" | translate }}
              </p>

              <button *ngIf="!isGeneratingChart" [disabled]="!enableGenerationButton()" (click)="generateChart()"
                class="btn btn-success mb-2 w-10">
                {{ "report-charts.filter-button" | translate }}
              </button>
              <mat-spinner *ngIf="isGeneratingChart" [diameter]="30"></mat-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Caixa do gráfico -->
    <div class="col-lg-12 col-xl-12 col-sm-12">
      <div class="chart-column table-div mt-2 mt-lg-3 mt-xl-3"
        [style.visibility]="!inInitialState ? 'visible' : 'hidden'">
        <canvas id="MyChart">{{ chartVariable }}</canvas>

        <div class="table">
          <div class="d-flex">
            <h1>{{ "report-charts.table-title" | translate }}</h1>
          </div>

          <!-- Table box -->
          <div *ngIf="!this.eqTypeWithVersion?.includes('PULSO') &&
                      !this.eqTypeWithVersion?.includes('READ'); then tableIP">
          </div>

          <div *ngIf="this.eqTypeWithVersion?.includes('PULSO'); then tablePULSE">
          </div>

          <div *ngIf="this.eqTypeWithVersion?.includes('READ'); then tableREAD">
          </div>

          <ng-template #tablePULSE>
            <div class="table-box">
              <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                <ng-container matColumnDef="dateTime">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "report-charts.date-time-table" | translate }}
                  </th>
                  <td mat-cell *matCellDef="let data">{{ data.dateTime }}</td>
                </ng-container>

                <ng-container matColumnDef="voltage">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "" }}
                  </th>
                  <td mat-cell *matCellDef="let data">{{ "" }}</td>
                </ng-container>

                <ng-container matColumnDef="current">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "" }}
                  </th>
                  <td mat-cell *matCellDef="let data">{{ "" }}</td>
                </ng-container>

                <ng-container matColumnDef="apparentPower">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "" }}
                  </th>
                  <td mat-cell *matCellDef="let data">{{ "" }}</td>
                </ng-container>

                <ng-container matColumnDef="consumption">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "report-charts.consumption-table-pulse" | translate }}
                  </th>
                  <td mat-cell *matCellDef="let data">{{ data.consumption }}</td>
                </ng-container>

                <ng-container matColumnDef="powerFactor">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "" }}
                  </th>
                  <td mat-cell *matCellDef="let data">{{ "" }}</td>
                </ng-container>

                <ng-container matColumnDef="time">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "report-charts.time-table" | translate }}
                  </th>
                  <td mat-cell *matCellDef="let data">{{ data.time }}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              </table>
              <mat-paginator [length]="chartData.length" [pageSize]="20" [pageSizeOptions]="[10, 20, 25, 30, 40, 100]"
                aria-label="Selecione o tamanho da página" class="mat-paginator-sticky" showFirstLastButtons>
              </mat-paginator>
            </div>
          </ng-template>

          <ng-template #tableREAD>
            <div class="table-box">
              <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                <ng-container matColumnDef="dateTime">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "report-charts.date-time-table" | translate }}
                  </th>
                  <td mat-cell *matCellDef="let data">{{ data.dateTime }}</td>
                </ng-container>

                <ng-container matColumnDef="voltage">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "" }}
                  </th>
                  <td mat-cell *matCellDef="let data">{{ "" }}</td>
                </ng-container>

                <ng-container matColumnDef="current">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "" }}
                  </th>
                  <td mat-cell *matCellDef="let data">{{ "" }}</td>
                </ng-container>

                <ng-container matColumnDef="apparentPower">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "" }}
                  </th>
                  <td mat-cell *matCellDef="let data">{{ "" }}</td>
                </ng-container>

                <ng-container matColumnDef="consumption">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "report-charts.consumption-table" | translate }}
                  </th>
                  <td mat-cell *matCellDef="let data">{{ data.consumption }}</td>
                </ng-container>

                <ng-container matColumnDef="powerFactor">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "" }}
                  </th>
                  <td mat-cell *matCellDef="let data">{{ "" }}</td>
                </ng-container>

                <ng-container matColumnDef="time">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "report-charts.time-table" | translate }}
                  </th>
                  <td mat-cell *matCellDef="let data">{{ data.time }}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              </table>
              <mat-paginator [length]="chartData.length" [pageSize]="20" [pageSizeOptions]="[10, 20, 25, 30, 40, 100]"
                aria-label="Selecione o tamanho da página" class="mat-paginator-sticky" showFirstLastButtons>
              </mat-paginator>
            </div>
          </ng-template>

          <ng-template #tableIP>
            <div class="table-box">
              <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                <ng-container matColumnDef="dateTime">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "report-charts.date-time-table" | translate }}
                  </th>
                  <td mat-cell *matCellDef="let data">{{ data.dateTime }}</td>
                </ng-container>

                <ng-container matColumnDef="voltage">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "report-charts.voltage-table" | translate }}
                  </th>
                  <td mat-cell *matCellDef="let data">{{ data.voltage }}</td>
                </ng-container>

                <ng-container matColumnDef="current">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "report-charts.current-table" | translate }}
                  </th>
                  <td mat-cell *matCellDef="let data">{{ data.current }}</td>
                </ng-container>
                <!-- Será adicionada novamente após correções -->
                <!-- <ng-container matColumnDef="power">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "report-charts.time-table" | translate }}
                  </th>
                  <td mat-cell *matCellDef="let data">{{ data.activePower }}</td>
                </ng-container> -->

                <ng-container matColumnDef="apparentPower">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "report-charts.apparent-power-table" | translate }}
                  </th>
                  <td mat-cell *matCellDef="let data">
                    {{ data.apparentPower }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="consumption">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "report-charts.consumption-table" | translate }}
                  </th>
                  <td mat-cell *matCellDef="let data">{{ data.consumption }}</td>
                </ng-container>

                <ng-container matColumnDef="powerFactor">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ "report-charts.power-factor-table" | translate }}
                  </th>
                  <td mat-cell *matCellDef="let data">{{ data.powerFactor }}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              </table>
              <mat-paginator [length]="chartData.length" [pageSize]="20" [pageSizeOptions]="[10, 20, 25, 30, 40, 100]"
                aria-label="Selecione o tamanho da página" class="mat-paginator-sticky" showFirstLastButtons>
              </mat-paginator>
            </div>
          </ng-template>

        </div>
      </div>
    </div>
  </div>
</div>