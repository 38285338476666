import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Apollo } from 'apollo-angular';
import { environment } from 'src/environments/environment';
import { CHANGE_FAVORITE } from 'src/app/graphql/preferences.graphql';
import { GatewayManager } from '../components/map/models/mapModel';
import { BehaviorSubject, Observable } from 'rxjs';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';
import { decrypt } from 'src/util/cryptography.util';
import { FILTER_EQUIPMENTS } from 'src/app/graphql/equipment.graphql';
import { FILTER_GATEWAYS } from 'src/app/graphql/gateways.graphql';
import { FILTER_DIVISIONS } from 'src/app/graphql/divisions.graphql';
import { GET_ALL_ALERTS } from 'src/app/graphql/alerts.graphql';
import { GET_USER_GROUP_PERMISSIONS } from 'src/app/graphql/users.graphql';
import { FILTER_INSTALLATIONS, INSTALLATION_NODE } from 'src/app/graphql/installations.graphql';
import { GET_INDIVIDUAL_CONSUMPTION_CHART } from 'src/app/graphql/consumption.graphql';

@Injectable({
  providedIn: 'root'
})

export class MapjsService {

  constructor(
    private http: HttpClient,
    private apollo: Apollo
  ) { }

  // Variável utilizada para realizar o unsubscribe da subscrição do webSocket
  public websocketSubject: WebSocketSubject<any> | any;

  // Endereço do geoserver, é alterado de acordo com o ambiente
  private geoServerUrl: string = environment.geoServerUrl;
  private geoRedisConnectorUrl: string = environment.geoRedisConnectorUrl;

  // Propriedade que cuida do Gateway Socket
  private gatewaySubject = new BehaviorSubject<GatewayManager>(new GatewayManager);
  private gatewayManage$ = this.gatewaySubject.asObservable();

  /** Subject utilizado para manipular o estado da exibição dos logs,
   * onde será passado o valor que foi atribuido no componente de mapa para o componente de logs **/
  private logsSubject = new BehaviorSubject<boolean>(false);

  /** Observable responsável por ouvir e compartilhar o estado da propriedade subject para o componente de logs **/
  public logsSubject$ = this.logsSubject.asObservable();

  /** Método que altera o estado da propriedade para true (ou seja, momento em que os logs deverão ser exibidos) **/
  public setLogsTrue() {
    this.logsSubject.next(true);
  }

  /** Método que altera o estado da propriedade para false (ou seja, momento em que os logs não deverão ser exibidos) **/
  public setLogsFalse() {
    this.logsSubject.next(false);
  }

  /** Subject utilizado para manipular quando os markers referente ao grupo de permissão gerencial será exibido **/
  private markerMapSubject = new BehaviorSubject<boolean>(false);

  /** Observable responsável por ouvir e compartilhar o estado da propriedade subject para o componente do mapa **/
  public markerMapSubject$ = this.markerMapSubject.asObservable();

  /** Método utilizado para alterar o estado do subject para true,
   *  emitindo um valor p/componente do mapa, onde será alterado a cor dos markers das instalações **/
  public setMarkerManagementMapTrue() {
    this.markerMapSubject.next(true);
  }

  /** Método utilizado para alterar o estado do subject, mantendo a cor padrão dos markers **/
  public setMarkerManagementMapFalse() {
    this.markerMapSubject.next(false);
  }

  /**Headers responsaveis pela autenticação do endereço do geoserver*/
  public username = localStorage.getItem('username');
  public password = decrypt(localStorage.getItem("geoserverPassword")!, localStorage.getItem("token")!);

  public headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': '*/*',
    'Authorization': 'Basic ' + btoa(`${this.username}:${this.password}`)
  });

  // Função responsável por trazer todas as divisões da empresa selecionada
  public getAllDivisions(company: string): any {
    return this.apollo.watchQuery<any>({
      query: FILTER_DIVISIONS,
      fetchPolicy: "network-only",
      variables: {
        company,
        pageSize: 1000
      }
    })
  }

  // Função responsável por trazer todos os alertas da instalação selecionada(Aparece na sidebar de mais informações)
  public getAlert(
    company: string,
    installation: any,
  ): any {
    return this.apollo.watchQuery<any>({
      query: GET_ALL_ALERTS,
      fetchPolicy: "network-only",
      variables: {
        company,
        installation,
        first: 50,
        sort_dir: "DESC",
        sort_field: "ALERT_DATETIME"
      }
    })
  }

  /*  Função responsável por trazer mais informações
   sobre o a instalação selecionada assim como o consumo detalhado */
  public getDetailConsuption(
    id: string
  ): any {
    return this.apollo.watchQuery<any>({
      query: INSTALLATION_NODE,
      fetchPolicy: "network-only",
      variables: {
        id
      }
    })
  }

  public changeFavorite(): any {
    return this.apollo.mutate<any>({
      mutation: CHANGE_FAVORITE,
      fetchPolicy: "network-only",
      variables: {
        json: "{}",
        preferencesGroup: "{}"
      }
    })
  }

  /** Realiza a consulta do consumo **/
  public getConsumptionData(
    startDatetime: string,
    endDatetime: string,
    deviceMac: string,
    equipmentType: string | null,
    installationId: string | null
  ) {

    return this.apollo.watchQuery<any>({
      query: GET_INDIVIDUAL_CONSUMPTION_CHART,
      fetchPolicy: "network-only",
      variables: {
        startDatetime,
        endDatetime,
        deviceMac,
        equipmentType: equipmentType ? equipmentType : ' ',
        installationId
      },
    });
  }

  /** Realiza a consulta do tipo de equipamento (filtro realizado por mac) **/
  public getEquipmentByMacAddress(search: string | null, company: string | null) {
    return this.apollo.watchQuery<any>({
      query: FILTER_EQUIPMENTS,
      fetchPolicy: 'network-only',
      variables: {
        search,
        company,
        sort_dir: "ASC",
        sort_field: "MAC_ADDRESS"
      }
    })
  }

  /** Realiza o filtro dos usuários (utilizado para verificar as permissões do usuário) **/
  public getUser(username: any): any {
    return this.apollo.watchQuery<any>({
      query: GET_USER_GROUP_PERMISSIONS,
      fetchPolicy: 'no-cache',
      variables: {
        username: username,
      }
    }).valueChanges.subscribe({
      next: ((res: any) => {
        /** Armazena os grupos de permissões do usuário atual **/
        const userPermissionsGroup: any = res.data.user.edges[0].node.groups.edges;

        /** Armazena o nome do grupo de permissão para o mapa gerencial **/
        const permissionGroupManagementMap: string = 'Perfil (Mapa Gerencial)';

        /** Realiza um filtro dos grupos de permissões do usuário retornando apenas a permissão de perfil do mapa gerencial **/
        const permissionGroupsManagementMap: string = userPermissionsGroup.filter((group: any) => {

          /** Retorna o grupo de permissão do perfil do mapa gerencial **/
          return group.node.name === permissionGroupManagementMap;
        })

        /** Caso o grupo de permissão de acesso para o mapa gerencial exista no perfil do usuário **/
        if (permissionGroupsManagementMap.length > 0) {

          /** Altera o estado do subject para ser enviado ao componente do mapa **/
          this.setMarkerManagementMapTrue();

        } else {
          /** Caso o usuário não tenha a permissão, mantendo a cor padrão dos markers **/
          this.setMarkerManagementMapFalse();
        }
      })
    })
  }

  /** Consulta que filtra as instalações vinculadas ao gateway **/
  public getInstallationByGatewayID(company: string | null, gateway: string | null) {
    return this.apollo.watchQuery<any>({
      query: FILTER_INSTALLATIONS,
      fetchPolicy: "network-only",
      variables: {
        company,
        gateway,
        sort_dir: "ASC",
        sort_field: "REFERENCE",
      }
    })
  }

  /** Consulta que filtra os gateways (utilizada para capturar o id dos gateways da caixa de circuito) **/
  public getGatewayId(company: string | null, search: string) {
    return this.apollo.watchQuery<any>({
      query: FILTER_GATEWAYS,
      fetchPolicy: "network-only",
      variables: {
        company: company,
        search,
        sort_dir: "ASC",
        sort_field: "REFERENCE"
      }
    })
  }

  // Filtro das instalações, de acordo com os parametros selecionados pelo usuário(Geoserver)
  public filterByElements(filter: string, companyId: string) {

    let url = `${this.geoServerUrl}geoserver/${companyId.replace(/\D/g, '')}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=map_nodes&maxFeatures=150000&outputFormat=application%2Fjson&CQL_FILTER=${filter}`;

    return this.http.get(url, { headers: this.headers });

  }
  // Filtro das roles, de acordo com os parametros selecionados pelo usuário(Geoserver)
  public isCompanyInRoles() {
    let url = `${this.geoServerUrl}geoserver/rest/security/roles/user/${localStorage.getItem("username")}/`;
    return this.http.get(url, { headers: this.headers, responseType: 'text' });
  }


  // Filtra instalações pelo poligono selecionado no mapa
  public filterByPolygons(filter: string, companyId: string) {

    let url = `${this.geoServerUrl}geoserver/${companyId.replace(/\D/g, '')}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=map_nodes&maxFeatures=150000&outputFormat=application%2Fjson&CQL_FILTER=${filter}`;

    return this.http.get(url, { headers: this.headers });

  }

  // Filtra  todas os concentradores da empresa selecionada atraves do geoserver
  public getGateways(companyId: string) {

    let url = `${this.geoServerUrl}geoserver/${companyId.replace(/\D/g, '')}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=gateways&maxFeatures=50000&outputFormat=application%2Fjson&CQL_FILTER=is_active=true`;

    return this.http.get(url, { headers: this.headers });
  }

  // Filtro das instalações, de acordo com os parametros selecionados pelo usuário(Geoserver)
  public getCircuitBox(companyId: string) {

    let url = `${this.geoServerUrl}geoserver/${companyId.replace(/\D/g, '')}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=circuitbox&maxFeatures=50000&outputFormat=application%2Fjson`;
    return this.http.get(url, { headers: this.headers });
  }

  // Filtro das instalações, de acordo com os parametros selecionados pelo usuário(Geoserver)
  public getCircuitBoxReference(companyId: string, filter: string) {

    let url = `${this.geoServerUrl}geoserver/${companyId.replace(/\D/g, '')}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=circuitbox&maxFeatures=50000&outputFormat=application%2Fjson&CQL_FILTER=reference${filter}`;
    return this.http.get(url, { headers: this.headers });
  }

  public getStatusAndLastTransmissions(macs: any, filter: any) {

    let url = `${this.geoRedisConnectorUrl}last_transmissions${filter}`;
    // Captura o token do cliente.
    const token = localStorage.getItem('token');

    // Transforma o objeto formData em uma string no formato application/x-www-form-urlencoded
    const body = new URLSearchParams(macs).toString();

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${token}`,  // Insere o token do client no header da requisição.
    });

    return this.http.post(url, body, { headers });

  }

  /** Requisição que retorna apenas o status **/
  public getStatus(mac: any) {

    /** Armazena a url do redisConnector, passando o mac como parâmetro no filtro **/
    let url = `${this.geoRedisConnectorUrl}last_transmission?mac=${mac}`;

    /** Captura o token do cliente. **/
    const token = localStorage.getItem('token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${token}`,  // Insere o token do client no header da requisição.
    });

    return this.http.get(url, { headers });
  }

  public watchGatewaysRequest(serialNumbers: Array<string>, token: string | null): void {
    let _url = `${environment.gatewayWebSocketUrl}?token=${token}`;
    this.websocketSubject = webSocket(_url);
    // Envia uma mensagem pro websocket com os números de série
    this.websocketSubject.next(serialNumbers.join(','))
    this.websocketSubject.subscribe({
      next: (data: any): void => {
        let gateway: GatewayManager = new GatewayManager(
          data?.serial_number,
          data?.received_datetime,
          data?.rtc_datetime,
          data?.last_reboot,
          data?.reboots,
          data?.uptime,
          data?.transmission_datetime,
          data?.cpu_percent,
          data?.cpu_count,
          data?.ram_total,
          data?.ram_used,
          data?.disk_total,
          data?.disk_used,
          data?.disk_free,
          data?.disk_percent,
          data?.temperature_current,
          data?.temperature_high,
          data?.ovpn_alive,  // Indica se o gateway está ou não ativo na rede.
          data?.devices,
          data?.ovpn_datetime
        );
        this.gatewaySubject.next(gateway);
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        console.log('Close websocket connection');
      }
    })
  }

  public closeConnection() {
    this.websocketSubject.unsubscribe();
    this.websocketSubject.complete();
  }

  public watchGateway(): Observable<GatewayManager> {
    return this.gatewayManage$;
  }
}
