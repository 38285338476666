import { Component, OnInit, ViewChild } from '@angular/core';
import { DateTime } from 'luxon';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ErrorLibService } from 'src/shared/services/error-lib.service';
import { ReportChartService } from 'src/shared/services/report-chart.service';
import { ReportService } from 'src/shared/services/report.service';
import Swal from 'sweetalert2';
import { Installation } from '../../report/models/installation.model';
import { ReportChartData } from '../models/chartData.model';
import { Unsubscriber } from '../../unsubscriber/unsubscriber.component';
import { tzConvertISOEndDate, tzConvertISOStartDate, tzConvertUTC2Local } from 'src/assets/convertTimeZone/convertTimeZone';

@Component({
  selector: 'app-report-charts',
  templateUrl: './report-charts.component.html',
  styleUrls: ['./report-charts.component.less'],
})

export class ReportChartsComponent extends Unsubscriber implements OnInit {

  //Variáveis usadas para o paginator e para o sort.
  private paginator: MatPaginator;

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  /**
   * Função que configura o paginator.
   */
  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
  }

  public company: string | null = '';

  constructor(
    private reportService: ReportService,
    private reportChartService: ReportChartService,
    private errorService: ErrorLibService,
    private formbuilder: FormBuilder
  ) {
    super();
    this.company = localStorage.getItem('lastCompanySelected');
  }

  ngOnInit(): void { }

  //Form control variables
  installationReferenceForm: FormControl = new FormControl(
    '',
    Validators.required
  );
  selectedInstallationForm: FormControl = new FormControl(
    '',
    Validators.required
  );

  //General variables
  selectedInstallations: Installation[] = [];
  filteredInstallations: Installation[] = [];
  eqTypeWithVersion: string | null;

  chartData: ReportChartData[] = [
    new ReportChartData(
      '1',
      '2021-01-01 00:00:00',
      '1',
      '1',
      '1',
      '1',
      '1',
      '1',
      '1'
    ),
    new ReportChartData(
      '2',
      '2021-01-01 00:00:00',
      '2',
      '1',
      '1',
      '2',
      '2',
      '2',
      '2'
    ),
    new ReportChartData(
      '3',
      '2021-01-01 00:00:00',
      '3',
      '1',
      '1',
      '3',
      '3',
      '3',
      '3'
    ),
  ];

  displayedColumns: string[] = [
    'dateTime',
    'voltage',
    'current',
    // 'power',
    'apparentPower',
    'consumption',
    'powerFactor',
  ];

  dataSource = new MatTableDataSource<ReportChartData>(this.chartData);

  public chartVariable: any;

  //flags
  step1IsConcluded: boolean = false;
  step2IsConcluded: boolean = false;
  step3IsConcluded: boolean = false;
  isLoadingInstallationSearch: boolean = false;
  isThereInstallationListed: boolean = false;
  noInstallationWarning: boolean = false;
  createChartBox: boolean = false;
  //flag de consulta de gráfico
  inInitialState: boolean = true;
  inVoltageChart: boolean = false;
  inCurrentChart: boolean = false;
  inConsumptionChart: boolean = false;
  inAparentPowerChart: boolean = false;
  inPowerFactorChart: boolean = false;

  isGeneratingChart: boolean = false;

  public range: FormGroup = this.formbuilder.group({
    startDate: [null],
    endDate: [null]
  });

  /** Armazena o range de datas entre data inicial e data final **/
  public datesInRange: Date[] = [];

  createChartBoxOpen() {
    this.createChartBox = !this.createChartBox;
  }

  isStep1Concluded(): void {
    if (!this.isThereInstallationListed) {
      this.step1IsConcluded = false;
      return;
    }
    this.step1IsConcluded = true;
  }

  isStep2Concluded(): void {
    if (!this.selectedInstallationForm.valid) {
      this.step2IsConcluded = false;
      return;
    }
    this.step2IsConcluded = true;
  }

  isStep3Concluded(): void {
    if (!this.range.valid) {
      this.step3IsConcluded = false;
      return;
    }
    this.step3IsConcluded = true;
  }

  searchInstallations() {
    this.isLoadingInstallationSearch = true;
    this.filteredInstallations = [];
    this.selectedInstallations = [];
    if (!this.installationReferenceForm.valid) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Por favor, preencha todos os campos corretamente!',
      });
      this.isLoadingInstallationSearch = false;
      return;
    }

    this.subscriptions = this.reportService
      .getInstallationByReference(
        this.installationReferenceForm.value,
        this.company
      )
      .subscribe({
        next: (installations: any) => {
          installations.data.installation.edges.forEach((installation: any) => {
            if (
              installation.node.reference.includes(
                this.installationReferenceForm.value
              )
            ) {
              this.filteredInstallations.push(
                new Installation(
                  installation.node.id,
                  installation.node.reference,
                  installation.node.device
                    ? installation.node.device.macAddress
                    : '',
                  ''
                )
              );

              this.eqTypeWithVersion =
                installation.node.device != undefined
                  ? installation.node.device.equipmentType.reference +
                  ' ' +
                  installation.node.device.equipmentType.major +
                  '.' +
                  installation.node.device.equipmentType.minor +
                  '.' +
                  installation.node.device.equipmentType.revision
                  : null;
            }
          });
          if (this.filteredInstallations.length == 0) {
            this.isThereInstallationListed = false;
            this.step2IsConcluded = false;
            this.noInstallationWarning = true;
          } else {
            this.isThereInstallationListed = true;
            this.noInstallationWarning = false;
          }
          this.isStep1Concluded();
          this.isLoadingInstallationSearch = false;
        },
        error: (error: any) => {
          console.log('installationSearchResult: ', error.message);
          Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: `${this.errorService.errorLib(error.message)}`,
          });
          this.isLoadingInstallationSearch = false;
        },
        complete: () => {
          console.log('complete');
        },
      });
  }

  enableGenerationButton(): boolean {
    if (
      this.step1IsConcluded &&
      this.step2IsConcluded &&
      this.step3IsConcluded
    ) {
      return true;
    }
    return false;
  }

  generateChart(): void {
    this.isGeneratingChart = true;
    this.chartData = [];

    this.subscriptions = this.reportChartService
      .getConsumptionData(
        tzConvertISOStartDate(this.range.value.startDate),
        tzConvertISOEndDate(this.range.value.endDate),
        this.selectedInstallationForm.value)
      .subscribe({
        next: (data: any) => {
          data.data.individualConsumption.forEach((consumption: any) => {
            if (this.eqTypeWithVersion?.match(/PULSO/)) {
              var dateTime = consumption.date ? DateTime.fromISO(consumption.date).toFormat('dd/MM/yyyy') : '';
              var macAddress = '';
              var data_voltage = '';
              var data_current = '';
              var data_apparent_power = '';
              var data_power_factor = '';
              var data_consumption: string = consumption.total.toFixed(5);
              var data_diff = '24';
              var data_active_power = consumption.activePower;
              this.chartData.push(
                new ReportChartData(
                  macAddress,
                  dateTime,
                  data_voltage,
                  data_current,
                  data_active_power,
                  data_apparent_power,
                  data_consumption,
                  data_power_factor,
                  data_diff
                )
              );
            }
            else if (this.eqTypeWithVersion?.match(/READ/)) {
              var dateTime = consumption.date ? DateTime.fromISO(consumption.date).toFormat('dd/MM/yyyy') : '';
              var macAddress = '';
              var data_voltage = '';
              var data_current = '';
              var data_apparent_power = '';
              var data_power_factor = '';
              var data_consumption: string = (consumption.total / 1000).toFixed(5);
              var data_diff = "24";
              var data_active_power = consumption.activePower;
              this.chartData.push(
                new ReportChartData(
                  macAddress,
                  dateTime,
                  data_voltage,
                  data_current,
                  data_active_power,
                  data_apparent_power,
                  data_consumption,
                  data_power_factor,
                  data_diff
                )
              );
            }
            else {
              consumption.metadata.forEach((metadata: any) => {
                var dateTime = metadata.datetime;
                var macAddress = metadata.macAddress ? metadata.macAddress : '';
                var data_voltage = metadata.operatingVoltage?.toFixed(0);
                var data_current = metadata.meterCurrentConsumption?.toFixed(0);
                var data_apparent_power = (
                  (data_voltage * data_current) /
                  1000
                ).toFixed(2);
                var data_power_factor = (metadata.powerFactor / 1000).toFixed(3);
                var data_consumption: string = (metadata.proportionalPower / 1000)
                  ?.toFixed(3)
                  .replace(/\.?0+$/, '');
                var data_diff = (metadata.diff / 3600).toFixed(2);
                var data_active_power = metadata.activePower;
                this.chartData.push(
                  new ReportChartData(
                    macAddress,
                    dateTime,
                    data_voltage,
                    data_current,
                    data_active_power,
                    data_apparent_power,
                    data_consumption,
                    data_power_factor,
                    data_diff
                  )
                );
              })
            }
          });

          this.isGeneratingChart = false;

          this.chartData.sort((a, b) => {
            const consumptionA = DateTime.fromISO(a.dateTime!);  // Converte para DateTime
            const consumptionB = DateTime.fromISO(b.dateTime!);  // Converte para DateTime

            // Comparando as datas em milissegundos
            return consumptionA.toMillis() - consumptionB.toMillis();
          });

          this.dataSource = new MatTableDataSource<ReportChartData>(
            this.chartData
          );
          //Inicia mostrando o gráfico de tensão

          if (this.eqTypeWithVersion?.match(/PULSO/)) {
            this.createPulseChart();
          }
          else if (this.eqTypeWithVersion?.match(/READ/)) {
            this.createReadChart();
          }
          else {

            this.chartData.forEach((data: any) => {
              data.dateTime = tzConvertUTC2Local(data.dateTime);
            })

            this.createVoltageChart()
          }
        },
        error: (error) => {
          console.log(error);
          this.isGeneratingChart = false;
          Swal.fire({
            title: 'Erro!',
            text: 'Não foi possivel gerar o gráfico, tente novamente!',
            icon: 'error',
            confirmButtonText: 'Ok',
          });
        },
      });
  }

  createPulseChart(): void {

    let consumptionData: any[] = [];
    let dateTimeData: any[] = [];

    this.chartData.forEach((chartData: any) => {
      consumptionData.push(chartData.consumption);
      dateTimeData.push(chartData.dateTime);
    });

    /** Caso não haja dados de consumo **/
    if (this.chartData.length < 1) {

      /** Limpa a lista de datas **/
      this.datesInRange = [];

      /** Contantes utilizadas para armazenar as data incial e final do formulário convertidas **/
      const startDate = new Date(JSON.stringify(this.range?.value?.startDate).replace(/"/g, ''));

      const endDate = new Date(JSON.stringify(this.range?.value?.endDate).replace(/"/g, ''));

      /** Enquanto a data inicial for menor que a dara final **/
      while (startDate <= endDate) {
        /** Armazena a data em uma lista de ranges **/
        this.datesInRange.push(new Date(startDate));
        /** Seta a data inicial somando +1 **/
        startDate.setDate(startDate.getDate() + 1);
      }
      /** Limpa o gráfico **/
      this.chartVariable ? this.chartVariable.destroy() : null;

      /** Exibe o gráfico com a linha horizontal **/
      this.chartVariable = this.reportChartService.generateEmptyPulseConsumptionChart(this.datesInRange);
    }
    /** Caso existam dados de consumo **/
    else {

      this.chartVariable ? this.chartVariable.destroy() : null;

      /** Exibe os dados de consumo conforme os dados existentes **/
      this.chartVariable = this.reportChartService.generatePulseChart(
        consumptionData,
        dateTimeData
      );
    }

    this.inInitialState = false;

    Swal.fire({
      icon: 'success',
      title: 'Sucesso!',
      text: 'Gráfico gerado com sucesso!',
    });
  }

  createReadChart(): void {

    let consumptionData: any[] = [];
    let dateTimeData: any[] = [];

    this.chartData.forEach((chartData: any) => {
      consumptionData.push(chartData.consumption);
      dateTimeData.push(chartData.dateTime);
    });

    /** Caso não haja dados de consumo **/
    if (this.chartData.length < 1) {

      /** Limpa a lista de datas **/
      this.datesInRange = [];

      /** Contantes utilizadas para armazenar as data incial e final do formulário convertidas **/
      const startDate = new Date(JSON.stringify(this.range?.value?.startDate).replace(/"/g, ''));

      const endDate = new Date(JSON.stringify(this.range?.value?.endDate).replace(/"/g, ''));

      /** Enquanto a data inicial for menor que a dara final **/
      while (startDate <= endDate) {
        /** Armazena a data em uma lista de ranges **/
        this.datesInRange.push(new Date(startDate));
        /** Seta a data inicial somando +1 **/
        startDate.setDate(startDate.getDate() + 1);
      }
      /** Limpa o gráfico **/
      this.chartVariable ? this.chartVariable.destroy() : null;

      /** Exibe o gráfico com a linha horizontal **/
      this.chartVariable = this.reportChartService.generateEmptyReadConsumptionChart(this.datesInRange);
    }
    /** Caso existam dados de consumo **/
    else {

      this.chartVariable ? this.chartVariable.destroy() : null;

      /** Exibe os dados de consumo conforme os dados existentes **/
      this.chartVariable = this.reportChartService.generateReadChart(
        consumptionData,
        dateTimeData
      );
    }

    this.inInitialState = false;

    Swal.fire({
      icon: 'success',
      title: 'Sucesso!',
      text: 'Gráfico gerado com sucesso!',
    });
  }

  createVoltageChart(): void {
    let voltageData: any[] = [];
    let currentData: any[] = [];
    let apparentPowerData: any[] = [];
    let consumptionData: any[] = [];
    let powerFactorData: any[] = [];
    let dateTimeData: any[] = [];

    this.chartData.forEach((chartData: any) => {
      voltageData.push(chartData.voltage);
      currentData.push(chartData.current);
      apparentPowerData.push(chartData.apparentPower);
      consumptionData.push(chartData.consumption);
      powerFactorData.push(chartData.powerFactor);
      dateTimeData.push(chartData.dateTime);
    });

    /** Caso não haja dados de consumo **/
    if (this.chartData.length < 1) {

      /** Limpa a lista de datas **/
      this.datesInRange = [];

      /** Contantes utilizadas para armazenar as data incial e final do formulário convertidas **/
      const startDate = new Date(JSON.stringify(this.range?.value?.startDate).replace(/"/g, ''));

      const endDate = new Date(JSON.stringify(this.range?.value?.endDate).replace(/"/g, ''));

      /** Enquanto a data inicial for menor que a dara final **/
      while (startDate <= endDate) {
        /** Armazena a data em uma lista de ranges **/
        this.datesInRange.push(new Date(startDate));
        /** Seta a data inicial somando +1 **/
        startDate.setDate(startDate.getDate() + 1);
      }
      /** Limpa o gráfico **/
      this.chartVariable ? this.chartVariable.destroy() : null;

      /** Exibe o gráfico com a linha horizontal **/
      this.chartVariable = this.reportChartService.generateEmptyConsumptionChart(this.datesInRange);
    }
    /** Caso existam dados de consumo **/
    else {

      this.chartVariable ? this.chartVariable.destroy() : null;

      /** Exibe os dados de consumo conforme os dados existentes **/
      this.chartVariable = this.reportChartService.generateChart(
        voltageData,
        currentData,
        apparentPowerData,
        consumptionData,
        dateTimeData,
        powerFactorData
      );
    }

    this.inInitialState = false;

    Swal.fire({
      icon: 'success',
      title: 'Sucesso!',
      text: 'Gráfico gerado com sucesso!',
    });
  }

  resetForm(): void {
    //limpar o formulário
    this.range.reset();
    this.selectedInstallationForm.reset();
    this.installationReferenceForm.reset();
    this.filteredInstallations = [];
    this.selectedInstallations = [];
    this.step1IsConcluded = false;
    this.step2IsConcluded = false;
    this.step3IsConcluded = false;
    this.isThereInstallationListed = false;
    this.noInstallationWarning = false;
    this.isGeneratingChart = false;
  }
}
