import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import {
  Gateway,
  Installation,
  Command,
  Equipment,
  User,
  ResponseData,
  PaginationInfo
} from './commands-assets/channel-model';
import { CommandsServiceService } from 'src/shared/services/commands-service.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { InstallationsModalComponent } from '../installations-modal/installations-modal.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { CommandsModalComponent } from '../commands-modal/commands-modal.component';
import { ErrorLibService } from 'src/shared/services/error-lib.service';
import { tzConvertUTC2Local } from 'src/assets/convertTimeZone/convertTimeZone';
import { TranslateService } from '@ngx-translate/core';
import jsPDF from 'jspdf';
import { Unsubscriber } from '../unsubscriber/unsubscriber.component';
import { catchError, forkJoin, of } from 'rxjs';

@Component({
  selector: 'app-commands',
  templateUrl: './commands.component.html',
  styleUrls: ['./commands.component.less'],
})
export class CommandsComponent extends Unsubscriber implements OnInit {

  ngOnInit(): void {

    this.filterByGateway();

    /** Verifica se uma empresa foi selecionada pelo usuário **/
    this.company = localStorage.getItem('lastCompanySelected') ? localStorage.getItem('lastCompanySelected') : null;

    // Verifica se existe instalações selecionadas
    if (
      localStorage.getItem('selectedInstallations') &&
      JSON.parse(localStorage.getItem('selectedInstallations')!).length > 0
    ) {

      // Mostra a barra de selcionado
      this.selectedInstallationsView = true;

      // Armazena e envia para a barra de selecionados as instalações
      this.selectedInstallations = JSON.parse(localStorage.getItem('selectedInstallations')!);

      // Armazena as instalações selecionadas na lista de instalações da página
      this.selectedInstallations.forEach((installation: any) => {
        this.installationList.push(
          new Installation(
            installation.deviceMac,
            installation.reference
          )
        );
      })
    }
  }

  constructor(
    private commandsServiceService: CommandsServiceService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private errorLibService: ErrorLibService,
    private translateService: TranslateService
  ) {
    super();
  }

  /** Utilizado para acessar o campo de input dos arquivos **/
  @ViewChild('fileUploadSimple') fileUploadSimple: ElementRef;

  /** Variável que contém a empresa selecionada **/
  public company: any = null;

  /** Propriedades utilizadas para manipulação dos arquivos de importação **/
  public data: string;
  public dataToImport: Array<any> = [];

  /** Propriedade que manipula o botão de importação **/
  public isDisabledImportButton: boolean = true;

  /** Lista que armazena a lista de instalações não encontradas no arquivo de importação **/
  public installationsNotFound: Array<string> = new Array<string>();

  // Armazena os IDs de cada instalação selecionada pelo usuário
  public installationList: Array<Installation> = [];

  // Informa ao hmtl quando alguma requisição esta sendo feita, ela inicia como false e no inicio de cada
  // requisição é setada para true e após o recebimento da resposta deve retornar p/ false
  public isLoadingInstallationFilter: boolean = false;

  public isLoadingImportFilter: boolean = false;

  // Responsável por abrir e fechar a sidebar de filtro da tabela
  public isFilterTable: boolean = false;

  // Armazena os IDs dos gateways de cada empresa, a lista inicia vázia
  // após a alteração da empresa seu valor é limpo novamente e preenchido com os IDs da ultima empresa selecionada
  public gatewayList: Gateway[] = [];

  // Manipula a renderização da barra de instalações selecionadas
  public selectedInstallationsView: boolean = false;

  // Armazena as instalações selecionadas(Componente externo)
  public selectedInstallations = [];

  // Informa se a sidebar de consulta esta aberta
  public consultSidebar: boolean = false;

  // Informa se a sidebar de agendamentos esta aberta
  public schedulingSidebar: boolean = false;

  // Variavel responsável por abrir e fechar sidebar que filtra as instalações
  public filterInstallation: boolean = false;

  // Variavel responsável por setar a cor do slide-toggle
  public color: ThemePalette = 'primary';
  // Marcador para os campos de consulta
  public consultIsChecked: boolean = false;
  // Variavel responsável por armazenar o valor escolhido pelo usuário no campo "Consultar"
  public consultValue: string = '';

  //Campo responsável por mostrar as opções ligar/desligar
  public turnOn: boolean = false;
  // Variavel armazena o valor do campo ligar/desligar
  public turnOnValue: any = null;

  //Variavel responsável por armazenar o valor do campo configurar
  public setUp: boolean = false;

  // Campo responsável por setar a variavel que sera configurada
  public settingsForm: string | null = null;

  // Armazena o valor de filtro da tabela
  public tableFilterForm: string | null = null;

  //Valor do campo de dimerização
  public dimmingValue: number = 100;

  //Variavel responsável por armazenar o value do comando TGE
  public minTGE: number | null = null;

  //Variavel responsável por armazenar o value do comando MCA
  public valueMCA: boolean | null = null;

  //Varia responsável por armazenar o value do comando PSL
  public valuePSL: boolean | null = null;

  //Varia responsável por armazenar o value do comando OPM
  public valueOPM: number | null = 3;

  // Time inicial da tela de agendamento
  public switchOnTime: string = '00:00';

  // Time final da tela de agendamento
  public switchOffTime: string = '00:00';

  // Canal de dimerização do agendamento
  public scheduleChannel: number = 1;

  // Armazena os comandos feitos pelo usuário( Serão renderizados na tabela )
  public ELEMENT_DATA: Command[] = [];

  // Seta o nome de cada coluna da tabela
  public displayedColumns: string[] = [
    'demo-date',
    'demo-type',
    'demo-sendBy',
    'demo-answer',
    'demo-view',
  ];

  // Variável que guarda o número de relatórios retornados do backend.
  public resultsLength = this.ELEMENT_DATA.length;

  // Renderiza os comandos da lista ELEMENT_DATA para a tabela
  public dataSource = new MatTableDataSource<Command>(this.ELEMENT_DATA);

  //Variáveis usadas para o paginator e para o sort.
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  // Armazena os dados do formulário de filtro das instalações, o valor é setado automáticamente pelo HTML
  public installationForm: FormGroup = this.formBuilder.group({
    reference: [null],
    gateway: [null],
    equipmentType: [null],
  });

  @ViewChild(MatPaginator, { static: true }) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  /** Variáveis utilizadas para manipulação do Paginator **/
  public pageIndex: number = 0;
  public pageSize: number = 10;
  public length: number = 1;

  /** Variável utilizada para controle de paginação **/
  public paginationProperties: PaginationInfo = new PaginationInfo(
    10000,
    0,
    true,
    false,
    null,
    null
  );
  /**
   * Função que configura o paginator.
   */
  public setDataSourceAttributes() {
    this.dataSource.data = this.ELEMENT_DATA;
    this.dataSource.paginator = this.paginator;
  }

  /**
   * Função que verifica o tipo de consulta que o usuário deseja fazer e impede conflitos de busca.
   */
  public checkConsult(consult: any): void {
    switch (consult) {
      // Caso o parâmetro seja de consulta
      case 'consult':
        this.turnOn = false;
        this.turnOnValue = null;
        this.setUp = false;
        break;
      // Caso o parâmetro seja de comando p/ ligar ou desligar
      case 'turnOn':
        this.consultIsChecked = false;
        this.consultValue = '';
        this.setUp = false;
        break;
      // Caso o parâmetro seja de configuração
      case 'setUp':
        this.turnOn = false;
        this.turnOnValue = null;
        this.consultIsChecked = false;
        this.consultValue = '';
        break;
      default:
        break;
    }
  }

  /**
   * Método que realiza o filtro por gateway
   ***/
  public filterByGateway() {
    this.subscriptions = this.commandsServiceService.filterInstallationByGateway().valueChanges.subscribe({
      next: ((response: any) => {
        response.data.gatewayInstallation.edges.forEach((node: any) => {
          this.gatewayList.push(new Gateway(
            node?.node?.id,
            node?.node?.reference
          ))
        })
      })
    })
  }


  /** Função que controla os botões de anterior e pŕoximo no paginator **/
  public pageChanged(event: any) {
    /** Atualiza o índice da página atual **/
    this.pageIndex = event.pageIndex;

    /**
     * Caso o botão pressionado seja o de página anterior utiliza o
     * startCursor como cursor para o novo filtro
     *
     * Caso o botão pressionado seja o de próxima página é utilizado
     * o endCursor como cursor
     */
    if (event.previousPageIndex > event.pageIndex) {
      this.handlePreviousPage(this.paginationProperties.startCursor)
    } else if (event.previousPageIndex < event.pageIndex) {
      this.filterCommands(this.paginationProperties.endCursor)
    }
  }
  /**
   * Função que atualiza os valores da tabela
   */
  public filterCommands(cursor: string | null): void {
    // // Regex usado para remover o timezone das datas criadas com a função tzConvertUTC2Local
    // const regex = /(.*)\s(\d{2}:\d{2}:\d{2})\s(.*)/;

    //Limpa a lista de comandos
    this.ELEMENT_DATA = [];

    // Cria contagem de resposta dos equipamentos
    let equipmentsQueued = 0;

    if (this.company !== null) {
      // Ativa loading na tela
      this.isLoadingInstallationFilter = true;
      // Faz a requisição de comandos sem filtro( Todos os comandos )

      this.subscriptions = this.commandsServiceService
        .filterCommands(
          this.company,
          this.tableFilterForm,
          'REQUEST_DATETIME',
          'DESC',
          cursor
        )
        .valueChanges.subscribe({
          next: (response) => {

            this.paginationProperties = new PaginationInfo(
              10000,
              response.data?.commandBulkRequests?.pageInfo?.count,
              response.data?.commandBulkRequests?.pageInfo?.hasNextPage,
              response.data?.commandBulkRequests?.pageInfo?.hasPreviousPage,
              response.data?.commandBulkRequests?.pageInfo?.startCursor,
              response.data?.commandBulkRequests?.pageInfo?.endCursor
            )
            if (this.paginationProperties.hasNextPage == true) {
              this.length = this.length + 10;
            }
            // Array usado para armazenar todos os comandos
            let commands: Array<Command> = []

            // Desativa loading na tela
            this.isLoadingInstallationFilter = false;
            response.data.commandBulkRequests.edges.forEach((node: any) => {
              // Armazenas os equipamentos envolvidos em cada comando
              let bulkEquipments: Array<Equipment> = [];

              node.node.commands.edges.forEach((command: any) => {

                /** Condicionais utilizadas para conseguir realizar a tradução de alguns dados de resposta dos comandos vindos da api
                 * Buscar maneiras de otimizar
                 * **/
                if (command.node.responseData.relay === true) {
                  this.subscriptions = this.translateService.get('commands.relay-true').subscribe((message: string) => {
                    command.node.responseData.relay = message
                  })
                }

                else if (command.node.responseData.relay === false) {
                  this.subscriptions = this.translateService.get('commands.relay-false').subscribe((message: string) => {
                    command.node.responseData.relay = message
                  })
                }

                /** Tradução quando os elementos abaixo existirem (true)
                 * has_digital_measurement;
                 * has_ldr;
                 * has_current_consumption
                 *  **/
                if (command.node.responseData.has_digital_measurement === true || command.node.responseData.has_ldr === true || command.node.responseData.has_current_consumption === true) {
                  this.subscriptions = this.translateService.get('commands.has-command-true').subscribe((message: string) => {
                    command.node.responseData.has_digital_measurement = message
                    command.node.responseData.has_ldr = message
                    command.node.responseData.has_current_consumption = message
                  })
                }

                /** Caso sejam false **/
                else if (command.node.responseData.has_digital_measurement === false || command.node.responseData.has_ldr || command.node.responseData.has_current_consumption) {
                  /** Passa as traduções definidas nos arquivos json de traduções  **/
                  this.subscriptions = this.translateService.get('commands.has-command-false').subscribe((message: string) => {
                    /** Define a tradução para cada um dos elementos **/
                    command.node.responseData.has_digital_measurement = message
                    command.node.responseData.has_ldr = message
                    command.node.responseData.has_current_consumption = message
                  })
                }

                /** Caso a prioridade do ldr seja true **/
                if (command.node.responseData.ldr_priority === true) {
                  /** Passa as traduções definidas nos arquivos json de traduções **/
                  this.subscriptions = this.translateService.get('commands.prority-ldr-true').subscribe((message: string) => {
                    /** Define a tradução para cada o elemento **/
                    command.node.responseData.ldr_priority = message
                  })
                }

                /** Caso não possua ldr_priority **/
                else if (command.node.responseData.ldr_priority === false) {
                  /** Passa as traduções definidas nos arquivos json de traduções **/
                  this.subscriptions = this.translateService.get('commands.priority-ldr-false').subscribe((message: string) => {
                    command.node.responseData.ldr_priority = message
                  })
                }

                bulkEquipments.push(
                  new Equipment(
                    command?.node?.id,
                    command?.node?.reference,
                    command?.node?.parameterReference,
                    command?.node?.macAddress,
                    command?.node?.status,
                    tzConvertUTC2Local(command?.node?.responseGenerationDt),
                    tzConvertUTC2Local(command?.node?.responseReceiptDt),
                    command?.node?.commandReference,
                    command?.node?.layout,
                    /** Passando os elementos dos status dos comandos para ser renderizado no modal de status de comandos **/
                    new ResponseData(
                      command?.node?.responseData?.relay,
                      command?.node?.responseData?.ir,
                      command?.node?.responseData?.tga,
                      command?.node?.responseData?.tge,
                      command?.node?.responseData?.tgm,
                      command?.node?.responseData?.tc,
                      command?.node?.responseData?.ts,
                      command?.node?.responseData?.has_digital_measurement,
                      command?.node?.responseData?.has_ldr,
                      command?.node?.responseData?.ldr_priority,
                      command?.node?.responseData?.lux_switch_on_value,
                      command?.node?.responseData?.lux_switch_off_value,
                      command?.node?.responseData?.ldr_check_interval,
                      command?.node?.responseData?.lamp_status_detection,
                      command?.node?.responseData?.message_bitmask,
                      command?.node?.responseData?.dissociation_interval,
                      command?.node?.responseData?.ts_app,
                      command?.node?.responseData?.dimming,
                      command?.node?.responseData?.current_consumption,
                      command?.node?.responseData?.has_current_consumption,
                      command?.node?.responseData?.light_sensor_value,
                      command?.node?.responseData?.operating_voltage,
                      command?.node?.responseData?.meter_current_consumption,
                      command?.node?.responseData?.active_power,
                      command?.node?.responseData?.reactive_power,
                      command?.node?.responseData?.active_energy,
                      command?.node?.responseData?.power_factor,
                      command?.node?.responseData?.ts_reference,
                      command?.node?.responseData?.major,
                      command?.node?.responseData?.minor,
                      command?.node?.responseData?.rev,
                      command?.node?.responseData?.serial_number,
                      command?.node?.responseData?.switch_on_time,
                      command?.node?.responseData?.switch_off_time,
                      command?.node?.responseData?.nacks_count,
                      command?.node?.responseData?.acks_count,
                      command?.node?.responseData?.reboots,
                      command?.node?.responseData?.command_fail_count,
                      command?.node?.responseData?.internal_temperature,
                      command?.node?.responseData?.lamp_control_mode
                    ),
                    /** Informações dos comandos para passar ao componente do modal dos elementos de status des comandos  **/
                    new Command(
                      command?.node?.commands?.id,
                      node?.node?.parameter,
                      null,
                      tzConvertUTC2Local(node?.node?.requestDt),
                      this.checkTypeCommand(node?.node),
                      new User(node?.node?.user?.firstName, node?.node?.user?.lastName),
                      equipmentsQueued,
                      node?.node?.value,
                      bulkEquipments,
                    )
                  )
                );

                if (
                  command.node.status === 'SUCCESS' ||
                  command.node.status === 'DEV_ERR'
                ) {
                  equipmentsQueued += 1;
                }
              });
              // Armazena os comandos criados com seus equipamentos
              commands.push(
                new Command(
                  node?.node?.id,
                  node?.node?.parameter,
                  null,
                  tzConvertUTC2Local(node?.node?.requestDt),
                  this.checkTypeCommand(node.node),
                  new User(node?.node?.user?.firstName, node?.node?.user?.lastName),
                  equipmentsQueued,
                  node?.node?.value,
                  bulkEquipments,
                ))

              equipmentsQueued = 0;
            });

            this.checkReference(commands)

            this.tableFilterForm = null;
          },
          error: (error) => {
            // Desativa loading na tela
            this.isLoadingInstallationFilter = false;
            console.error('filterCommands', error);

            // Emite uma mensagem de erro ao usuário
            this.errorLibService.errorAlert(error);
            console.log(error);
          },
        });
    }
  }

  /**
  * Função que retorna os valores anteriores da tabela
  */
  public handlePreviousPage(cursor: string | null): void {

    // Regex usado para remover o timezone das datas criadas com a função tzConvertUTC2Local
    // const regex = /(.*)\s(\d{2}:\d{2}:\d{2})\s(.*)/;

    //Limpa a lista de comandos
    this.ELEMENT_DATA = [];

    // Cria contagem de resposta dos equipamentos
    let equipmentsQueued = 0;

    if (this.company !== null) {
      // Ativa loading na tela
      this.isLoadingInstallationFilter = true;
      // Faz a requisição de comandos sem filtro( Todos os comandos )

      this.subscriptions = this.commandsServiceService.handlePreviousPage(
        this.company,
        this.tableFilterForm,
        'REQUEST_DATETIME',
        'DESC',
        cursor,
      )
        .valueChanges.subscribe({
          next: (response) => {

            this.paginationProperties = new PaginationInfo(
              10000,
              response.data?.commandBulkRequests?.pageInfo?.count,
              response.data?.commandBulkRequests?.pageInfo?.hasNextPage,
              response.data?.commandBulkRequests?.pageInfo?.hasPreviousPage,
              response.data?.commandBulkRequests?.pageInfo?.startCursor,
              response.data?.commandBulkRequests?.pageInfo?.endCursor
            );
            // Verifica se existe mais de 10 equipamentos antes de alterar a quantidade
            (this.length - 10) < 10 ? this.length = 11 : this.length - 10;

            // Desativa loading na telsa
            this.isLoadingInstallationFilter = false;

            // Array utilizado para armazenar os comandos
            let commands: Array<Command> = [];

            response.data.commandBulkRequests.edges.forEach((node: any) => {
              // Armazenas os equipamentos envolvidos em cada comando
              let equipments: Array<Equipment> = [];

              node.node.commands.edges.forEach((command: any) => {

                /** Condicionais utilizadas para conseguir realizar a tradução de alguns dados de resposta dos comandos vindos da api
                 * Buscar maneiras de otimizar
                 * **/
                if (command.node.responseData.relay === true) {
                  this.subscriptions = this.translateService.get('commands.relay-true').subscribe((message: string) => {
                    command.node.responseData.relay = message
                  })
                }

                else if (command.node.responseData.relay === false) {
                  this.subscriptions = this.translateService.get('commands.relay-false').subscribe((message: string) => {
                    command.node.responseData.relay = message
                  })
                }

                /** Tradução quando os elementos abaixo existirem (true)
                 * has_digital_measurement;
                 * has_ldr;
                 * has_current_consumption
                 *  **/
                if (command.node.responseData.has_digital_measurement === true || command.node.responseData.has_ldr === true || command.node.responseData.has_current_consumption === true) {
                  this.subscriptions = this.translateService.get('commands.has-command-true').subscribe((message: string) => {
                    command.node.responseData.has_digital_measurement = message
                    command.node.responseData.has_ldr = message
                    command.node.responseData.has_current_consumption = message
                  })
                }

                /** Caso sejam false **/
                else if (command.node.responseData.has_digital_measurement === false || command.node.responseData.has_ldr || command.node.responseData.has_current_consumption) {
                  /** Passa as traduções definidas nos arquivos json de traduções  **/
                  this.subscriptions = this.translateService.get('commands.has-command-false').subscribe((message: string) => {
                    /** Define a tradução para cada um dos elementos **/
                    command.node.responseData.has_digital_measurement = message
                    command.node.responseData.has_ldr = message
                    command.node.responseData.has_current_consumption = message
                  })
                }

                /** Caso a prioridade do ldr seja true **/
                if (command.node.responseData.ldr_priority === true) {
                  /** Passa as traduções definidas nos arquivos json de traduções **/
                  this.subscriptions = this.translateService.get('commands.prority-ldr-true').subscribe((message: string) => {
                    /** Define a tradução para cada o elemento **/
                    command.node.responseData.ldr_priority = message
                  })
                }

                /** Caso não possua ldr_priority **/
                else if (command.node.responseData.ldr_priority === false) {
                  /** Passa as traduções definidas nos arquivos json de traduções **/
                  this.subscriptions = this.translateService.get('commands.priority-ldr-false').subscribe((message: string) => {
                    command.node.responseData.ldr_priority = message
                  })
                }
                // Para cada comando é adicionado os equipamentos referentes a ele na lista
                equipments.push(

                  new Equipment(
                    command?.node?.id,
                    command?.node?.reference,
                    command?.node?.parameterReference,
                    command?.node?.macAddress,
                    command?.node?.status,
                    tzConvertUTC2Local(command?.node?.responseGenerationDt),
                    tzConvertUTC2Local(command?.node?.responseReceiptDt),
                    command?.node?.commandReference,
                    command?.node?.layout,
                    /** Passando os elementos dos status dos comandos para ser renderizado no modal de status de comandos **/
                    new ResponseData(
                      command?.node?.responseData?.relay,
                      command?.node?.responseData?.ir,
                      command?.node?.responseData?.tga,
                      command?.node?.responseData?.tge,
                      command?.node?.responseData?.tgm,
                      command?.node?.responseData?.tc,
                      command?.node?.responseData?.ts,
                      command?.node?.responseData?.has_digital_measurement,
                      command?.node?.responseData?.has_ldr,
                      command?.node?.responseData?.ldr_priority,
                      command?.node?.responseData?.lux_switch_on_value,
                      command?.node?.responseData?.lux_switch_off_value,
                      command?.node?.responseData?.ldr_check_interval,
                      command?.node?.responseData?.lamp_status_detection,
                      command?.node?.responseData?.message_bitmask,
                      command?.node?.responseData?.dissociation_interval,
                      command?.node?.responseData?.ts_app,
                      command?.node?.responseData?.dimming,
                      command?.node?.responseData?.current_consumption,
                      command?.node?.responseData?.has_current_consumption,
                      command?.node?.responseData?.light_sensor_value,
                      command?.node?.responseData?.operating_voltage,
                      command?.node?.responseData?.meter_current_consumption,
                      command?.node?.responseData?.active_power,
                      command?.node?.responseData?.reactive_power,
                      command?.node?.responseData?.active_energy,
                      command?.node?.responseData?.power_factor,
                      command?.node?.responseData?.ts_reference,
                      command?.node?.responseData?.major,
                      command?.node?.responseData?.minor,
                      command?.node?.responseData?.rev,
                      command?.node?.responseData?.serial_number,
                      command?.node?.responseData?.switch_on_time,
                      command?.node?.responseData?.switch_off_time,
                      command?.node?.responseData?.nacks_count,
                      command?.node?.responseData?.acks_count,
                      command?.node?.responseData?.reboots,
                      command?.node?.responseData?.command_fail_count,
                      command?.node?.responseData?.internal_temperature,
                      command?.node?.responseData?.lamp_control_mode
                    ),
                    /** Informações dos comandos para passar ao componente do modal dos elementos de status des comandos  **/
                    new Command(
                      command?.node?.commands?.id,
                      node?.node?.parameter,
                      null,
                      tzConvertUTC2Local(node?.node?.requestDt),
                      this.checkTypeCommand(node?.node),
                      new User(node?.node?.user?.firstName, node?.node?.user?.lastName),
                      equipmentsQueued,
                      node?.node?.value,
                      equipments,
                    )
                  )
                );

                if (
                  command.node.status === 'SUCCESS' ||
                  command.node.status === 'DEV_ERR'
                ) {
                  equipmentsQueued += 1;
                }
              });

              // Armazena os comandos com seus equipamentos na lista
              commands.push(new Command(
                node?.node?.id,
                node?.node?.parameter,
                null,
                tzConvertUTC2Local(node?.node?.requestDt),
                this.checkTypeCommand(node.node),
                new User(node?.node?.user?.firstName, node?.node?.user?.lastName),
                equipmentsQueued,
                node?.node?.value,
                equipments,
              ))

              equipmentsQueued = 0;
            });
            // Função que verifica se não existem referências nulas
            this.checkReference(commands);

            this.tableFilterForm = null;
          },
          error: (error) => {
            // Desativa loading na tela
            this.isLoadingInstallationFilter = false;
            console.error('filterCommands', error);

            // Emite uma mensagem de erro ao usuário
            this.errorLibService.errorAlert(error);
            console.log(error);
          },
        });
    }

  }

  // Função utilizada para verificar se existem comandos de caixas de circuito
  checkReference(commands: Array<Command>) {
    // Percorre a lista de comandos e procura instalações sem referência
    this.ELEMENT_DATA = [];
    commands.forEach(command => {
      command.equipments.forEach(equipment => {
        // Define a referência como "Carregando..." enquanto aguarda a resposta
        if (equipment.reference == null) {
          equipment.reference = "Carregando..."

          this.commandsServiceService
            .getCircuitBoxesByCompanyId(
              this.company,
              null,
              equipment.macAddress
            ).subscribe({
              next: (response: any) => {
                // substitui a referência nula pela referência da caixa de circuito
                equipment.reference = response.data?.circuitBox?.edges[0]?.node.reference;
              }
            });
        }

      })
      // Atualiza a lista que preenche a tabela
      this.ELEMENT_DATA.push(command);
    });
    // Atualiza a tabela
    this.setDataSourceAttributes();

  }

  /**
   * Função responsável por fazer agendamentos
   */
  public scheduleQuery(): any {
    // Seta a data e a hora atual
    const timestamp = new Date().toISOString();
    // Lista que recebe os IDs das instalações selecionadas
    const list: Array<string | null> = [];

    this.installationList.forEach((installation) => {
      list.push(installation.id);
    });

    // Verifica se os valores estão em conflito
    if (this.switchOnTime === this.switchOffTime) {
      return Swal.fire({
        title: 'Valores conflitantes!',
        text: 'Um horário deve ser diferente do outro',
        icon: 'warning',
        confirmButtonText: 'Ok',
      });
    }
    // carrega o loading na tela
    this.isLoadingInstallationFilter = true;
    // Faz a requisição com os parãmetros de agendamento informados pelo usuário
    this.subscriptions = this.commandsServiceService
      .setSchedule(
        list,
        this.scheduleChannel,
        this.dimmingValue,
        this.switchOnTime,
        this.switchOffTime,
        timestamp
      )
      .subscribe({
        next: (response) => {
          // Desativa o loading da tela
          this.isLoadingInstallationFilter = false;
          // Informa ao usuário que a requisição foi concluída com sucesso
          Swal.fire({
            title: 'Enviado!',
            text: 'Agendamento concluído! Carregando tabela...',
            icon: 'success',
            confirmButtonText: 'Ok',
          });

          this.tableFilterForm = "SET_SCHEDULE";
          this.filterCommands(null);
        },
        error: (error) => {
          // Mostra no console o erro p/ fins de facilidade na resolução do problema e não congelar a aplicação do usuário
          console.log(error);
          // DEsativa loading da tela
          this.isLoadingInstallationFilter = false;
          // Informa ao usuário o erro
          Swal.fire({
            title: 'Erro!',
            text: 'Não foi possivel fazer este agendamento, tente novamente!',
            icon: 'error',
            confirmButtonText: 'Ok',
          });
        },
      });
  }

  /**
   * Função responsável por fazer as consultas
   */
  public consultationQuery(): void {
    const timestamp = new Date().toISOString();
    const list: Array<string | null> = [];

    this.installationList.forEach((installation) => {
      //atualiza a lista de IDs das instalações
      list.push(installation.id);
    });

    //Consultar agendamento
    if (this.consultValue === 'GET_SCHEDULE') {
      // Ativa loading na tela
      this.isLoadingInstallationFilter = true;
      //Faz a requisição de consulta
      this.subscriptions = this.commandsServiceService
        .sendScheduleCommand(list, timestamp, this.scheduleChannel)
        .subscribe({
          next: (response) => {
            // Desativa loading na tela
            this.isLoadingInstallationFilter = false;
            // Informa ao usuário que a requisição foi concluída
            Swal.fire({
              title: 'Enviado!',
              text: 'Consulta concluída! Carregando tabela...',
              icon: 'success',
              confirmButtonText: 'Ok',
            });
          },
          error: (err) => {
            // Desativa loading na tela
            this.isLoadingInstallationFilter = false;
            // Mostra no console o erro p/ fins de facilidade na resolução do problema e não congelar a aplicação do usuário
            console.log(err);
            // Informa ao usuário o erro
            Swal.fire({
              title: 'Erro!',
              text: 'Não foi possivel fazer esta consulta, tente novamente!',
              icon: 'error',
              confirmButtonText: 'Ok',
            });
          },
        });
    }
    // Consulta o parâmetro selecionado pelo usuário
    else if (this.consultValue !== '') {
      // Carrega o loading na tela
      this.isLoadingInstallationFilter = true;
      // Envia a requisição
      this.subscriptions = this.commandsServiceService
        .sendQueryCommand(list, timestamp, this.consultValue)
        .subscribe({
          next: (response) => {
            // Desativa o loading na tela
            this.isLoadingInstallationFilter = false;
            // Informa ao usuário que a requisição foi concluída
            Swal.fire({
              title: 'Enviado!',
              text: 'Consulta concluída! Carregando tabela...',
              icon: 'success',
              confirmButtonText: 'Ok',
            });
          },
          error: (error) => {
            // Mostra no console o erro p/ fins de facilidade na resolução do problema e não congelar a aplicação do usuário
            console.log(error);
            // Desativa o loading na tela
            this.isLoadingInstallationFilter = false;
            // Informa o erro ao usuário
            Swal.fire({
              title: 'Erro!',
              text: 'Não foi possivel fazer esta consulta, tente novamente!',
              icon: 'error',
              confirmButtonText: 'Ok',
            });
          },
        });
    }
    // Checa se o formulário foi preenchido corretamente
    else {
      Swal.fire({
        title: 'Preenchimento incompleto!',
        text: 'Selecione um parâmetro de consulta',
        icon: 'warning',
        confirmButtonText: 'Ok',
      });
    }
  }

  /**
   * Função responsável por ligar e desligar os equipamentos
   */
  public turnOnQuery(): void {
    // Armazena data e hora atual
    const timestamp = new Date().toISOString();
    // reseta lista de IDs
    const list: Array<string | null> = [];

    this.installationList.forEach((installation) => {
      // Atualiza IDs selecionados
      list.push(installation.id);
    });

    // Checa se o usuário quer ligar os equipamentos
    if (this.turnOnValue === 'on') {
      // Ativa loading na tela
      this.isLoadingInstallationFilter = true;
      // Envia a requisição
      this.subscriptions = this.commandsServiceService
        .sendSwitchOnCommand(list, timestamp)
        .subscribe({
          next: (response) => {
            // Desativa o loading da tela
            this.isLoadingInstallationFilter = false;
            // Informa ao usuário que a requisição foi concluída
            Swal.fire({
              title: 'Enviado!',
              text: 'Comando enviado! Carregando tabela...',
              icon: 'success',
              confirmButtonText: 'Ok',
            });

            this.tableFilterForm = "SWITCH_ON";
            this.filterCommands(null);
          },
          error: (error) => {
            // Mostra no console o erro p/ fins de facilidade na resolução do problema e não congelar a aplicação do usuário
            console.log(error);
            // Desativa loading da tela
            this.isLoadingInstallationFilter = false;
            // Emite uma mensagem de erro ao usuário
            this.errorLibService.errorAlert(error);
          },
        });
    }
    // Checa se o usuário quer desligar os equipamentos
    else if (this.turnOnValue === 'off') {
      // Ativa loading na tela
      this.isLoadingInstallationFilter = true;
      // Faz a requisição
      this.subscriptions = this.commandsServiceService
        .sendSwitchOffCommand(list, timestamp)
        .subscribe({
          next: (response) => {
            // Desativa o loading da tela
            this.isLoadingInstallationFilter = false;
            // Informa ao usuário que a requisição foi concluída
            Swal.fire({
              title: 'Enviado!',
              text: 'Comando enviado! Carregando tabela...',
              icon: 'success',
              confirmButtonText: 'Ok',
            });

            this.tableFilterForm = "SWITCH_OFF";
            this.filterCommands(null);
          },
          error: (error) => {
            // Mostra no console o erro p/ fins de facilidade na resolução do problema e não congelar a aplicação do usuário
            console.log(error);
            // Desativa loading da tela
            this.isLoadingInstallationFilter = false;
            // Emite uma mensagem de erro ao usuário
            this.errorLibService.errorAlert(error);
          },
        });
    }
    // Caso nenhuma opção tenha sido selecionada
    else {
      Swal.fire({
        title: 'Erro!',
        text: 'Selecione uma das opções para enviar este comando!',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
    }
  }

  /**
   * Função responsável por configurar equipamentos
   */
  public setUpQuery(): any {
    // data e hora atual
    const timestamp = new Date().toISOString();
    // Esvazia lista de IDs
    const list: Array<string | null> = [];

    this.installationList.forEach((installation) => {
      // Atualiza lista de IDs
      list.push(installation.id);
    });

    // // Comando TGE (Desativado por solicitação em asgard#531)
    // if (this.settingsForm === 'TGE') {
    //   // Checa se o usuário preencheu o campo necessário
    //   if (this.minTGE === null) {
    //     return Swal.fire({
    //       title: 'Erro!',
    //       text: 'Informe um valor em minutos para enviar este comando!',
    //       icon: 'error',
    //       confirmButtonText: 'Ok',
    //     });
    //   }
    //   // Checa se o número informado é válido
    //   else if (this.minTGE > 32767) {
    //     return Swal.fire({
    //       title: 'Valor maxímo excedido!',
    //       text: 'Informe uma valor abaixo de 32767',
    //       icon: 'warning',
    //       confirmButtonText: 'Ok',
    //     });
    //   }
    //   // Permite requisição
    //   else {
    //     // Ativa loading na tela
    //     this.isLoadingInstallationFilter = true;
    //     // Faz a requisição
    //     this.subscriptions = this.commandsServiceService
    //       .SendSetParameterCommand(
    //         list,
    //         this.settingsForm,
    //         timestamp,
    //         this.minTGE
    //       )
    //       .subscribe({
    //         next: (response) => {
    //           // Desativa o loading
    //           this.isLoadingInstallationFilter = false;
    //           // Informa ao usuário
    //           Swal.fire({
    //             title: 'Enviado!',
    //             text: 'Comando enviado! Carregando tabela...',
    //             icon: 'success',
    //             confirmButtonText: 'Ok',
    //           });
    //         },
    //         error: (error) => {
    //           // Mostra no console o erro p/ fins de facilidade na resolução do problema e não congelar a aplicação do usuário
    //           console.log(error);
    //           // Desativa o loading
    //           this.isLoadingInstallationFilter = false;
    //           // Informa ao usuário
    //           Swal.fire({
    //             title: 'Erro!',
    //             text: 'Não foi possivel enviar este comando, tente novamente!',
    //             icon: 'error',
    //             confirmButtonText: 'Ok',
    //           });
    //         },
    //       });
    //   }
    // }
    //Comando DIM
    if (this.settingsForm === 'DIM') {
      // Ativa loading na tela
      this.isLoadingInstallationFilter = true;
      // Faz a requisição
      this.subscriptions = this.commandsServiceService
        .SendSetParameterCommand(
          list,
          this.settingsForm,
          timestamp,
          this.dimmingValue
        )
        .subscribe({
          next: (response) => {
            // Desativa loading na tela
            this.isLoadingInstallationFilter = false;
            // Informa ao usuário
            Swal.fire({
              title: 'Enviado!',
              text: 'Comando enviado! Carregando tabela...',
              icon: 'success',
              confirmButtonText: 'Ok',
            });
          },
          error: (error) => {
            // Desativa loading na tela
            this.isLoadingInstallationFilter = false;
            // Mostra no console o erro p/ fins de facilidade na resolução do problema e não congelar a aplicação do usuário
            console.log(error);
            // Emite uma mensagem de erro ao usuário
            this.errorLibService.errorAlert(error);
          },
        });
    }
    // Comando PSL
    if (this.settingsForm === 'PSL') {
      // Checa se os campos necessários foram preechidos
      if (this.valuePSL === null) {
        Swal.fire({
          title: 'Erro!',
          text: 'Selecione uma das opções para enviar este comando!',
          icon: 'error',
          confirmButtonText: 'Ok',
        });
      } else {
        // Ativa loading na tela
        this.isLoadingInstallationFilter = true;
        this.subscriptions = this.commandsServiceService
          .SendSetParameterCommand(
            list,
            this.settingsForm,
            timestamp,
            this.valuePSL
          )
          .subscribe({
            next: (response) => {
              // Desativa loading na tela
              this.isLoadingInstallationFilter = false;
              // Informa ao usuário
              Swal.fire({
                title: 'Enviado!',
                text: 'Comando enviado! Carregando tabela...',
                icon: 'success',
                confirmButtonText: 'Ok',
              });
            },
            error: (error) => {
              // Desativa loading na tela
              this.isLoadingInstallationFilter = false;
              // Mostra no console o erro p/ fins de facilidade na resolução do problema e não congelar a aplicação do usuário
              console.log(error);
              // Emite uma mensagem de erro ao usuário
              this.errorLibService.errorAlert(error);
            },
          });
      }
    }
    // Comando MCA
    if (this.settingsForm === 'MCA') {
      if (this.valueMCA === null) {
        Swal.fire({
          title: 'Erro!',
          text: 'Selecione uma das opções para enviar este comando!',
          icon: 'error',
          confirmButtonText: 'Ok',
        });
      } else {
        // Ativa loading na tela
        this.isLoadingInstallationFilter = true;
        this.subscriptions = this.commandsServiceService
          .SendSetParameterCommand(
            list,
            this.settingsForm,
            timestamp,
            this.valueMCA
          )
          .subscribe({
            next: (response) => {
              // Desativa loading na tela
              this.isLoadingInstallationFilter = false;
              Swal.fire({
                title: 'Enviado!',
                text: 'Comando enviado! Carregando tabela...',
                icon: 'success',
                confirmButtonText: 'Ok',
              });
            },
            error: (error) => {
              // Desativa loading na tela
              this.isLoadingInstallationFilter = false;
              // Mostra no console o erro p/ fins de facilidade na resolução do problema e não congelar a aplicação do usuário
              console.log(error);
              // Emite uma mensagem de erro ao usuário
              this.errorLibService.errorAlert(error);
            },
          });
      }
    }
    // Comando OPM
    if (this.settingsForm === 'OPM') {
      if (this.valueOPM === null) {
        Swal.fire({
          title: 'Erro!',
          text: 'Selecione uma das opções para enviar este comando!',
          icon: 'error',
          confirmButtonText: 'Ok',
        });
      } else {
        // Ativa loading na tela
        this.isLoadingInstallationFilter = true;
        this.subscriptions = this.commandsServiceService
          .SendSetParameterCommand(
            list,
            this.settingsForm,
            timestamp,
            this.valueOPM
          )
          .subscribe({
            next: (response) => {
              // Desativa loading na tela
              this.isLoadingInstallationFilter = false;
              Swal.fire({
                title: 'Enviado!',
                text: 'Comando enviado! Carregando tabela...',
                icon: 'success',
                confirmButtonText: 'Ok',
              });
            },
            error: (error) => {
              // Desativa loading na tela
              this.isLoadingInstallationFilter = false;
              // Mostra no console o erro p/ fins de facilidade na resolução do problema e não congelar a aplicação do usuário
              console.log(error);
              // Emite uma mensagem de erro ao usuário
              this.errorLibService.errorAlert(error);
            },
          });
      }
    }
  }

  /**
   * Função checa o tipo de consulta o usuário deseja fazer
   */
  public consultationForm(): any {
    this.tableFilterForm = null;

    if (this.consultIsChecked) {
      this.consultationQuery();
      this.tableFilterForm = "QUERY";
      this.filterCommands(null);
    }

    if (this.turnOn) {
      this.turnOnQuery();
    }

    if (this.setUp) {
      this.setUpQuery();
      this.tableFilterForm = "SET_PARAMETER";
      this.filterCommands(null);
    }

  }

  /**
   * Função filtra as empresa por ID
   */
  public getInstallationsByCompanyId(): void {
    // Ativa loading na tela
    this.isLoadingInstallationFilter = true;
    // Armazena as requisições utilizadas para preencher o campo do filtro
    let installations = this.commandsServiceService
      .getInstallationsByCompanyId(
        this.company,
        this.installationForm.get('equipmentType')?.value,
        this.installationForm.get('gateway')?.value,
        this.installationForm.get('reference')?.value
      ).pipe(catchError(error => of(error)))

    let circuitBoxes = this.commandsServiceService
      .getCircuitBoxesByCompanyId(
        this.company,
        this.installationForm.get('reference')?.value,
        null
      ).pipe(catchError(error => of(error)))

    let installationsData: any;
    let circuitData: any
    // Executa as 2 requisições e aguarda o retorno das duas antes de preencher o campo
    forkJoin(
      [circuitBoxes, installations],
    ).subscribe(([resultCircuitBox, resultInstallations]) => {
      // Itera a resposta da requisição de caixas de circuito
      circuitData = resultCircuitBox;
      circuitData?.data?.circuitBox?.edges?.forEach((node: any) => {
        if (node.node.sgIpc?.macAddress) {
          this.installationList.push(
            new Installation(
              node.node.sgIpc.macAddress,
              node.node.reference,
            )
          );
        }
      })
      // Itera a resposta da requisição de instalações
      installationsData = resultInstallations;
      installationsData?.data?.installation?.edges?.forEach((node: any) => {
        this.installationList.push(
          new Installation(
            node.node.device?.macAddress,
            node.node.reference,
          )
        );
      })

      // Retirar instalações repetidas da lista
      const objectMap = this.installationList.reduce((map, object) => {
        map.set(object.id, object);
        return map;
      }, new Map());
      // Atualiza a lista de instalações selecionadas
      this.installationList = Array.from(objectMap, ([_, value]) => value);
      // Envias as instalações selecionadas para a modal
      const dialogRef = this.dialog.open(InstallationsModalComponent, {
        data: { installationList: this.installationList },
        disableClose: true,
      });
      // Recebe a lista após o fechamento da modal
      this.subscriptions = dialogRef.afterClosed().subscribe((result) => {
        if (result !== undefined) {
          this.installationList = result;
        }
      });
      // Atualiza a lista de instalações selecionadas
      this.installationList = Array.from(objectMap, ([_, value]) => value);

      this.isLoadingInstallationFilter = false;

    });

  }

  public sendCommandsToModal(command: any, element: any): void {
    // Envias as instalações selecionadas para a modal
    const dialogRef = this.dialog.open(CommandsModalComponent, {
      data: command,
    });
    // Recebe a lista após o fechamento da modal
    this.subscriptions = dialogRef.afterClosed().subscribe((result) => { });
  }

  /**
   * Função Checa se pelo menos um campo do filtro de instalações foi preenchido
   */
  public checkInstallationFilter(): any {
    if (
      !this.installationForm.get('reference')?.value &&
      !this.installationForm.get('gateway')?.value &&
      !this.installationForm.get('equipmentType')?.value
    ) {
      return true;
    } else {
      false;
    }
  }
  /**
   * Função Envia as instalações selecionadas para a modal
   */
  public openModalPage(): void {
    const dialogRef = this.dialog.open(InstallationsModalComponent, {
      data: { installationList: this.installationList },
      disableClose: true,
    });
    this.subscriptions = dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        this.installationList = result;
      }
    });
  }

  /**
   * Função altera o valor da sidebar de filtro
   */
  public isFilterInstallation(): void {
    this.filterInstallation = !this.filterInstallation;
    this.schedulingSidebar = false;
    this.consultSidebar = false;
    this.isFilterTable = false;
  }

  public filterTable(): void {
    this.filterInstallation = false;
    this.consultSidebar = false;
    this.schedulingSidebar = false;
    this.dimmingValue = 100;
    this.isFilterTable = !this.isFilterTable;
  }
  /**
   * Função abre a sidebar de consulta
   */
  public consultFilter(): void {
    if (this.installationList.length) {
      this.filterInstallation = false;
      this.consultSidebar = !this.consultSidebar;
      this.schedulingSidebar = false;
      this.dimmingValue = 100;
      this.isFilterTable = false;
    } else {
      Swal.fire({
        title: 'Filtrar instalações',
        text: 'É necessário filtrar instalações antes de seguir para este campo!',
        icon: 'warning',
        confirmButtonText: 'Ok',
      });
    }
  }

  /**
   * Função abre a sidebar de agendamentos
   */
  public schedulingSidebarOpen(): void {
    if (this.installationList.length) {
      this.filterInstallation = false;
      this.consultSidebar = false;
      this.schedulingSidebar = !this.schedulingSidebar;
      this.consultIsChecked = false;
      this.isFilterTable = false;
      this.turnOn = false;
      this.turnOnValue = null;
      this.setUp = false;
      this.dimmingValue = 100;
    } else {
      Swal.fire({
        title: 'Filtrar instalações',
        text: 'É necessário filtrar instalações antes de seguir para este campo!',
        icon: 'warning',
        confirmButtonText: 'Ok',
      });
    }
  }

  /**
   * Função traduz o tipo de comandos da tabela
   */
  public checkTypeCommand(node: any): any {
    if (node.type === 'SWITCH_OFF') {
      return 'Desligar';
    } else if (node.type === 'SWITCH_ON') {
      return 'Ligar';
    } else if (node.type === 'SET_SCHEDULE') {
      return 'Agendamento';
    } else if (node.type === 'GET_SCHEDULE') {
      return 'Consultar - Agendamento';
    }

    if (node.parameter !== null) {
      if (node.parameter === 'MCA') {
        return `Configurar - Prioridade de atuação`;
      } else if (node.parameter === 'TGE') {
        return `Configurar - Temporização`;
      } else if (node.parameter === 'PSL') {
        return `Configurar - Sensor de luminosidade`;
      } else if (node.parameter === 'DIM') {
        return `Configurar - Dimerização - ${node.value}%`;
      } else {
        return `Configurar - ${node.parameter}`;
      }
    } else if (node.queryType !== null) {
      if (node.queryType === 'TGE') {
        return `Consultar - Temporização`;
      } else if (node.queryType === 'MCA') {
        return `Consultar - Prioridade de atuação`;
      } else if (node.queryType === 'PSL') {
        return `Consultar - Sensor de luminosidade`;
      } else if (node.queryType === 'DIM') {
        return `Consultar - Dimerização`;
      } else if (node.queryType === 'LATEST_MEASUREMENT') {
        return `Consultar - Última Medição`;
      } else if (node.queryType === 'VERSION') {
        return `Consultar - Versão`;
      } else if (node.queryType === 'STATUS') {
        return 'Consultar - Status';
      } else if (node.queryType === 'MEASUREMENT') {
        return 'Consultar - Medição';
      } else if (node.queryType === 'PARAMETERS') {
        return 'Consultar - Parâmetros';
      } else if (node.queryType === 'DIAGNOSTIC') {
        return 'Consultar - Diagnóstico';
      }
    }
  }

  /** Função que realiza a conversão para arquivo CSV */
  public convertToCSV(arr: any) {
    const array = [Object.keys(arr[0])].concat(arr);

    return array
      .map((it) => {
        return Object.values(it).toString();
      })
      .join(',\n');
  }

  /** Função que realiza o download em CSV */
  public downloadCSV() {
    let listCommands: any = [];

    this.ELEMENT_DATA.forEach((command) => {
      listCommands.push({
        'Data de solicitação': command.requestDt.replace(',', ' -'),
        Tipo: command.type,
        'Enviado por': `${command.user.firstName} ${command.user.lastName}`,
        Resposta: `${(command.equipmentsQueued / command.equipments.length) * 100
          }%`,
      });
    });

    let csvData = this.convertToCSV(listCommands);
    let fileCSV = new Blob([csvData], { type: 'text/csv' });
    let url = window.URL.createObjectURL(fileCSV);
    let a = document.createElement('a');
    a.href = url;

    a.download = `${new Date().toLocaleDateString()}-${new Date().getHours()}:${new Date().getMinutes()}-commands.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    window.URL.revokeObjectURL(url);
  }

  public downloadPDF() {
    const tableLayout = [
      'Data da Solicitação',
      'Tipo',
      'Enviado Por',
      'Resposta',
    ];

    let commands: any = [];

    this.ELEMENT_DATA.forEach((command) => {
      commands.push([
        command.requestDt,
        command.type,
        `${command.user.firstName} ${command.user.lastName}`,
        `(${command.equipmentsQueued}/${command.equipments.length}) ${(command.equipmentsQueued / command.equipments.length) * 100
        }%`,
      ]);
    });

    const orientation = 'l';

    const doc: any = new jsPDF(orientation, "mm", "a4")

    doc.autoTable({
      theme: 'grid',
      margin: { top: 20 },
      head: [tableLayout],
      body: commands,
    });

    doc.save(
      `${new Date().toLocaleDateString()}-${new Date().getHours()}:${new Date().getMinutes()}-commands.pdf`
    );
  }

  /** Método que lê o arquivo CSV para que possa ser filtrado as instalações */
  public async getTextFromFile(event: any) {

    /** Ativa o Loading */
    this.isLoadingImportFilter = false;

    /** Habilita o botão de importação */
    this.isDisabledImportButton = false;

    /** Constante do tipo File */
    const file: File = event.target.files[0];
    let fileContent = await file.text();
    /** Propriedade que recebe os dados do arquivo  */
    this.data = fileContent;
  }

  /** Método que realiza a importação do arquivo CSV validando as instalações do arquivo */
  public async importDataFromCSV() {
    /** Desativa o loading da tela */
    this.isLoadingImportFilter = true;
    /** Armazena os dados do CSV */
    this.dataToImport = this.commandsServiceService.importDataFromCSV(this.data)
    /** Armazena o objeto que contém a propriedade Reference que representa a referência da instalação */
    const referenceInstallation: any = this.dataToImport.map(row => ({
      reference: row.reference
    }));

    /** Para cada elemento do arquivo */
    for (const data of referenceInstallation) {
      /** Caso o cabeçalho seja inválido */
      if (!data.reference) {
        /** exibe um alerta ao usuário */
        await Swal.fire({
          title: 'Cabeçalho ou formato de arquivo incorreto',
          text: 'Por favor, verifique o arquivo e tente novamente',
          icon: 'warning',
          confirmButtonText: 'Ok'
        });
        this.isLoadingImportFilter = false;
        return;
      }

      /** Variável que armazena as referências das instalações */
      let referenceInstallations = data.reference;

      /** Realiza o filtro de instalações por referência, passando como parâmetro a referência existente no arquivo*/
      this.commandsServiceService.getInstallationByReference(referenceInstallations).valueChanges
        .subscribe({
          next: ((res: any) => {
            /** Constante que armazena o nó de todas as referências */
            const installationReference: Installation[] = res?.data?.installation?.edges?.map((edge: any) => edge.node)
              /** Filtro comparando a referência do arquivo com a referência exata já existente **/
              .filter((installation: Installation) =>
                installation?.reference === referenceInstallations
              )

            /** Verifica se a referência da instalação do arquivo foi encontrada */
            if (installationReference.length > 0) {
              /** Armazena as referências na lista de instalações */
              res.data.installation.edges?.forEach((node: any) => {
                this.installationList.push(
                  new Installation(
                    node?.node?.device?.macAddress,
                    referenceInstallations
                  )
                )
              })

              /** Desativa o loading da tela */
              this.isLoadingImportFilter = false;
              /** Habilita o botão de importação */
              this.isDisabledImportButton = true;
            }

            /** Caso a referencia da instalação não seja encontrada **/
            if (installationReference.length === 0) {

              /** Adiciona a(s) referência(s) em uma lista **/
              this.installationsNotFound.push(referenceInstallations)
              /** Caso a lista de referência(s) não encontrada(s) seja igual a quantidade de linhas do arquivo **/
              if (this.installationsNotFound.length === referenceInstallation.length) {
                /** Desativa o loading da tela */
                this.isLoadingImportFilter = false;
                /** Habilita o botão de importação */
                this.isDisabledImportButton = true;
                /** Exibe alerta ao usuário **/
                Swal.fire({
                  title: 'Nenhuma instalação encontrada',
                  text: 'Não foi encontrada nenhuma instalação correspondente à referência informada. Por favor, verifique o arquivo e tente novamente.',
                  icon: 'warning',
                  confirmButtonText: 'Ok'
                });
              }
            }
            /** Limpa o valor do Input de importação */
            this.fileUploadSimple.nativeElement.value = null;
          })
        })
    }
  }
}
