import { gql } from "apollo-angular";

const GET_ALL_REPORTS = gql`
query All(
	$company: ID!
	$reference: String
	$reportType: PromiseReportType
	$requestedBy: String
	$first: Int
	$after: String
	$before: String
	$last: Int
) {
	promiseReports(
		company: $company
		reference_Icontains: $reference
		reportType: $reportType
		requestedBy_Icontains: $requestedBy
		First: $first
		After: $after
		Before: $before
		Last: $last
		orderBy: { direction: DESC, field: GENERATION_DATE }
	) {
		count
		total
		pageInfo {
			hasNextPage
			hasPreviousPage
			startCursor
			endCursor
		}
		edges {
			node {
				id
				generationDate
				requestedBy
				reference
				reportType
				ready
			}
		}
	}
}`;

const GET_REPORT_DOWNLOAD_URL = gql`
query generatedReport($report: ID!, $company: ID!) {
	generatedReport(report: $report, company: $company) {
		preSignedUrl
	}
}`;

const LAST_TRANSMISSION_MUTATION = gql`
mutation LastTransmissionReport($company: ID!, $reference: String!, $timezone: String!) {
	generateLastTransmissionReport(
		input: { company: $company, reference: $reference, timezone: $timezone }
	) {
		reportPromise {
			id
			ready
			reportType
			ready
			generationDate
			requestedBy
			reference
		}
	}
}`;

const REGISTER_INSTALLATIONS_REPORT = gql`
mutation installationsListReport($reference: String!, $company: ID!, $timezone: String!) {
	generateInstallationLogs(
		input: { reference: $reference, company: $company, timezone: $timezone}
	) {
		reportPromise {
			id
			generationDate
			requestedBy
			reference
			reportType
			ready
		}
	}
}`;

const LAST_DATA_MUTATION = gql`
mutation generateLastDataReport($company: ID!, $reference: String!, $timezone: String!) {
	generateLastDataReport(input: { company: $company, reference: $reference, timezone: $timezone}) {
		reportPromise {
			id
			ready
			reportType
			ready
			generationDate
			requestedBy
			reference
		}
	}
}`;

const GENERATE_ALERT_REPORT = gql`
mutation generateGeneralAlerts(
	$company: ID!
	$reference: String!
	$installations: [ID]
	$startDatetime: DateTime!
	$endDatetime: DateTime!
	$timezone: String!
) {
	generateGeneralAlerts(
		input: {
			company: $company
			reference: $reference
			installations: $installations
			startDatetime: $startDatetime
			endDatetime: $endDatetime
			timezone: $timezone
		}
	) {
		reportPromise {
			id
			generationDate
			requestedBy
			reference
			reportType
			ready
		}
	}
}`;


const INDIVIDUAL_CONSUMPTION_REPORT = gql`
mutation IndividualConsumption(
	$company: ID!
	$reference: String!
	$macAddress: String!
	$startDatetime: DateTime!
	$endDatetime: DateTime!
	$kind: IndividualConsumptionKind!
	$timezone: String!
) {
	generateIndividualConsumption(
		input: {
			macAddress: $macAddress
			company: $company
			reference: $reference
			startDatetime: $startDatetime
			endDatetime: $endDatetime
			kind: $kind
			timezone: $timezone
		}
	) {
		reportPromise {
			id
			generationDate
			requestedBy
			reference
			reportType
			ready
		}
	}
}`;

const CIRCUIT_BOX_CONSUMPTION_MUTATION = gql`
mutation CircuitBoxConsumption(
	$company: ID!
	$reference: String!
	$macAddress: String!
	$startDatetime: DateTime!
	$endDatetime: DateTime!
	$timezone: String!
) {
	generateCircuitBoxConsumption(
		input: {
			macAddress: $macAddress
			company: $company
			reference: $reference
			startDatetime: $startDatetime
			endDatetime: $endDatetime
			timezone: $timezone
		}
	) {
		reportPromise {
			id
			generationDate
			requestedBy
			reference
			reportType
			ready
		}
	}
}`;

const INDIVIDUAL_STATUS_MUTATION = gql`
mutation generateIndividualStatus(
	$company: ID!
	$reference: String!
	$macAddress: String!
	$startDatetime: DateTime!
	$endDatetime: DateTime!
	$timezone: String!
) {
	generateIndividualStatus(
		input: {
			macAddress: $macAddress
			company: $company
			reference: $reference
			startDatetime: $startDatetime
			endDatetime: $endDatetime
			timezone: $timezone
		}
	) {
		reportPromise {
			id
			generationDate
			requestedBy
			reference
			reportType
			ready
		}
	}
}`;

const EVENTS_REPORT_MUTATION = gql`
mutation EventsReport(
	$company: ID!
	$reference: String!
	$macAddress: String!
	$startDatetime: DateTime!
	$endDatetime: DateTime!
	$timezone: String!
) {
	generateInstallationEventsReport(
		input: {
			macAddress: $macAddress
			company: $company
			reference: $reference
			startDatetime: $startDatetime
			endDatetime: $endDatetime
			timezone: $timezone
		}
	) {
		reportPromise {
			id
			generationDate
			requestedBy
			reference
			reportType
			ready
		}
	}
}`;

const METEOROLOGICAL_REPORT_MUTATION = gql`
mutation meteorologicalReport(
	$reference: String!
	$company: ID!
	$macAddress: String!
	$startDate: DateTime!
	$endDate: DateTime!
	$timezone: String!
) {
	generateWeatherTelemetry(
		input: {
			reference: $reference
			company: $company
			deviceMac: $macAddress
			startDatetime: $startDate
			endDatetime: $endDate
			timezone: $timezone
		}
	) {
		reportPromise {
			id
			generationDate
			requestedBy
			reference
			reportType
			ready
		}
	}
}`;

const COMMAND_LIST_REPORT_MUTATION = gql`
mutation CommandList(
	$reference: String!
	$company: ID!
	$startDate: DateTime!
	$endDate: DateTime!
	$timezone: String!
) {
	generateCommandsList(
		input: {
			reference: $reference
			company: $company
			startDatetime: $startDate
			endDatetime: $endDate
			timezone: $timezone
		}
	) {
		reportPromise {
			id
			generationDate
			requestedBy
			reference
			reportType
			ready
		}
	}
}`;

const INSTALLATION_LIST_REPORT = gql`
mutation installationsListReport($reference: String!, $company: ID!) {
	generateInstallationsList(
		input: { reference: $reference, company: $company }
	) {
		reportPromise {
			id
			generationDate
			requestedBy
			reference
			reportType
			ready
		}
	}
}`;

const NO_TRANSMISSION_REPORT_MUTATION = gql`
mutation noTransmissionReport(
	$company: ID!
	$reference: String!
	$hours: Int!
	$timezone: String!
) {
	generateNoTransmissionDevices(
		input: { company: $company, reference: $reference, hours: $hours, timezone: $timezone }
	) {
		reportPromise {
			id
			generationDate
			requestedBy
			reference
			reportType
			ready
		}
	}
}`;

const FIRST_ON_FIRST_OFF_REPORT_MUTATION = gql`
mutation FOFO($reference: String!, $company: ID!, $targetDate: Date!, $timezone: String!) {
	generateFirstOnFirstOff(
		input: { reference: $reference, company: $company, targetDate: $targetDate, timezone: $timezone }
	) {
		reportPromisse {
			id
			generationDate
			requestedBy
			reference
			reportType
			ready
		}
	}
}`;

const POSSIBLE_STATUS_REPORT_MUTATION = gql`
mutation possibleStatus(
	$company: ID!
	$reference: String!
	$targetDate: Date!
	$timezone: String!
) {
	generatePossibleStatus(
		input: { company: $company, reference: $reference, targetDate: $targetDate, timezone: $timezone }
	) {
		reportPromisse {
			id
			generationDate
			requestedBy
			reference
			reportType
			ready
		}
	}
}`;

const GENERATE_GATEWAY_CONSUMPTION = gql`
mutation GenerateGatewayConsumption(
	$company: ID!
	$reference: String!
	$gateway: ID!
	$startDatetime: DateTime!
	$endDatetime: DateTime!
  $timezone: String!
) {
	generateGatewayConsumption(
		input: {
			company: $company
			reference: $reference
			gateway: $gateway
			startDatetime: $startDatetime
			endDatetime: $endDatetime
      timezone: $timezone
		}
	) {
		clientMutationId
		reportPromise {
			id
			generationDate
			requestedBy
			reference
			reportType
			ready
			auxInfo
		}
	}
}`;

const REPORT_DELETE = gql`
mutation ReportDelete($company: ID!, $report: [ID]!) {
	deleteDumpReport(input: { company: $company, dumpReports: $report }) {
		result
	}
}`;

export {
	// Queries
	GET_ALL_REPORTS,
	GET_REPORT_DOWNLOAD_URL,
	// Mutations
	LAST_TRANSMISSION_MUTATION,
	REGISTER_INSTALLATIONS_REPORT,
	LAST_DATA_MUTATION,
	GENERATE_ALERT_REPORT,
	INDIVIDUAL_CONSUMPTION_REPORT,
	CIRCUIT_BOX_CONSUMPTION_MUTATION,
	INDIVIDUAL_STATUS_MUTATION,
	EVENTS_REPORT_MUTATION,
	METEOROLOGICAL_REPORT_MUTATION,
	COMMAND_LIST_REPORT_MUTATION,
	INSTALLATION_LIST_REPORT,
	NO_TRANSMISSION_REPORT_MUTATION,
	FIRST_ON_FIRST_OFF_REPORT_MUTATION,
	POSSIBLE_STATUS_REPORT_MUTATION,
	GENERATE_GATEWAY_CONSUMPTION,
	REPORT_DELETE
};
