import { gql } from 'apollo-angular';

const GET_INDIVIDUAL_CONSUMPTION_CHART = gql`
query consumption(
	$startDatetime: DateTime!
	$endDatetime: DateTime!
	$installationId: ID!
) {
	individualConsumption(
		startDatetime: $startDatetime
		endDatetime: $endDatetime
		installationId: $installationId
	) {
		date
		total
		paMaxmin
		expectedEnergy
		metadata {
			datetime
			operatingVoltage
			currentConsumption
			activePower
			meterCurrentConsumption
			powerFactor
			accumulatedEnergy
			proportionalPower
			diff
		}
	}
}`;

export {
	GET_INDIVIDUAL_CONSUMPTION_CHART
};
