<div class="main-container" *ngIf="currentPage === '/map' || currentPage === '/'">
    <h2>{{ 'guide-map.title-label' | translate }}</h2>
    <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="center">
        <div class="map">

            <mat-tab label="{{'guide-map.title-label' | translate }}">
                <div class="container">
                    <div class="img">
                        <h2> {{'guide-map.theme-light' | translate}} </h2>
                        <img src="../../../assets/imgs-guide-map/map-light.png"
                            alt="{{'guide-map.theme-light' | translate}}" class="medium-img-h">
                        <br><br>
                        <h2> {{'guide-map.theme-dark' | translate}} </h2>
                        <img src="../../../assets/imgs-guide-map/map-dark.png"
                            alt="{{'guide-map.theme-dark' | translate}}" class="medium-img-h">
                    </div>
                    <div class="text">
                        <p>
                            {{ 'guide-map.map-text' | translate }}
                        </p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-map.mat-tab-label-buttons' | translate }}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-map/button-demonstration.png" alt="">
                    </div>
                    <div class="text">
                        <p>{{ 'guide-map.btn-demonstration-text' | translate }}</p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-map/button-theme-map.png"
                            alt="{{ 'guide-map.btn-map-theme-text' | translate }}">
                    </div>
                    <div class="text">
                        <p>{{ 'guide-map.btn-map-theme-text' | translate }}</p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-map/button-search.png"
                            alt="{{ 'guide-map.btn-filter-text' | translate }}">
                    </div>

                    <div class="text">
                        <p>{{ 'guide-map.btn-filter-text' | translate }}</p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-map/button-download-csv.png"
                            alt=" {{ 'guide-map.btn-download-text' | translate }}">
                    </div>

                    <div class="text">
                        <p> {{ 'guide-map.btn-download-text' | translate }} </p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-map.mat-tab-label-icons' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-map/gateway-verde.png"
                            alt="{{ 'guide-map.icon-gateway-green-text' | translate }}">
                    </div>
                    <div class="text">
                        <p>{{ 'guide-map.icon-gateway-green-text' | translate }}</p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-map/gateway-label.png"
                            alt="{{ 'guide-map.icon-gateway-label-text' | translate }}">
                    </div>
                    <div class="text">
                        <p> {{ 'guide-map.icon-gateway-label-text' | translate }}</p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-map/gateway-red-light-theme.png"
                            alt="{{ 'guide-map.icon-gateway-red-text' | translate }}">
                    </div>
                    <div class="text">
                        <p>{{ 'guide-map.icon-gateway-red-text' | translate }}</p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-map/folha-logo-amarelo.png"
                            alt=" {{ 'guide-map.icon-installation-yellow-text' | translate }}">
                    </div>
                    <div class="text">
                        <p> {{ 'guide-map.icon-installation-yellow-text' | translate }}</p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-map/folha-logo-cinza.png"
                            alt="{{ 'guide-map.icon-installation-gray-text' | translate }}">
                    </div>
                    <div class="text">
                        <p> {{ 'guide-map.icon-installation-gray-text' | translate }} </p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-map/folha-logo-azul.png"
                            alt="{{ 'guide-map.icon-installation-blue-text' | translate }}">
                    </div>
                    <div class="text">
                        <p> {{ 'guide-map.icon-installation-blue-text' | translate }} </p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-map/folha-logo-roxo.png"
                            alt="{{ 'guide-map.icon-installation-violet-text' | translate }}">
                    </div>
                    <div class="text">
                        <p> {{ 'guide-map.icon-installation-violet-text' | translate }} </p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-map/folha-logo-vermelho.png"
                            alt="{{ 'guide-map.icon-installation-red-text' | translate }}">
                    </div>
                    <div class="text">
                        <p> {{ 'guide-map.icon-installation-red-text' | translate }} </p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/mark-icons/circuit-box-black.png"
                            alt="{{'guide-map.icon-circuit-box-black-text' | translate }}">
                    </div>
                    <div class="text">
                        <p> {{'guide-map.icon-circuit-box-black-text' | translate }} </p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/mark-icons/circuit-box-blue.png"
                            alt="{{'guide-map.icon-circuit-box-blue-text' | translate }}">
                    </div>
                    <div class="text">
                        <p> {{'guide-map.icon-circuit-box-blue-text' | translate }} </p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/mark-icons/circuit-box-green.png"
                            alt="{{'guide-map.icon-circuit-box-black-text' | translate }}">
                    </div>
                    <div class="text">
                        <p> {{'guide-map.icon-circuit-box-green-text' | translate }} </p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/mark-icons/circuit-box-purple.png"
                            alt="{{'guide-map.icon-circuit-box-purple-text' | translate }}">
                    </div>
                    <div class="text">
                        <p> {{'guide-map.icon-circuit-box-purple-text' | translate }} </p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/mark-icons/circuit-box-red.png"
                            alt="{{'guide-map.icon-circuit-box-red-text' | translate }}">
                    </div>
                    <div class="text">
                        <p> {{'guide-map.icon-circuit-box-red-text' | translate }} </p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/mark-icons/SG-corte-blue.png"
                            alt="{{'guide-map.icon-corte-blue-text' | translate }}">
                    </div>
                    <div class="text">
                        <p> {{'guide-map.icon-corte-blue-text' | translate }} </p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/mark-icons/SG-corte-gray.png"
                            alt="{{'guide-map.icon-corte-gray-text' | translate }}">
                    </div>
                    <div class="text">
                        <p> {{'guide-map.icon-corte-gray-text' | translate }} </p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/mark-icons/SG-corte-green.png"
                            alt="{{'guide-map.icon-corte-green-text' | translate }}">
                    </div>
                    <div class="text">
                        <p> {{'guide-map.icon-corte-green-text' | translate }} </p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/mark-icons/SG-corte-purple.png"
                            alt="{{'guide-map.icon-corte-purple-text' | translate }}">
                    </div>
                    <div class="text">
                        <p> {{'guide-map.icon-corte-purple-text' | translate }} </p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/mark-icons/SG-corte-red.png"
                            alt="{{'guide-map.icon-corte-red-text' | translate }}">
                    </div>
                    <div class="text">
                        <p> {{'guide-map.icon-corte-red-text' | translate }} </p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/mark-icons/SG-corte-yellow.png"
                            alt="{{'guide-map.icon-corte-yellow-text' | translate }}">
                    </div>
                    <div class="text">
                        <p> {{'guide-map.icon-corte-yellow-text' | translate }} </p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/mark-icons/SG-IPC-blue.png"
                            alt="{{'guide-map.icon-ipc-blue-text' | translate }}">
                    </div>
                    <div class="text">
                        <p> {{'guide-map.icon-ipc-blue-text' | translate }} </p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/mark-icons/SG-IPC-gray.png"
                            alt="{{'guide-map.icon-ipc-gray-text' | translate }}">
                    </div>
                    <div class="text">
                        <p> {{'guide-map.icon-ipc-gray-text' | translate }} </p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/mark-icons/SG-IPC-green .png"
                            alt="{{'guide-map.icon-ipc-green-text' | translate }}">
                    </div>
                    <div class="text">
                        <p> {{'guide-map.icon-ipc-green-text' | translate }} </p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/mark-icons/SG-IPC-purple.png"
                            alt="{{'guide-map.icon-ipc-purple-text' | translate }}">
                    </div>
                    <div class="text">
                        <p> {{'guide-map.icon-ipc-purple-text' | translate }} </p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/mark-icons/SG-IPC-red.png"
                            alt="{{'guide-map.icon-ipc-red-text' | translate }}">
                    </div>
                    <div class="text">
                        <p> {{'guide-map.icon-ipc-red-text' | translate }} </p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/mark-icons/SG-IPC-yellow.png"
                            alt="{{'guide-map.icon-ipc-yellow-text' | translate }}">
                    </div>
                    <div class="text">
                        <p> {{'guide-map.icon-ipc-yellow-text' | translate }} </p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-map/info-circuit-box.png"
                            alt="{{'guide-map.information-circuit-box-text' | translate }}">
                    </div>
                    <div class="text">
                        <p> {{'guide-map.information-circuit-box-text' | translate }} </p>
                    </div>
                </div>

            </mat-tab>

            <mat-tab label="{{'guide-map.mat-tab-label-filters' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-map/sidebar.png"
                            alt="{{ 'guide-map.filter-filds-text' | translate }}" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{ 'guide-map.filter-filds-text' | translate }} </p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-map/sidebar-polygon.png"
                            alt="{{'guide-map.filter-area-text' | translate }}" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-map.filter-area-text' | translate }} </p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-map/polygon.png" alt="" class="medium-img-v">
                    </div>

                    <div class="text">
                        <p> {{ 'guide-map.polygon-text' | translate }} </p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-map/search-favorites.png"
                            alt=" {{'guide-map.filter-favorites-text' | translate }}" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-map.filter-favorites-text' | translate }} </p>
                    </div>
                </div>

                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-map/info-gateways.png"
                            alt="{{ 'guide-map.info-gateway-text' | translate }}" class="medium-img">
                    </div>
                    <div class="text">
                        <p> {{ 'guide-map.info-gateway-text' | translate }} </p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-map/info-equipments.png"
                            alt="{{'guide-map.info-installation-text' | translate }}" class="medium-img">
                    </div>
                    <div class="text">
                        <p> {{'guide-map.info-installation-text' | translate }} </p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-map/info-equipments-details.png"
                            alt=" {{'guide-map.details-text' | translate }}" class="medium-img-v">
                    </div>

                    <div class="text">
                        <p> {{'guide-map.details-text' | translate }} </p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-map/info-equipments-alerts.png"
                            alt=" {{'guide-map.alerts-text' | translate }}" class="medium-img-v">
                    </div>

                    <div class="text">
                        <p> {{'guide-map.alerts-text' | translate }} </p>
                    </div>
                </div>

            </mat-tab>
            <mat-tab label="{{'guide-map.mat-tab-label-wheel' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-map/wheel.png"
                            alt="{{'guide-map.wheel-text' | translate }}" class="medium-img">
                    </div>

                    <div class="text">
                        <p> {{'guide-map.wheel-text' | translate }} </p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-map/wheel-commands.png"
                            alt="{{'guide-map.wheel-commands-text' | translate }}" class="medium-img">
                    </div>
                    <div class="text">
                        <p> {{'guide-map.wheel-commands-text' | translate }} </p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-map/wheel-reports.png"
                            alt="{{'guide-map.wheel-reports-text' | translate }}" class="medium-img">
                    </div>
                    <div class="text">
                        <p> {{'guide-map.wheel-reports-text' | translate }} </p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-map/wheel-fav.png"
                            alt="{{'guide-map.wheel-selecion' | translate }}" class="medium-img">
                    </div>
                    <div class="text">
                        <p> {{'guide-map.wheel-selecion' | translate }} </p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-map/wheel-logs.png"
                            alt="{{'guide-map.wheel-logs-text' | translate }}" class="medium-img">
                    </div>
                    <div class="text">
                        <p> {{'guide-map.wheel-logs-text' | translate }} </p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-map/favorites.png"
                            alt="{{'guide-map.favorites-text' | translate }}" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-map.favorites-text' | translate }} </p>
                    </div>
                </div>
            </mat-tab>
        </div>
    </mat-tab-group>
    <div class="main-container" *ngIf="currentPage !== '/map' && currentPage !== '/'">
        <h3>{{ 'guide-map.no-guide-label' | translate }}</h3>
    </div>
</div>

<div class="main-container" *ngIf="currentPage === '/report' || currentPage === '/'">
    <h2>{{'guide-reports.title-label' | translate }}</h2>
    <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="center">
        <div class="report">

            <mat-tab label="{{'guide-reports.home-page' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-report/home-page.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-reports.home-page-text' | translate}}
                        </p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-reports.generate-reports' | translate }}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-report/generate-reports.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-reports.generate-reports-text' | translate}}
                            <b>{{'guide-reports.generate-reports-reference' | translate}}</b>
                            {{'guide-reports.generate-reports-and' | translate}}
                            <b>{{'guide-reports.generate-reports-report-type' | translate}}</b>
                            {{'guide-reports.generate-reports-report-type-text' | translate}}
                            <b>{{'guide-reports.generate-reports-all-report-type' | translate}}</b>
                            {{'guide-reports.generate-reports-and' | translate}}
                            <b> {{'guide-reports.generate-reports-latest-data' | translate}} </b>
                        </p>
                    </div>
                </div>

                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-report/Generate-reports-alerts.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-reports.generate-reports-text-all' | translate}}
                            <b>{{'guide-reports.generate-reports-alerts-text-bold' | translate}}</b>
                            {{'guide-reports.generate-reports-alerts' | translate}}
                        </p>
                    </div>
                </div>

                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-report/Generate-reports-consumption.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-reports.generate-reports-text-all' | translate}}
                            <b>{{'guide-reports.generate-reports-consumption-text-bold' |
                                translate}}</b>{{'guide-reports.generate-reports-consumption' | translate}}
                            <b>{{'guide-reports.generate-reports-detailed-consumption-text-bold' | translate}}</b> ou
                            <b>{{'guide-reports.generate-reports-total-consumption-text-bold' | translate}}</b>
                        </p>
                    </div>
                </div>

                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-report/Generate-reports-consumption-per-gateway.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-reports.generate-reports-text-all' | translate}}
                            <b>{{'guide-reports.generate-reports-consumption-per-gateway-text-bold' |
                                translate}}</b>{{'guide-reports.generate-reports-consumption-per-gateway-text' |
                            translate}}
                        </p>
                    </div>
                </div>

                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-report/Generate-reports-events.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-reports.generate-reports-text-all' | translate}}
                            <b>{{'guide-reports.generate-reports-events-text-bold' |
                                translate}}</b>{{'guide-reports.generate-reports-events-text' | translate}}
                        </p>
                    </div>
                </div>

                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-report/Generate-reports-status.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-reports.generate-reports-text-all' | translate}}
                            <b>{{'guide-reports.generate-reports-status-text-bold' |
                                translate}}</b>{{'guide-reports.generate-reports-status-text' | translate}}
                        </p>
                    </div>
                </div>

                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-report/Generate-reports-last -transmissions.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-reports.generate-reports-text-all' | translate}}
                            <b>{{'guide-reports.generate-reports-last-transmissions-text-bold' |
                                translate}}</b>{{'guide-reports.generate-reports-last-transmissions-text' | translate}}
                        </p>
                    </div>
                </div>

                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-report/Generate-reports-weather-report.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-reports.generate-reports-weather-text' | translate}}<b>
                                {{'guide-reports.generate-reports-weather-report-text-bold' |
                                translate}}</b>{{'guide-reports.generate-reports-weather-report-text'| translate}}
                        </p>
                    </div>
                </div>

                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-report/Generate-reports-command-report.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-reports.generate-reports-text-all' | translate}} <b>
                                {{'guide-reports.generate-reports-commands-text-bold' |
                                translate}}</b>{{'guide-reports.generate-reports-commands-text' | translate}}</p>
                    </div>
                </div>

                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-report/Generate-reports-installation-list.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-reports.generate-reports-text-all' | translate}}
                            <b>{{'guide-reports.generate-reports-installations-list-text-bold' |
                                translate}}</b>{{'guide-reports.generate-reports-installations-list-text' | translate}}
                        </p>
                    </div>
                </div>

                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-report/Generate-reports-no-transmission.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-reports.generate-reports-text-all' | translate}}
                            <b>{{'guide-reports.generate-reports-no-transmission-list-text-bold' |
                                translate}}</b>{{'guide-reports.generate-reports-no-transmission-list-text' |
                            translate}}
                        </p>
                    </div>
                </div>

                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-report/Generate-reports-installation-record.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-reports.generate-reports-text-all' | translate}}
                            <b>{{'guide-reports.generate-reports-installation-record-text-bold' |
                                translate}}</b>{{'guide-reports.generate-reports-installation-record-text' | translate}}
                        </p>
                    </div>
                </div>

                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-report/Generate-reports-latest-data.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-reports.generate-reports-text-all' | translate}}
                            <b>{{'guide-reports.generate-reports-latest-data-text-bold' |
                                translate}}</b>{{'guide-reports.generate-reports-latest-data-text' | translate}}
                        </p>
                    </div>
                </div>

            </mat-tab>
            <mat-tab label="{{'guide-reports.filter-reports' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-report/filter-reports.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-reports.filter-reports-text' | translate}}
                            <b>{{'guide-reports.generate-reports-reference' | translate}}</b>,
                            <b>{{'guide-reports.filter-reports-report-type' |translate}}</b>
                            {{'guide-reports.generate-reports-and' | translate}}
                            <b>{{'guide-reports.filter-reports-users' |translate}}</b>
                        </p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-reports.reports-table' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-report/table-reports.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-reports.reports-table-text' |translate}}
                        </p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-report/table-header.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-reports.reports-table-header-text' | translate}}
                        </p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-report/icon-download-reports.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-reports.reports-table-icon-download' | translate}}</p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-report/icon-delete-reports.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-reports.reports-table-icon-delete' | translate}}</p>
                    </div>
                </div>
                <hr>
            </mat-tab>
        </div>
    </mat-tab-group>
</div>

<div class="main-container" *ngIf="currentPage === '/manageuser' || currentPage === '/'">
    <h2> {{'guide-users.title-label' | translate}} </h2>
    <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="center">
        <div class="users">
            <mat-tab label="{{'guide-users.home-page' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-users/home-page-users.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-users.home-page-text' | translate}}
                        </p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-users.create-users-title' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-users/create-users-box.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-users.create-users-text' | translate}}
                            <b>{{'guide-users.create-users-text-bold' | translate}}</b>
                            {{'guide-users.create-users-text-and' | translate}}
                            <b>{{'guide-users.create-users-password-bold' | translate}}</b>.
                            {{'guide-users.create-users-permissions' | translate}}
                        </p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-users.search-users-title' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-users/search-users-box.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-users.search-users-text' | translate}}
                        </p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-users.users-table-title' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-users/users-table.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-users.users-table-text' | translate}}
                            <b> {{'guide-users.users-table-bold' | translate}} </b> {{'guide-users.users-table-text-and'
                            |
                            translate}} <b>{{'guide-users.users-table-user-data' | translate}}</b>
                            {{'guide-users.users-table-buttons-text' | translate}}
                        </p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-users/users-table-header.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-users.users-table-header-text' | translate}}
                        </p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-users/users-table-buttons.png" alt="">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-users.users-table-buttons' | translate}}
                        </p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-users.user-update-title' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-users/users-update-box.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-users.user-update-box-text' | translate}}
                            <b>{{'guide-users.user-update-inputs-bold' | translate}}</b>
                            {{'guide-users.user-update-and' | translate}}
                            <b>{{'guide-users.user-update-input-email-bold' |
                                translate}}</b>{{'guide-users.user-update-inputs-text' | translate}}
                        </p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-users/users-view-box.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-users.user-view-box-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>
        </div>
    </mat-tab-group>
</div>

<div class="main-container" *ngIf="currentPage === '/commands' || currentPage === '/'">
    <h2> {{'guide-commands.title-label' | translate}} </h2>
    <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="center">
        <div class="report">

            <mat-tab label="{{'guide-commands.home-page' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-commands/home-page-commands.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-commands.home-page-text' | translate}}
                        </p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-commands.filter-installations' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-commands/filter-installations.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-commands.filter-installation-text' | translate}}
                        </p>
                    </div>
                </div>

                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-commands/commands-installations.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-commands.installations-selected' | translate}}
                        </p>
                    </div>
                </div>

                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-commands/commands-scheduling-and-search.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-commands.all-boxs-commands' | translate}}
                            <b>{{'guide-commands.search-commands' | translate}}</b> e
                            <b>{{'guide-commands.scheduling' | translate}}</b>
                        </p>
                    </div>
                </div>

                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-commands/commands-search.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-commands.search-commands-text' | translate}}
                            <b>
                                {{'guide-commands.search' | translate}}, {{'guide-commands.turn-on-off' | translate}}
                            </b>
                            {{'guide-commands.filter-commands-text-and' | translate}}
                            <b>{{'guide-commands.set-up' | translate}}</b>
                        </p>
                    </div>
                </div>

                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-commands/commands-search-options.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-commands.command-search-options-text' | translate}}
                            <b> {{'guide-commands.command-seacrh-options-bold' | translate}} </b>
                        </p>
                    </div>
                </div>

                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-commands/commands-turn-on-off.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-commands.command-turn-on-off-text' | translate}}
                        </p>
                    </div>
                </div>

                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-commands/commands-set-up.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-commands.command-set-up-text' | translate}}
                        </p>
                    </div>
                </div>

                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-commands/commands-scheduling.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-commands.scheduling-commands-text' | translate}}
                        </p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-commands.filter-commands-title' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-commands/filter-commands.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-commands.filter-commands-text' | translate}}
                            <b>{{'guide-commands.filter-commands-text-bold' | translate}} </b>
                            {{'guide-commands.filter-commands-text-and' | translate}}
                            <b> {{'guide-commands.filter-commands-text-config' | translate}}</b>
                        </p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-commands.table-commands-title' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-commands/commands-table.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-commands.table-commands-text' | translate}}
                        </p>
                    </div>
                </div>
                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-commands/icon-view-equipments.png" alt="">
                    </div>
                    <div class="text">
                        <p> {{'guide-commands.icon-view-equipments-text' | translate}} </p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-commands/commands-info-equipments.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-commands.button-info-equipments-text' | translate}}
                        </p>
                    </div>
                </div>
                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-commands/commands-info-commands.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-commands.info-commands-text' | translate}}
                        </p>
                    </div>
                </div>

                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-commands/commands-table-header.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-commands.table-header-text' | translate}}

                        </p>
                    </div>
                </div>
                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-commands/commands-downloads.png" alt="">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-commands.button-downloads' | translate}}
                        </p>
                    </div>
                </div>
            </mat-tab>
        </div>
    </mat-tab-group>
</div>

<div class="main-container" *ngIf="currentPage === '/logs' || currentPage === '/'">
    <h2> {{'guide-logs.title-label' | translate}} </h2>
    <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="center">
        <div class="logs">
            <mat-tab label="{{'guide-commands.home-page' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-logs/home-page-logs.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-logs.home-page-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-logs.mat-label-logs-type' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-logs/logs-options.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-logs.logs-options-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>
        </div>
    </mat-tab-group>
</div>

<div class="main-container" *ngIf="currentPage === '/logs/installation' || currentPage === '/'">
    <h2> {{'guide-logs.title-label-installation'| translate }} </h2>
    <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="center">
        <div class="logs-installation">
            <mat-tab label="{{'guide-logs.mat-label-logs-installation' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-logs/logs-installation-filter.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-logs.filter-logs-installations-text' | translate}}</p>
                    </div>
                </div>
                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-logs/logs-operation-installation.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-logs.logs-operations-installations-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-logs.mat-label-table-logs-installation' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-logs/table-installations-log.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-logs.table-installations-logs-text' | translate}}
                            <b>
                                {{'guide-logs.table-installations-logs-text-bold' | translate}}
                            </b>
                            {{'guide-logs.table-installations-logs-text-and' | translate}}
                            <b>
                                {{'guide-logs.table-installations-logs-text-division' | translate}}
                            </b>
                        </p>
                    </div>
                </div>
                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-logs/logs-installation-table-header.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-logs.table-header-logs' | translate}}</p>
                    </div>
                </div>
                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-logs/logs-downloads.png" alt="">
                    </div>
                    <div class="text">
                        <p>{{'guide-logs.button-downloads' | translate}}</p>
                    </div>
                </div>
            </mat-tab>
        </div>
    </mat-tab-group>
</div>

<div class="main-container" *ngIf="currentPage === '/logs/installation_site' || currentPage === '/'">
    <h2> {{'guide-logs.title-label-installation-site' | translate}} </h2>
    <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="center">
        <div class="logs-installation">
            <mat-tab label="{{'guide-logs.mat-label-logs-installation-site' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-logs/logs-installation-filter.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-logs.filter-logs-installations-site-text' | translate}}</p>
                    </div>
                </div>
                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-logs/logs-operation-installation.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-logs.logs-operations-installations-site-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-logs.mat-label-table-logs-installation-site' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-logs/logs-table-installation-site.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-logs.table-installations-site-logs-text' | translate}}
                            <b>
                                {{'guide-logs.table-installations-site-logs-text-bold' | translate}}
                            </b>
                            {{'guide-logs.table-installations-site-logs-text-and' | translate}}
                            <b>
                                {{'guide-logs.table-installations-site-logs-text-active' | translate}}
                            </b>
                        </p>
                    </div>
                </div>
                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-logs/logs-installation-site-table-header.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-logs.table-header-logs' | translate}}</p>
                    </div>
                </div>
                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-logs/logs-downloads.png" alt="">
                    </div>
                    <div class="text">
                        <p>{{'guide-logs.button-downloads' | translate}}</p>
                    </div>
                </div>
            </mat-tab>
        </div>
    </mat-tab-group>
</div>

<div class="main-container" *ngIf="currentPage === '/logs/equipment' || currentPage === '/'">
    <h2> {{'guide-logs.title-label-equipments' | translate}} </h2>
    <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="center">
        <div class="logs-equipments">

            <mat-tab label="Logs (Equipamentos)">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-logs/logs-equipments-filter.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-logs.filter-logs-equipments-text' | translate}}</p>
                    </div>
                </div>
                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-logs/logs-operations-equipments.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-logs.logs-operations-equipments-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-logs.mat-label-table-logs-equipments' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-logs/equipment-log-table.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-logs.table-equipments-logs-text' | translate}}
                            <b>
                                {{'guide-logs.table-equipments-logs-text-bold' | translate}}
                            </b>
                            {{'guide-logs.table-equipments-logs-text-and' | translate}}
                            <b>
                                {{'guide-logs.table-equipments-logs-text-bold-macAddress' | translate}}
                            </b>
                        </p>
                    </div>
                </div>
                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-logs/logs-equipment-table-header.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-logs.table-header-logs' | translate}}</p>
                    </div>
                </div>
                <hr>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-logs/logs-downloads.png" alt="">
                    </div>
                    <div class="text">
                        <p>{{'guide-logs.button-downloads' | translate}}</p>
                    </div>
                </div>
            </mat-tab>
        </div>
    </mat-tab-group>
</div>

<div class="main-container" *ngIf="currentPage === '/serviceorder' || currentPage === '/'">
    <h2> {{'guide-service-order.title-label' | translate}} </h2>
    <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="center">
        <div class="service-order">

            <mat-tab label="{{'guide-service-order.home-page' | translate}}">
                <div class="container">
                    <div class="img">
                        <h2> {{'guide-service-order.home-page' | translate}} </h2>
                        <img src="../../../assets/imgs-guide-service-order/home-page-service-order.png"
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-service-order.home-page-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-service-order.title-label-service-order' | translate}}">
                <div class="container">
                    <div class="img">
                        <h2> {{'guide-service-order.title-label-service-order' | translate}} </h2>
                        <img src="../../../assets/imgs-guide-service-order/create-service-order.png"
                            class="medium-img-v">

                    </div>
                    <div class="text">
                        <p> {{'guide-service-order.create-service-order-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-service-order.mat-label-filter-service-order' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-service-order/filter-service-order.png"
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-service-order.filter-service-order-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-service-order.title-label-table-service-order' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-service-order/table-service-order.png"
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-service-order.table-service-order-text' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-service-order/table-header-service-order.png"
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-service-order.table-header-service-order' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-service-order/downloads-service-order.png">
                    </div>
                    <div class="text">
                        <p> {{'guide-service-order.button-downloads' | translate}}
                        </p>
                    </div>
                </div>

                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-service-order/buttons-edit-and-view-service-order.png">
                    </div>
                    <div class="text">
                        <p> {{'guide-service-order.buttons-table-service-order' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-service-order.mat-label-edit-service-order' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-service-order/sidebar-edit-service-order.png"
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-service-order.sidebar-edit-text' | translate}}</p>
                    </div>
                </div>

                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-service-order/sidebar-view-service-order.png"
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-service-order.sidebar-view-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>
        </div>
    </mat-tab-group>
</div>

<div class="main-container" *ngIf="currentPage === '/register/companies' || currentPage === '/'">
    <h2> {{'guide-companies.title-label' | translate}} </h2>
    <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="center">
        <div class="companies">
            <mat-tab label="{{'guide-companies.home-page' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-company/home-page-company.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-companies.home-page-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-companies.mat-label-create-companies' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-company/register-company.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-companies.create-companies-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-companies.mat-label-filter-companies' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-company/filter-company.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-companies.filter-companies-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-companies.mat-label-table-companies' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-company/table-company.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-companies.table-companies-text' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-company/table-header-company.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-companies.table-header-companies' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-company/companies-downloads.png" alt="">
                    </div>
                    <div class="text">
                        <p>{{'guide-companies.button-downloads-text' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-company/button-edit.png" alt="">
                    </div>
                    <div class="text">
                        <p>{{'guide-companies.button-edit-company-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-companies.mat-label-edit-companies' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-company/edit-company.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-companies.sidebar-edit-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>
        </div>
    </mat-tab-group>
</div>

<div class="main-container" *ngIf="currentPage === '/register/unit-measurement' || currentPage === '/'">
    <h2> {{'guide-measurement-unit.title-label' | translate}} </h2>
    <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="center">
        <div class="UnitMeasurement">
            <mat-tab label="{{'guide-measurement-unit.home-page' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-measurement-unit/home-page-measurement-unit.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-measurement-unit.home-page-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-measurement-unit.mat-label-create-measurement-unit' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-measurement-unit/register-measurement-unit.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-measurement-unit.create-measurement-unit-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-measurement-unit.mat-label-filter-measurement-unit' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-measurement-unit/filter-measurement-unit.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-measurement-unit.filter-measurement-unit-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-measurement-unit.mat-label-table-measurement-unit' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-measurement-unit/table-measurement-unit.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-measurement-unit.table-measurement-unit-text' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-measurement-unit/table-header-measurement-unit.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-measurement-unit.table-header-measurement-unit' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-company/companies-downloads.png" alt="">
                    </div>
                    <div class="text">
                        <p>{{'guide-measurement-unit.button-downloads-text' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-measurement-unit/button-edit-measurement-unit.png" alt="">
                    </div>
                    <div class="text">
                        <p>{{'guide-measurement-unit.button-edit-measurement-unit-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-measurement-unit.mat-label-edit-measurement-unit' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-measurement-unit/edit-measurement-unit.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-measurement-unit.sidebar-edit-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>
        </div>
    </mat-tab-group>
</div>

<div class="main-container" *ngIf="currentPage === '/alertrules' || currentPage === '/'">
    <h2> {{'guide-alert-rules.title-label' | translate}} </h2>
    <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="center">
        <div class="alert-rules">
            <mat-tab label="{{'guide-alert-rules.home-page' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/img-guide-alert/alert-rules/screen_alert_rules.png" alt=""
                            class="medium-img-v medium-img-h">
                    </div>
                    <div class="text">
                        <p>{{'guide-alert-rules.home-page-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-alert-rules.create-alert-rules' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/img-guide-alert/alert-rules/create_rules.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-alert-rules.create-alert-rules-text' | translate}}</p>
                    </div>

                </div>

                <div class="container">
                    <div class="img">
                        <img src="../../../assets/img-guide-alert/alert-rules/create_rules_data.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-alert-rules.create-alert-rules-text-data' | translate}}</p>
                    </div>
                </div>

                <div class="container">
                    <div class="img">
                        <img src="../../../assets/img-guide-alert/alert-rules/create_rules_param_rules.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-alert-rules.create-alert-rules-text-rules' | translate}}</p>
                    </div>
                </div>

                <div class="container">
                    <div class="img">
                        <img src="../../../assets/img-guide-alert/alert-rules/create_rules_days.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-alert-rules.create-alert-rules-text-days' | translate}}</p>
                    </div>
                </div>

                <div class="container">
                    <div class="img">
                        <img src="../../../assets/img-guide-alert/alert-rules/create_rules_installations.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-alert-rules.create-alert-rules-text-installations' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-alert-rules.filter-alert-rules'  | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/img-guide-alert/alert-rules/filter_rules.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-alert-rules.filter-alert-rules-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-alert-rules.list-alert-rules' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/img-guide-alert/alert-rules/list_rules_alert.png" alt=""
                            class="medium-img-v medium-img-h ">
                    </div>
                    <div class="text">
                        <p> {{'guide-alert-rules.list-alert-rules-text' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/img-guide-alert/alert-rules/actions_rule_alert.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-alert-rules.actions-alert-rules-text' | translate}}
                            <b>{{'guide-alert-rules.actions-alert-rules-types-bold' | translate}} </b>
                        </p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/img-guide-alert/alert-rules/edit-geral-data-alert-rules.png" alt=""
                            class="medium-img-v medium-img-h">
                    </div>
                    <div class="text">
                        <p> <b>{{'guide-alert-rules.actions-alert-rules-types-edit' | translate}} </b>
                            {{'guide-alert-rules.edit-alert-rules-text' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/img-guide-alert/alert-rules/view-alert-rule.png" alt=""
                            class="medium-img-v  medium-img-h">
                    </div>
                    <div class="text">
                        <p> <b>{{'guide-alert-rules.actions-alert-rules-types-view' | translate}} </b>
                            {{'guide-alert-rules.view-geral-alert-rules-text' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/img-guide-alert/alert-rules/copy-alert-rule.png" alt=""
                            class="medium-img-v  medium-img-h">
                    </div>
                    <div class="text">
                        <p> <b>{{'guide-alert-rules.actions-alert-rules-types-copy' | translate}} </b>
                            {{'guide-alert-rules.copy-alert-rule' | translate}}</p>
                    </div>
                </div>
            </mat-tab>
        </div>
    </mat-tab-group>
</div>


<div class="main-container" *ngIf="currentPage === '/alerts' || currentPage === '/'">
    <h2> {{'guide-record-alert.title-label' | translate}} </h2>
    <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="center">
        <div class="alerts">
            <mat-tab label="{{'guide-record-alert.home-page' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/img-guide-alert/record-alert/view-record-alert.png" alt=""
                            class="medium-img-v medium-img-h">
                    </div>
                    <div class="text">
                        <p>{{'guide-record-alert.home-page-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-record-alert.filter-record-alert' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/img-guide-alert/record-alert/filter-alert.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-record-alert.filter-record-alert-text' | translate}}</p>
                    </div>

                </div>
            </mat-tab>

            <mat-tab label="{{'guide-record-alert.list-record'  | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/img-guide-alert/record-alert/list-record-alert.png" alt=""
                            class="medium-img-v medium-img-h">
                    </div>
                    <div class="text">
                        <p>{{'guide-record-alert.list-record-alert-text' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/img-guide-alert/record-alert/export-record-alert.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-record-alert.button-download' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/img-guide-alert/record-alert/actions-record-alert.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-record-alert.button-action-record-alert' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-record-alert.manage-alert' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/img-guide-alert/record-alert/manage_alert.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-record-alert.manage-alert-text' | translate}}
                            <b>{{'guide-record-alert.manage-alert-bold-sub-section' |
                                translate}} </b>
                        </p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/img-guide-alert/record-alert/manage-alert-info.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-record-alert.manage-alert-basic-info' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/img-guide-alert/record-alert/manage-alert-event.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-record-alert.manage-alert-events' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/img-guide-alert/record-alert/manage-alert-create-os.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-record-alert.manage-alert-create-os' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/img-guide-alert/record-alert/manage-alert-os-exist .png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-record-alert.manage-alert-os-exist' | translate}}</p>
                    </div>
                </div>
            </mat-tab>
        </div>
    </mat-tab-group>
</div>

<div class="main-container" *ngIf="currentPage === '/register/properties' || currentPage === '/'">
    <h2> {{'guide-properties.title-label' | translate}} </h2>
    <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="center">
        <div class="Propriedades">
            <mat-tab label="{{'guide-properties.home-page' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-properties/home-page-properties.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-properties.home-page-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-properties.mat-label-create-properties' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-properties/create-properties.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-properties.create-properties-text' | translate}}
                            <b>{{'guide-properties.create-properties-text-bold' | translate}}</b>
                            {{'guide-properties.create-properties-text-and' | translate}}
                            <b>{{'guide-properties.create-properties-text-service-bold' | translate}}</b>
                            {{'guide-properties.create-properties-text-measurement-unit' | translate}}
                        </p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-properties.mat-label-filter-properties' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-properties/filter-properties.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-properties.filter-properties-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-properties.mat-label-table-properties' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-properties/table-properties.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-properties.table-properties-text' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-properties/table-header-properties.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-properties.table-header-properties' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-properties/properties-downloads.png" alt="">
                    </div>
                    <div class="text">
                        <p>{{'guide-properties.button-downloads-text' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-properties/buttons-edit-and-view-properties.png" alt="">
                    </div>
                    <div class="text">
                        <p>{{'guide-properties.buttons-table-properties' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-properties.mat-label-edit-properties' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-properties/edit-properties.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-properties.sidebar-edit-text' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-properties/view-properties.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-properties.sidebar-view-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>
        </div>
    </mat-tab-group>
</div>

<div class="main-container" *ngIf="currentPage === '/register/hardwares' || currentPage === '/'">
    <h2> {{'guide-hardwares.title-label' | translate}} </h2>
    <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="center">
        <div class="Hardwares">
            <mat-tab label="{{'guide-hardwares.home-page' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-hardwares/home-page-hardwares.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-hardwares.home-page-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-hardwares.mat-label-create-hardwares' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-hardwares/register-hardwares.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-hardwares.create-hardwares-text' | translate}}
                            <b>{{'guide-hardwares.create-hardwares-text-bold' | translate}}</b>
                            {{'guide-hardwares.create-hardwares-text-and' | translate}}
                            {{'guide-hardwares.create-hardwares-compatible-firmware-text' | translate}}
                        </p>
                    </div>
                </div>

                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-hardwares/new-firmware.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-hardwares.create-new-firmware' | translate}}
                        </p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-hardwares.mat-label-filter-hardwares' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-hardwares/filter-hardwares.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-hardwares.filter-hardwares-text' | translate}}
                            <b>{{'guide-hardwares.filter-hardwares-text-bold' | translate}}</b>
                            {{'guide-hardwares.filter-hardwares-text-or' | translate}}
                            <b>{{'guide-hardwares.filter-hardwares-text-revision' | translate}}</b>
                        </p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-hardwares.mat-label-table-hardwares' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-hardwares/table-hardware.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-hardwares.table-hardwares-text' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-hardwares/table-header-hardware.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-hardwares.table-header-hardwares' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-hardwares/downloads-hardwares.png" alt="">
                    </div>
                    <div class="text">
                        <p>{{'guide-hardwares.button-downloads-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>
        </div>
    </mat-tab-group>
</div>

<div class="main-container" *ngIf="currentPage === '/register/firmwares' || currentPage === '/'">
    <h2> {{'guide-firmwares.title-label' | translate}} </h2>
    <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="center">
        <div class="Firmwares">
            <mat-tab label="{{'guide-firmwares.home-page' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-firmwares/home-page-firmwares.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-firmwares.home-page-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-firmwares.mat-label-create-firmwares' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-firmwares/register-firmwares.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-firmwares.create-firmwares-text' | translate}}
                            <b>{{'guide-firmwares.create-firmwares-text-bold' | translate}}</b>
                            {{'guide-firmwares.create-firmwares-text-and' | translate}}
                            {{'guide-firmwares.create-firmware-compatible-hardware-text' | translate}}
                        </p>
                    </div>
                </div>

                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-firmwares/new-hardware.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-firmwares.create-new-hardware' | translate}}
                        </p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-firmwares.mat-label-filter-firmwares' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-firmwares/filter-firmwares.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-firmwares.filter-firmwares-text' | translate}}
                            <b>{{'guide-firmwares.filter-firmwares-text-bold' | translate}}</b>
                            {{'guide-firmwares.filter-firmwares-text-or' | translate}}
                            <b>{{'guide-firmwares.filter-firmwares-text-revision' | translate}}</b>
                        </p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-firmwares.mat-label-table-firmwares' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-firmwares/table-firmware.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-firmwares.table-firmwares-text' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-firmwares/table-header-firmware.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-firmwares.table-header-firmwares' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-firmwares/downloads-firmwares.png" alt="">
                    </div>
                    <div class="text">
                        <p>{{'guide-firmwares.button-downloads-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>
        </div>
    </mat-tab-group>
</div>

<div class="main-container" *ngIf="currentPage === '/register/parameters' || currentPage === '/'">
    <h2> {{'guide-parameters.title-label' | translate}} </h2>
    <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="center">
        <div class="Parâmetros">
            <mat-tab label="{{'guide-parameters.home-page' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-parameters/home-page-parameters.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-parameters.home-page-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-parameters.mat-label-create-parameters' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-parameters/register-parameters.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-parameters.create-parameters-text' | translate}}
                            <b>{{'guide-parameters.create-parameters-reference-text-bold' | translate}}</b>
                            {{'guide-parameters.create-parameters-text-and' | translate}}
                            <b>{{'guide-parameters.create-parameters-parameter-text-bold' | translate}}</b>
                        </p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-parameters.mat-label-filter-parameters' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-parameters/filter-parameters.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-parameters.filter-parameters-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-parameters.mat-label-table-parameters' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-parameters/table-parameters.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-parameters.table-parameters-text' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-parameters/table-header-parameters.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-parameters.table-header-parameters' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-parameters/downloads-parameters.png" alt="">
                    </div>
                    <div class="text">
                        <p>{{'guide-parameters.button-downloads-text' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-parameters/buttons-table-parameters.png" alt="">
                    </div>
                    <div class="text">
                        <p>{{'guide-parameters.buttons-table-parameters' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-parameters.mat-label-edit-parameters' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-parameters/edit-parameters.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-parameters.sidebar-edit-text' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-parameters/view-parameters.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-parameters.sidebar-view-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>
        </div>
    </mat-tab-group>
</div>

<div class="main-container" *ngIf="currentPage === '/register/equipments' || currentPage === '/'">
    <h2> {{'guide-equipments.title-label' | translate}} </h2>
    <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="center">
        <div class="Equipamentos">
            <mat-tab label="{{'guide-equipments.home-page' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-equipments/home-page-equipments.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-equipments.home-page-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-equipments.mat-label-create-equipments' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-equipments/register-equipments.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-equipments.create-equipments-text' | translate}}
                            <b>{{'guide-equipments.create-equipments-text-bold' | translate}}</b>
                            {{'guide-equipments.create-equipments-text-and' | translate}}
                            <b>{{'guide-equipments.create-equipments-equipment-type-bold' | translate}}.</b>
                        </p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-equipments.mat-label-filter-equipments' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-equipments/filter-equipments.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-equipments.filter-equipments-text' | translate}}
                            <b>{{'guide-equipments.filter-equipments-text-bold' | translate}}</b>
                            {{'guide-equipments.filter-equipments-text-or' | translate}}
                            <b>{{'guide-equipments.filter-equipments-equipment-type-bold' | translate}}</b>
                        </p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-equipments.mat-label-table-equipments' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-equipments/table-equipments.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-equipments.table-equipments-text' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-equipments/table-header-equipments.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-equipments.table-header-equipments' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-equipments/downloads-equipments.png" alt="">
                    </div>
                    <div class="text">
                        <p>{{'guide-equipments.button-downloads-text' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-equipments/button-edit-equipment.png" alt="">
                    </div>
                    <div class="text">
                        <p>{{'guide-equipments.button-edit-equipments' | translate}}</p>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="{{'guide-equipments.mat-label-edit-equipments' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-equipments/edit-equipments.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-equipments.sidebar-edit-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>
        </div>
    </mat-tab-group>
</div>

<div class="main-container" *ngIf="currentPage === '/register/components' || currentPage === '/'">
    <h2> {{'guide-components.title-label' | translate}} </h2>
    <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="center">
        <div class="components">

            <mat-tab label="{{'guide-components.home-page' | translate}}">
                <div class="container">
                    <div class="img">
                        <h2> {{'guide-components.home-page' | translate}} </h2>
                        <img src="../../../assets/imgs-guide-components/home-page-components.png" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-components.home-page-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="{{'guide-components.mat-label-create-components' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-components/register-components.png" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-components.create-components-text' | translate}}
                            <b>{{'guide-components.create-components-text-bold' | translate}}</b>
                            {{'guide-components.create-components-text-and' | translate}}
                            <b>{{'guide-components.create-components-installation-bold' | translate}}</b>
                        </p>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="{{'guide-components.mat-label-filter-components' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-components/filter-components.png" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-components.filter-components-text' | translate}}
                            <b>{{'guide-components.filter-components-text-bold' | translate}}</b>
                            {{'guide-components.filter-components-text-or' | translate}}
                            <b>{{'guide-components.filter-components-meter-type-bold' | translate}}</b>
                        </p>
                    </div>
                </div>
            </mat-tab>
        </div>
    </mat-tab-group>
</div>

<div class="main-container" *ngIf="currentPage === '/register/meters' || currentPage === '/'">
    <h2> {{'guide-meters.title-label' | translate}} </h2>
    <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="center">
        <div class="meters">

            <mat-tab label="{{'guide-meters.home-page' | translate}}">
                <div class="container">
                    <div class="img">
                        <h2> {{'guide-meters.home-page' | translate}} </h2>
                        <img src="../../../assets/imgs-guide-meters/home-page-meters.png" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-meters.home-page-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="{{'guide-meters.mat-label-create-meters' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-meters/create-meters.png" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-meters.create-meters-text' | translate}}
                            <b>{{'guide-meters.create-meters-text-bold' | translate}}</b>
                            {{'guide-meters.create-meters-text-and' | translate}}
                            <b>{{'guide-meters.create-meters-installation-text-bold' | translate}}</b>
                        </p>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="{{'guide-meters.mat-label-filter-meters' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-meters/filter-meters.png" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-meters.filter-meters-text' | translate}}
                            <b>{{'guide-meters.filter-meters-text-bold' | translate}}</b>
                            {{'guide-meters.filter-meters-text-or' | translate}}
                            <b>{{'guide-meters.filter-meters-meter-type-bold' | translate}}</b>
                        </p>
                    </div>
                </div>
            </mat-tab>
        </div>
    </mat-tab-group>
</div>

<div class="main-container" *ngIf="currentPage === '/register/lampsType' || currentPage === '/'">
    <h2> {{'guide-lamps-type.title-label' | translate}} </h2>
    <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="center">
        <div class="{{'guide-lamps-type.title-label' | translate}}">
            <mat-tab label="{{'guide-lamps-type.home-page' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-lamps-type/home-page-lamps-type.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-lamps-type.home-page-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-lamps-type.mat-label-create-lamps-type' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-lamps-type/create-lamps-type.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-lamps-type.create-lamps-type-text' | translate}}
                            <b>{{'guide-lamps-type.create-lamps-type-text-bold' | translate}}</b>
                            {{'guide-lamps-type.create-lamps-type-text-and' | translate}}
                            <b> {{'guide-lamps-type.create-lamps-type-text-bold-dimmer' | translate}}.</b>
                        </p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-lamps-type.mat-label-filter-lamps-type' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-lamps-type/filter-lamps-type.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-lamps-type.filter-lamps-type-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-lamps-type.mat-label-table-lamps-type' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-lamps-type/table-lamps-type.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-lamps-type.table-lamps-type-text' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-lamps-type/table-header-lamps-type.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-lamps-type.table-header-lamps-type' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-properties/properties-downloads.png" alt="">
                    </div>
                    <div class="text">
                        <p>{{'guide-lamps-type.button-downloads-text' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-lamps-type/buttons-edit-and-view-lamps-type.png" alt="">
                    </div>
                    <div class="text">
                        <p>{{'guide-lamps-type.buttons-table-lamps-type' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-lamps-type.mat-label-edit-lamps-type' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-lamps-type/edit-lamps-type.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-lamps-type.sidebar-edit-text' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-lamps-type/view-lamps-type.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-lamps-type.sidebar-view-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>
        </div>
    </mat-tab-group>
</div>

<div class="main-container" *ngIf="currentPage === '/register/installations' || currentPage === '/'">
    <h2> {{'guide-installations.title-label' | translate}} </h2>
    <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="center">
        <div class="{{'guide-installations.title-label' | translate}}">
            <mat-tab label="{{'guide-installations.home-page' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-installations/home-page-installations.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-installations.home-page-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-installations.mat-label-create-installations' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-installations/create-installations.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-installations.create-installations-text' | translate}}
                            <b>{{'guide-installations.create-installations-text-bold' | translate}}</b>
                            {{'guide-installations.create-installations-text-and' | translate}}
                            <b> {{'guide-installations.create-installations-aplication-text-bold' | translate}}</b>.
                        </p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-installations.mat-label-filter-installations' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-installations/filter-reference-installations.png" alt=""
                            class="medium-img-v">
                        <br><br>
                        <img src="../../../assets/imgs-guide-installations/filter-installations-equipment-type.png"
                            alt="" class="medium-img-v">
                        <br><br>
                        <img src="../../../assets/imgs-guide-installations/filter-installations-installation-site.png"
                            alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-installations.filter-installations-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-installations.mat-label-table-installations' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-installations/table-installations.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-installations.table-installations-text' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-installations/table-header-installations.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-installations.table-header-installations' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-installations/downloads-installations.png" alt="">
                    </div>
                    <div class="text">
                        <p>{{'guide-installations.button-downloads-text' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-installations/buttons-edit-and-view-installations.png"
                            alt="">
                    </div>
                    <div class="text">
                        <p>{{'guide-installations.buttons-table-installations' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-installations.mat-label-edit-installations' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-installations/edit-installations.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-installations.sidebar-edit-text' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-installations/view-installations.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-installations.sidebar-view-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>
        </div>
    </mat-tab-group>
</div>

<div class="main-container" *ngIf="currentPage === '/register/installation-site' || currentPage === '/'">
    <h2> {{'guide-installation-site.title-label' | translate}} </h2>
    <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="center">
        <div class="{{'guide-installation-site.title-label' | translate}}">
            <mat-tab label="{{'guide-installation-site.home-page' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-installation-site/home-page-installation-site.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-installation-site.home-page-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-installation-site.mat-label-create-installation-site' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-installation-site/create-installation-site.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-installation-site.create-installation-site-text' | translate}}

                        </p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-installation-site.mat-label-filter-installation-site' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-installation-site/filter-installation-site.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-installation-site.filter-installation-site-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-installation-site.mat-label-table-installation-site' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-installation-site/table-installation-site.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-installation-site.table-installation-site-text' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-installation-site/table-header-installation-site.png"
                            alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-installation-site.table-header-installation-site' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-installation-site/downloads-installation-site.png" alt="">
                    </div>
                    <div class="text">
                        <p>{{'guide-installation-site.button-downloads-text' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-installation-site/buttons-edit-and-view-installation-site.png"
                            alt="">
                    </div>
                    <div class="text">
                        <p>{{'guide-installation-site.buttons-table-installation-site' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-installation-site.mat-label-edit-installation-site' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-installation-site/edit-installation-site.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-installation-site.sidebar-edit-text' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-installation-site/view-installation-site.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-installation-site.sidebar-view-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>
        </div>
    </mat-tab-group>
</div>

<div class="main-container" *ngIf="currentPage === '/register/equipment-type' || currentPage === '/'">
    <h2> {{'guide-equipment-type.title-label' | translate}} </h2>
    <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="center">
        <div class="Tipos de Equipamentos">
            <mat-tab label="{{'guide-equipment-type.home-page' | translate}}">
                <div class="container">
                    <div class="img">
                        <h2> {{'guide-equipment-type.home-page' | translate}} </h2>
                        <img src="../../../assets/imgs-guide-equipments-type/home-page-equipment-type.png"
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-equipment-type.home-page-text' | translate}} </p>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="{{'guide-equipment-type.mat-tab-label-create-equipment-type' | translate}}">
                <div class="container">
                    <div class="img">
                        <h2> {{'guide-equipment-type.informations-equipment-type-title' | translate}} </h2>
                        <img src="../../../assets/imgs-guide-equipments-type/create-equipment-type.png"
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-equipment-type.create-equipment-type-text' | translate}}
                            <b>{{'guide-equipment-type.create-equipment-type-tabs-text-bold' | translate}}</b>
                            {{'guide-equipment-type.create-equipment-type-text-and' | translate}}
                            {{'guide-equipment-type.create-equipment-tabs-text-bold-commands' | translate}}
                            {{'guide-equipment-type.create-equipment-type-text-informations' | translate}}
                        </p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <h2> {{'guide-equipment-type.hardware-and-firmware-title' | translate}} </h2>
                        <img src="../../../assets/imgs-guide-equipments-type/create-equipment-type-hardware-firmware.png"
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-equipment-type.create-equipment-type-tab-hardware-firmware-text' | translate}}
                        </p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <h2> Comandos </h2>
                        <img src="../../../assets/imgs-guide-equipments-type/create-equipment-type-commands.png"
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-equipment-type.create-equipment-type-tab-commands-text' | translate}}
                        </p>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="{{'guide-equipment-type.mat-tab-label-add-commands' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-equipments-type/button-add-commands.png">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-equipment-type.button-add-commands-text' | translate}}
                        </p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-equipments-type/add-commands-query.png"
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-equipment-type.commands-query-text' | translate}}
                        </p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-equipments-type/add-commands-config.png"
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-equipment-type.commands-config-text' | translate}} </p>
                    </div>
                </div>

                <div class="container">
                    <div class="img">
                        <h2> {{'guide-equipment-type.commands-config-title' | translate}} </h2>
                        <img src="../../../assets/imgs-guide-equipments-type/add-commands-config-int.png"
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-equipment-type.commands-config-int-text' | translate}}
                            <b>{{'guide-equipment-type.commands-config-int-text-bold' | translate}}</b>
                            {{'guide-equipment-type.commands-config-int-text-and' | translate}} <b>
                                {{'guide-equipment-type.commands-config-int-text-value-bold' | translate}}</b>.
                        </p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-equipments-type/add-commands-config-bool.png"
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-equipment-type.commands-config-bool-text' | translate}}
                            <b> {{'guide-equipment-type.commands-config-bool-true-text-bold' | translate}} </b>
                            {{'guide-equipment-type.commands-config-bool-text-and' | translate}}
                            <b>{{'guide-equipment-type.commands-config-bool-false-text-bold' | translate}}</b>.
                        </p>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="{{'guide-equipment-type.mat-tab-label-filter-equipment-type' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-equipments-type/filter-equipment-type.png"
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-equipment-type.filter-equipment-type-text' | translate}} </p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-equipment-type.mat-tab-label-table-equipment-type' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-equipments-type/table-equipments-type.png"
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-equipment-type.table-equipment-type-text' | translate}} </p>
                    </div>
                </div>

                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-equipments-type/table-header-equipments-type.png"
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-equipment-type.table-header-equipment-type-text' | translate}} </p>
                    </div>
                </div>

                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-equipments-type/buttons-table-equipments-type.png">
                    </div>
                    <div class="text">
                        <p> {{'guide-equipment-type.buttons-table-equipment-type' | translate}} </p>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="{{'guide-equipment-type.mat-tab-label-edit-equipment-type' | translate}}">
                <div class="container">
                    <div class="img">
                        <h2> {{'guide-equipment-type.edit-equipment-type-info-title' | translate}}</h2>
                        <img src="../../../assets/imgs-guide-equipments-type/edit-equipment-type.png"
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-equipment-type.sidebar-edit-text-informations' | translate}} </p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <h2> {{'guide-equipment-type.edit-equipment-type-hardware-and-firmware-title' | translate}}</h2>
                        <img src="../../../assets/imgs-guide-equipments-type/edit-equipment-type-hardware-firmware.png"
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-equipment-type.sidebar-edit-text-hardwares-firmware' | translate}} </p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <h2> {{'guide-equipment-type.edit-equipment-type-commands' | translate}} </h2>
                        <img src="../../../assets/imgs-guide-equipments-type/edit-equipment-type-commands.png"
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-equipment-type.sidebar-edit-text-commands' | translate}}</p>
                    </div>
                </div>

                <div class="container">
                    <div class="img">
                        <h2> {{'guide-equipment-type.editing-commands-equipment-type-title' | translate}} </h2>
                        <img src="../../../assets/imgs-guide-equipments-type/edit-commands-query.png"
                            class="medium-img-v">
                        <br><br>
                        <img src="../../../assets/imgs-guide-equipments-type/edit-commands-config.png"
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-equipment-type.edit-commands-query-and-config-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>
        </div>
    </mat-tab-group>
</div>

<div class="main-container" *ngIf="currentPage === '/register/division' || currentPage === '/'">
    <h2> {{'guide-division.title-label' | translate}} </h2>
    <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="center">
        <div class="Divisão (subprefeituras)">
            <mat-tab label="{{'guide-division.home-page' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-divisions/home-page-division.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-division.home-page-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-division.mat-label-create-division' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-divisions/create-division.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-division.create-division-text' | translate}}
                            <b>{{'guide-division.create-division-reference-text-bold' | translate}}.</b>
                        </p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-division.mat-label-filter-division' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-divisions/filter-division.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-division.filter-division-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-division.mat-label-table-division' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-divisions/table-division.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-division.table-division-text' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-divisions/buttons-table-division.png" alt="">
                    </div>
                    <div class="text">
                        <p>{{'guide-division.buttons-table-division' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-divisions/table-header-division.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-division.table-header-division' | translate}}</p>
                    </div>
                </div>

                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-divisions/downloads-divisions.png" alt="">
                    </div>
                    <div class="text">
                        <p>{{'guide-division.button-downloads-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-division.mat-label-edit-division' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-divisions/edit-division.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-division.sidebar-edit-text' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-divisions/view-division.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p> {{'guide-division.sidebar-view-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>
        </div>
    </mat-tab-group>
</div>

<div class="main-container" *ngIf="currentPage === '/reportcharts' || currentPage === '/'">
    <h2> {{'guide-report-charts.title-label' | translate}} </h2>
    <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="center">
        <div class="Relatórios (Gráficos)">
            <mat-tab label="{{'guide-report-charts.home-page' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-reportCharts/home-page-reportcharts.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-report-charts.home-page-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="{{'guide-report-charts.mat-label-generate-charts' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-reportCharts/generate-charts.png" alt=""
                            class="medium-img-v">
                        <br><br>
                        <div class="img">
                            <img src="../../../assets/imgs-guide-reportCharts/charts-info.png" alt=""
                                class="medium-img-v">
                        </div>
                    </div>
                    <div class="text">
                        <p>{{'guide-report-charts.generate-charts-text' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-reportCharts/chart.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-report-charts.charts-text' | translate}}
                            <b>{{'guide-report-charts.charts-text-bold' | translate}}</b>
                            {{'guide-report-charts.charts-text-and' | translate}}
                            <b>{{'guide-report-charts.charts-current-text-bold' | translate}}.</b>
                            {{'guide-report-charts.charts-table-text' | translate}}
                        </p>
                    </div>
                </div>
            </mat-tab>
        </div>
    </mat-tab-group>
</div>

<div class="main-container" *ngIf="currentPage === '/audit' || currentPage === '/'">
    <h2> {{'guide-audits.title-label' | translate}} </h2>
    <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="center">
        <div class="Auditorias">
            <mat-tab label="{{'guide-audits.home-page' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-audits/home-page-audits.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-audits.home-page-text' | translate}}</p>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="{{'guide-audits.mat-label-generate-audit' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-audits/generate-audit.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-audits.create-audit-name-text' | translate}}</p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-audits/audit-created.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-audits.audit-created-text' | translate}}</p>
                    </div>
                </div>

                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-audits/add-installations.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-audits.add-installation-text' | translate}}
                        </p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-audits/installations-confirmation.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-audits.confirmation-installation-text' | translate}}
                        </p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-audits/add-or-view-installations.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-audits.button-add-or-view-installation' | translate}}
                        </p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-audits/table-audit.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-audits.installation-table-text' | translate}}
                        </p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-audits/audit-installation-import-field-text.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-audits.audit-installation-import-field-text' | translate}}
                        </p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-audits/selection-import-installation-text.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-audits.selection-import-installation-text' | translate}}
                        </p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-audits/modal-imported-installations-text.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-audits.modal-imported-installations-text' | translate}}
                        </p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-audits/field-add-imported-installations-text.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-audits.field-add-imported-installations-text' | translate}}
                        </p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-audits/table-imported-installations-text.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-audits.table-imported-installations-text' | translate}}
                        </p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-audits/audit-button-delete-installations-text.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-audits.audit-button-delete-installations-text' | translate}}
                        </p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-audits/buttons-audits.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-audits.buttons-audits-text' | translate}}
                        </p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-audits/no-editing-audit.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-audits.no-editing-audits-text' | translate}}
                        </p>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="{{'guide-audits.mat-label-audit-table' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-audits/table-all-audits.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-audits.audit-table-text' | translate}}
                        </p>
                    </div>
                </div>
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-audits/button-edit-view-audits.png" alt="">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-audits.button-view-audits-text' | translate}}
                        </p>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="{{'guide-audits.mat-label-icvi-icti' | translate}}">
                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-audits/audit-ICTI-ICVI.png" alt="" class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>
                            {{'guide-audits.icti-icvi-text' | translate}}
                        </p>
                    </div>
                </div>

                <div class="container">
                    <div class="img">
                        <img src="../../../assets/imgs-guide-audits/buttons-downloads-ICTI-ICVI.png" alt=""
                            class="medium-img-v">
                    </div>
                    <div class="text">
                        <p>{{'guide-audits.button-download-icti-icvi-text' | translate}}
                        </p>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="{{'guide-audits.indicator-documentation-text' | translate}}">
                <br>
                <h1 style="font-size: 20px;"> {{'guide-audits.calculation-methodology-files-text' | translate}}</h1>
                <div class="container">
                    <div class="img">
                        <a href="../../../assets/imgs-guide-audits/IT-056-015_-_Lógica_de_cálculo_do_indicador_ICVI_-_r1.pdf"
                            target="_blank">
                            <mat-icon svgIcon="bx-pdf"></mat-icon>
                            <p>
                                {{'guide-audits.icvi-calculation-logic-text' | translate}}
                            </p>
                        </a>
                        <a href="../../../assets/imgs-guide-audits/IT-056-016_-_Lógica_de_cálculo_do_indicador_ICTI_-_r2.pdf"
                            target="_blank">
                            <mat-icon svgIcon="bx-pdf"></mat-icon>
                            <p>
                                {{'guide-audits.icti-calculation-logic-text' | translate}}
                            </p>
                        </a>
                        <a href="../../../assets/imgs-guide-audits/IT-056-017_-_Lógica_de_cálculo_do_indicador_SDOT_-_r2.pdf"
                            target="_blank">
                            <mat-icon svgIcon="bx-pdf"></mat-icon>
                            <p>
                                {{'guide-audits.sdot-calculation-logic-text' | translate}}
                            </p>
                        </a>
                        <a href="../../../assets/imgs-guide-audits/IT-056-018_-_Lógica_de_cálculo_do_indicador_SDFT_-_r2.pdf"
                            target="_blank">
                            <mat-icon svgIcon="bx-pdf"></mat-icon>
                            <p>
                                {{'guide-audits.sdft-calculation-logic-text' | translate}}
                            </p>
                        </a>
                        <a href="../../../assets/imgs-guide-audits/IT-056-019_-_Lógica_de_cálculo_do_indicador_MPREV_-_r2.pdf"
                            target="_blank">
                            <mat-icon svgIcon="bx-pdf"></mat-icon>
                            <p>
                                {{'guide-audits.mprev-calculation-logic-text' | translate}}
                            </p>
                        </a>
                    </div>
                </div>
            </mat-tab>