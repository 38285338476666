import { Component, Input, OnDestroy, OnInit, signal } from '@angular/core';
import { MapjsService } from 'src/shared/services/mapjs.service';
import { mapStyleLight, mapStyleDark } from '../mapAssets/map.config';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Alert, CircuitBox, Division, Equipment, Gateway, GatewayManager } from '../models/mapModel';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ErrorLibService } from 'src/shared/services/error-lib.service';
import { tzConvertISOEndDate, tzConvertISOStartDate, tzConvertUTC2LocalMap } from 'src/assets/convertTimeZone/convertTimeZone';
import { Favorite, Installation } from '../../painel-fav/painel-fav.model';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { Favorites } from '../ngrx/map.reducer';
import { addFavorite } from '../ngrx/favorite.actions';
import { firstValueFrom, Subscription } from 'rxjs';
import axios from 'axios';
import { latLngToCell } from 'h3-js';
import { decrypt } from 'src/util/cryptography.util';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.less']
})

export class MapComponent implements OnInit, OnDestroy {
  constructor(
    private mapjsService: MapjsService,
    private formBuilder: FormBuilder,
    private router: Router,
    private errorLibService: ErrorLibService,
    private translateService: TranslateService,
    private store: Store<{ favorite: Favorites }>
  ) { }

  @Input() gatewayManager: GatewayManager;

  // Nesta área fica todas as funções inicialidas assim que o mapa é carregado
  public ngOnInit() {

    /** Caso não exista nenhum fuso horário salvo no localStorage **/
    if (this.timeZone === null) {

      /** Constante que armazena o fuso horário do sistema **/
      const systemTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      /** Armazena o fuso do sistema por padrão no localStorage **/
      localStorage.setItem('selectedTimeZone', systemTimeZone)
    }

    /** Limpa o id da instalação armazenado no localStorage, utilizado para filtrar os logs **/
    localStorage.removeItem('installationId');

    // Filtra todas as divisões para poderem ser utilizadas como filtro de instalações
    this.getAllDivisions();

    // Filtra as caixas de circuito através do geoserver
    this.getCircuitBoxes();

    // Filtra os contradores atraves do geoserver para aparecerem no mapa
    this.getGateways();

    /** Realiza o filtro do usuário sempre que o usuário for redirecionado p/ a tela do mapa **/
    this.mapjsService.getUser(`${localStorage.getItem('username')}`)

    this.filteredGateways = this.allGateways;

    /** Caso esteja salvo a preferencia de modo escuro no local storage **/
    if (localStorage.getItem('Theme:') === 'modeDark') {
      this.themeDark = true;
    } else {
      this.themeDark = false;
    }

    // Cria o mapa levando em conta o tema salvo
    this.map = new google.maps.Map(document.getElementById('map')!, {
      zoom: 15,
      minZoom: 2,
      center: new google.maps.LatLng(-25.419736312579452, -49.26531751521734),
      styles: this.themeDark ? mapStyleDark : mapStyleLight,
      mapTypeControl: true, // false Remove os botões de tipo de mapa
      zoomControl: false, // Remover os botões de zoom
      fullscreenControl: false, // Remove o botão de tela cheia
    });

    /** Caso seja a empresa Iluminação Paulistana**/
    if (this.companyId === 'Q29tcGFueToxNg==') {

      /** Carrega o arquivo geoJson que contém as delimitações das subprefeituras de SP **/
      this.map.data.loadGeoJson("assets/subprefeitura-map/subprefeituras.geojson");

      /** Define as estilizações da delimitação **/
      this.map.data.setStyle((feature: any) => {

        const fillColor = feature.getProperty('fill');
        const fillOpacity = feature.getProperty('fill-opacity');

        /** Retorna com as propriedades que foram definidas e define o estilo que for padrão para todas as bordas **/
        return {
          fillColor: fillColor,
          fillOpacity: fillOpacity,
          strokeOpacity: 1,
          strokeWeight: 5,
          strokeColor: 'black'
        };
      });
    }

    this.store.select('favorite').subscribe((favorites) => {
      this.favoriteList = favorites;
    });

    let payloadInitial = {
      favorite: localStorage.getItem('favorites') ? JSON.parse(localStorage.getItem('favorites')!).favorites : {}
    };

    this.store.dispatch((addFavorite(payloadInitial)));

    if (
      localStorage.getItem('selectedInstallations') &&
      JSON.parse(localStorage.getItem('selectedInstallations')!).length > 0
    ) {
      this.selectedInstallationsView = true;

      this.selectedInstallations = JSON.parse(localStorage.getItem('selectedInstallations')!);

      /** Filtra as instalações checadas **/
      this.selectedOptions = this.selectedInstallations.filter(
        (installation: Installation) => installation.check === true);
    }

    // adiciona listener para o evento click no mapa
    google.maps.event.addListener(this.map, 'click', () => {

      // Verifica se algum ponto foi selecionado antes
      if (this.selectedMarker) {
        //Desativa a animação
        this.selectedMarker.setAnimation(null);
        // fecha a janela de informações dos concentradores(Caso tenha)
        this.infoWindowGateway.close();
        // fecha a janela de informações das instalações(Caso tenha)
        this.infoWindowInstallation.close();
        // fecha a janela de informações das caixas de circuito(Caso tenha)
        this.infoWindowCircuitBox.close();
        // fecha a janela de informações das caixas de circuito(Caso tenha)
        this.infoWindowCircuitDevices.close();
        // Fecha o wheel(Caso tenha)
        this.wheelView = false;
      }

      // Remove os markers do mapa
      this.circuitBoxEquipments.forEach((marker: google.maps.Marker) => {
        marker.setMap(null);
      });

      this.circuitBoxEquipments = [];
    });

    // listener de duplo click no poligono(Mapeamento para excluir vertices)
    this.polygon.addListener('dblclick', (event: any) => {
      // Verificar se o clique ocorreu em uma das arestas do polígono
      if (google.maps.geometry.poly.isLocationOnEdge(event.latLng, this.polygon)) {
        // Obter o caminho atual do polígono
        const path = this.polygon.getPath();

        // Encontrar a vértice mais próxima do local clicado
        let minDistance = Number.MAX_VALUE;
        let indexToRemove = -1;

        for (let i = 0; i < path.getLength(); i++) {
          const distance = google.maps.geometry.spherical.computeDistanceBetween(event.latLng, path.getAt(i));
          if (distance < minDistance) {
            minDistance = distance;
            indexToRemove = i;
          }
        }

        // Remover a vértice do caminho (path) do polígono
        if (indexToRemove !== -1) {
          path.removeAt(indexToRemove);

          // Atualizar o polígono no mapa com o novo caminho (path) modificado
          this.polygon.setPath(path);
          this.updatePolygonPaths();
        }
      }
    });

    this.mapjsService.watchGateway().subscribe((type: GatewayManager) => {
      this.gatewayStatusHandler(type);
    });

    /** Variável utilizada para manipular quando a label deverá ser exibida **/
    let labelVisible = false;

    //Adiciona um ouvinte de evento de zoom ao mapa
    google.maps.event.addListener(this.map, 'zoom_changed', () => {

      const bounds = this.map.getBounds();

      /** Variável que armazena o zoom atual **/
      let mapZoom = this.map.getZoom();

      /** Variável que armazena o zoom em que a label vai ser exibida **/
      let labelController = mapZoom >= 14; // Zoom maior ou igual a 14

      /** Verifica se houve uma mudança no estado da label **/
      if (labelController !== labelVisible) {

        /** Atualiza o estado da label **/
        labelVisible = labelController;

        /** Percorre a lista com todos os markers de gateways **/
        this.gatewayMarkers.forEach((marker: google.maps.Marker) => {
          /** Caso o zoom seja maior ou igual a 14 **/
          if (bounds.contains(marker.getPosition()) && labelVisible) {
            /** A label dos gateways é exibida **/
            marker.setLabel({
              text: '' + marker.getTitle(),
              color: 'black',
              fontSize: '12px',
              className: 'labels',
            });
            /** Caso contrário **/
          } else {
            /** A label dos gateways é removida **/
            marker.setLabel(null);
          }
        });
      }
    });

    /** Percorre a lista com todos os markers de gateways **/
    this.circuitBoxMarkers.forEach((marker: google.maps.Marker) => {
      /** Caso o zoom seja maior ou igual a 14 **/
      if (labelVisible) {
        /** A label dos gateways é exibida **/
        marker.setLabel({
          text: '' + marker.getTitle(),
          color: 'black',
          fontSize: '12px',
          className: 'labels',
        });
        /** Caso contrário **/
      } else {
        /** A label dos gateways é removida **/
        marker.setLabel(null);
      }
    });

    this.map.addListener('bounds_changed', () => {
      const bounds = this.map.getBounds();
      for (const marker of this.gatewayMarkers) {
        if (bounds.contains(marker.getPosition()) && labelVisible) {
          marker.setLabel({
            text: '' + marker.getTitle(),
            color: 'black',
            fontSize: '12px',
            className: 'labels',
          });
          if (marker.hasOwnProperty('label')) {
            marker.setZIndex(2);
          }
        } else {
          marker.setLabel(null);
        }
      }
    });
    // cria um objeto MarkerClusterer para clusterizar os marcadores
    /* let markerCluster = new MarkerClusterer({
      map: this.map,
      markers: this.markers,
    }); */

    //Cria um obj circle
    /* let circle = new google.maps.Circle({
      center: this.markers[0].getPosition()!, // Centraliza o
      radius: 1000, // Configura o tamanho do circle
      editable: true, // Permite ao usuário arrastar e redimensionar o círculo
      draggable: true, // Permite ao usuário arrastar o círculo
      map: this.map // O objeto google.maps.Map onde o círculo será exibido
    }); */

    // Listener para recentralização do circle
    /* google.maps.event.addListener(circle, 'center_changed', () => {
      const newCenter = circle.getCenter(); // Obtém a nova posição do centro do círculo
      console.log(`lat: ${newCenter?.lat()}, lng: ${newCenter?.lng()}`);
    }); */

    // Listener para auteração do raio do circle
    /* google.maps.event.addListener(circle, 'radius_changed', () => {
      const newRadius = circle.getRadius(); // Obtém o novo raio do círculo em metros
      console.log(newRadius);
    }); */

    /* google.maps.event.addListener(polygon.getPath(), 'remove_at', (index: any) => {
      console.log(`Vértice alterado: lat - ${polygon.getPath().getAt(index).lat()} | lng - ${polygon.getPath().getAt(index).lng()}`);
      this.markers.forEach((marker: google.maps.Marker) => {
        // Verifica se o marcador está dentro da área do polígono
      const isMarkerInsidePolygon = google.maps.geometry.poly.containsLocation(
        marker.getPosition()!,
        polygon
      );
      });
    }); */

  }

  /** Propriedade que armazena o fuso horário salvo no localStorage **/
  private timeZone = localStorage.getItem('selectedTimeZone');

  public ngOnDestroy() {

    /** Cancelar a subscrição do WebSocket**/
    this.mapjsService.closeConnection();

    /** Caso ocorra uma subscrição **/
    if (this.subscriptions) {
      /** Cancelar todas as susbcrições da tela do mapa**/
      this.subscriptions.unsubscribe();
      console.log("subscrição encerrada!");
    };
  }

  // Armazena o ID da empresa selecionada(Pode iniciar com o valor armazenado localStorage ou null)
  public companyId: string | null = localStorage.getItem('lastCompanySelected') ? localStorage.getItem('lastCompanySelected') : null;

  // Variavel utilizada para realizar o unsubscribe de todas as subscrições do componente
  private subscriptions: Subscription = new Subscription();

  /** Utilizado para encerrar as requisições de tipos de equipamentos e consumo **/
  public subscriptionsEquipmentConsumption: Subscription = new Subscription();

  // Endereço do Geoserver
  private geoServerUrl: string = environment.geoServerUrl;

  // Obj map, será usado na inicialização da tela
  private map: google.maps.Map | any;

  /** Variável utilizada para manipulação do modo escuro do mapa **/
  private themeDark: boolean = false;

  // Variavel utilizada para controle dos info window com dados do websocket
  private gatewayInstallations: [] = [];

  // Variáveis utilizadas para controle ds markers secundários da caixa de circuit
  private circuitIpc: string;
  private circuitCorte: string;
  private circuitGateway: string;

  // Propriedades que armazenam os números de série do IPC/Corte
  public corteSerialNumber: string;
  public ipcSerialNumber: string;

  // Lista de markers (Instalações)
  public markers: google.maps.Marker[] = [];
  // Marker selecionado pelo usuário(Variavel utilizada em diversas funções - CUIDADO)
  private selectedMarker: google.maps.Marker;
  // Lista de marker selecionados no mapa(Concentradores)
  private gatewayMarkers: google.maps.Marker[] = [];
  // Total de instalações re uma requisição(Utilizada no HTML para atualizar a porcentagem no filtro)
  private circuitBoxMarkers: google.maps.Marker[] = [];
  // Markers secundários criado ao filtrar os equipamentos de uma caixa de circuito
  private circuitBoxEquipments: google.maps.Marker[] = [];

  // Lista de concetradores
  public allGateways: Gateway[] = [];
  public filteredGateways: Gateway[] = [];
  public gatewaysCircuitBox: Gateway[] = [];

  // Lista de caixas de circuito
  public allCircuitBoxes: Gateway[] = [];

  public totalInstallations: number = 0;

  // Manipula a apresentação da sidebar de filtro
  public filterController: boolean = false;

  // Armazena o Status e últimas transmissões das instalações(recepta diretamente da requisição)
  private status_lastTransmission: any = {};

  // Manipula a apresentação do loading de mais informações
  public detailConsuptionLoading: boolean = false;

  // Lista de divisões
  public allDivisions: Division[] = [];

  // Lista de equipamentos utilizada para trazer a versão do tipo de equipamento (major, minor, revision) para realizar o filtro de consumo
  public equipment: Equipment[] = [];

  /** Armazena o valor do consumo para ser exibido no infowindow das instalações no mapa **/
  public individualConsumption: string = '';

  // Armazena o tipo de filtro que será feito pelo usuário
  public choiceOptionsToView: string = "details";

  // Recebe o valor do toggle referente ao poligono(Filtro de formas)
  public polygonSlideToggle: FormControl = new FormControl(false);

  // Manipula o estado de habilitado/desabilitado dos campos de endereço
  private polygonIsSelected: boolean = false

  // Lista com as coordenadas do poligono
  private polygonCoords: google.maps.LatLng[] = [];

  public wheelView: boolean = false;

  // Manipula a apresentação do wheel na tela
  public selectedInstallations: Installation[] = [];
  public selectedOptions: Installation[] = [];

  // Manipula a apresentação da sidebar de mais informações
  public moreInfoController: boolean = false;

  // Manipula a apresentação da sidebar do modo de demonstração
  public demonstrationController: boolean = false;

  //Variáveis para manipulação do modo de demonstração
  public index: number = 0;
  public delayInSeconds = 8;
  public elementIsFound = false;

  //Utilizada para iniciar/parar o looping
  public isRunning = false;

  //Informações sobre o gateway filtrado (para uso dos dados no HTML)
  public selectedGateway: any;
  public gatewayName: string = "";
  public equipmentCount: number = 0;

  // Manipula a barra de instalações selecionadas
  public selectedInstallationsView: boolean = false;

  public favoriteList: any;

  public viewFavorite: boolean = false;

  public favoriteSelected: Favorite | any;

  // Armazena o último  filtro feito(Utilizado no botão de download)
  public lastQuery: string = "is_active=true";

  // Armazena o último  filtro feito(Utilizado para gerar o último filtro do poligono)
  private urlFilter = '';

  // Manipula a apresentação do loading de filtro por elementos
  public filterLoading: boolean = false;

  // Manipula a apresentação do loading de filtro por formas
  public filterFormsLoading: boolean = false;

  // Variaveis utilizadas para receberem os dados formatados para o filtro de alertas de consumo detalhado
  public serialNumber: string;
  public address: string;
  public lat: any;
  public long: any;
  public gateway: string;
  public alerts: Alert[] = [];

  // contador de instalações localizadas na lat e log parecidas
  public currentInstallationIndex: number = 0;

  //Armazena um array de instalações com a mesma lat e lng 
  public samePlaceInstalations: google.maps.Marker[] = [];

  // Armazena os macs dos equipamentos
  public installationMacAddress: string;

  /** Variáveis do formulário de criação de componentes **/
  public filterForm: FormGroup = this.formBuilder.group({
    reference: [null],
    magneticKey: [null],
    gatewayStatus: [null],
    status: [null],
    division: [null],
    gateway: [null],
    id: [null],
    street: [null],
    district: [null],
    serialNumber: [null],
    circuitBox: [null]
  });

  public demonstrationForm: FormGroup = this.formBuilder.group({
    delay: [0, [Validators.min(5), Validators.required]]
  })

  /** 
   * Funções assincronas
  */

  // Seleciona as instalações e muda p/ a rota de relatórios
  public async reportSelectInstallation() {
    // Adiciona as instalações
    await this.selectInstallation();

    // Aguarda 100 milisegundos p/ dar tempo de atualizar o componente de selecionados
    setTimeout(() => {
      // Altera a rota p/ a tela de relatórios
      this.router.navigate(['report']);
    }, 100);
  }

  // Seleciona as instalações e muda p/ a rota de relatórios
  public async commandSelectInstallation() {
    await this.selectInstallation();

    // Aguarda 1 segundo p/ dar tempo de atualizar o componente de selecionados
    setTimeout(() => {
      // Altera a rota p/ a tela de comandos
      this.router.navigate(['commands']);
    }, 1000);
  }

  /** Seleciona as instalações e muda p/ a rota de logs **/
  public async logsSelectInstallation() {

    /** Aguarda 1 segundo p/ dar tempo de atualizar o componente de selecionados **/
    setTimeout(() => {

      this.mapjsService.setLogsTrue();

      /**  Altera a rota p/ a tela de logs de instalações**/
      this.router.navigate(['logs/installation']);

      /** Armazena o id da instalação convertido em base64 **/
      const installationId: any = btoa(`Installation:${this.selectedMarker.get('id')}`)

      /** Armazena o id da instalação no localStorage **/
      localStorage.setItem('installationId', JSON.stringify(installationId))

    }, 1000);
  }

  // Faz o filtro de status e últimas transmissões ao filtrar pelo modal
  public async filterStatusAndLastTrasmission(macs: any, installations: any, filter: any, type: any) {

    // Faz o filtro da primeira lista de macs
    this.subscriptions = this.mapjsService.getStatusAndLastTransmissions(macs[0].toString(), filter).subscribe({
      next: async (response: any) => {

        // Armazena o resultado de cada equipamento filtrado
        Object.values(response).forEach((item: any) => {
          this.status_lastTransmission[item.pk] = item;
        });

        // Retira o primeiro grupo de macs da lista
        macs.shift();

        // Verifica se ainda tem grupos na lista
        if (macs.length) {
          // Se tiver, refaz o filtro com o primeiro grupo disponivel
          await this.filterStatusAndLastTrasmission(macs, installations, filter, type);
        } else {
          if (type == "circuitBox") {
            this.getCircuitEquipments();
          }
          else {
            this.makeMarkers(installations, 'elements');
          }
        }
      },
      error: (error) => {
        // Envia uma msn de warning ao usuário
        if (!this.isRunning) {
          Swal.fire({
            icon: 'warning',
            title: this.translateService.instant('map.warning-status-lastTransmission-label'),
            text: this.translateService.instant('map.warning-status-lastTransmission-text'),
          });
        }

        setTimeout(() => {
          if (type == "circuitBox") {
            this.getCircuitEquipments();
          }
          else {
            this.makeMarkers(installations, 'elements');
          }
        }, 100);

        console.log("Error", error);
      }
    });
  }

  /** 
   *  Propriedades que estão instanciando classes do Google Maps.
   * **/

  // Objeto do poligono(Adicionado na tela assim que a polygonSlideToggle for alterada para true)
  private polygon = new google.maps.Polygon({
    paths: this.polygonCoords, //Variavel responsavel por setar as coordenadas do poligono
    editable: true, // Permite ao usuário arrastar e redimensionar o círculo
    draggable: true, // Permite ao usuário arrastar o círculo
    strokeColor: 'rgba(65, 255, 10, 1)', // Cor da borda
    strokeOpacity: 0.8, // Opacidade da borda (0.0 - 1.0)
    strokeWeight: 2, // Espessura da borda em pixels
    fillColor: 'rgba(65, 255, 10, 1)', // Cor de preenchimento
    fillOpacity: 0.35 // Opacidade de preenchimento (0.0 - 1.0)
  });

  // Obj infoWindow(Apresenta mais informações sobre as instalações)
  private infoWindowInstallation = new google.maps.InfoWindow({
    content: ``,// Conteudo inicial vazio(Por não ter pontos vinculados a ela ainda)
    maxWidth: 300 // define a largura máxima da janela de informações para 200 pixels
  });

  // Obj infoWindow(Apresenta mais informações sobre os concentradores)
  private infoWindowGateway = new google.maps.InfoWindow({
    content: ``, // Conteudo inicial vazio(Por não ter pontos vinculados a ela ainda)
    maxWidth: 250 // define a largura máxima da janela de informações para 200 pixels
  });

  private infoWindowCircuitBox = new google.maps.InfoWindow({
    content: ``, // Conteudo inicial vazio(Por não ter pontos vinculados a ela ainda)
    maxWidth: 250 // define a largura máxima da janela de informações para 200 pixels
  });

  private infoWindowCircuitDevices = new google.maps.InfoWindow({
    content: ``, // Conteudo inicial vazio(Por não ter pontos vinculados a ela ainda)
    maxWidth: 250 // define a largura máxima da janela de informações para 200 pixels
  });
  /**
   *  Propriedades ouvintes de eventos do mapa.
   *  
   * **/

  // Intercepta o clique no botão 'mais informações' das instalações e inicia os eventos
  public eventClickInstallation = google.maps.event.addListener(this.infoWindowInstallation, 'domready', () => {
    document.getElementById('more-information')?.addEventListener('click', () => {
      // Ação a ser executada quando o botão for clicado
      // habilita a sidebar de mais informações
      this.moreInfoController = true;
      // Faz a requisição de mais informações
      this.getMoreInformations(this.selectedMarker);
    });
  });

  // Intercepta o clique no botão 'trazer instalações' dos concentradores e inicia os eventos
  public eventClickGateway = google.maps.event.addListener(this.infoWindowGateway, 'domready', () => {
    document.getElementById('get-installations')!.addEventListener('click', () => {
      // Ação a ser executada quando o botão for clicado

      // Fecha a sidebar de filtro
      this.closeEditionSideBar()
      // Fecha a sidebar de mais informações
      this.moreInfoController = false;
      // Reseta os dados de filtro
      this.filterForm.reset();

      this.gatewayInstallations = this.selectedMarker.get('macs') ? this.selectedMarker.get('macs') : [];

      if (this.gatewayInstallations.length > 0) {

        // Retira todas as instalações renderizadas no mapa
        this.markers.forEach((marker: google.maps.Marker) => {
          marker.setMap(null);
        });

        // Limpa a lista de instalações
        this.markers = [];

        // Limpa a lista de requisições utilizadas para geração do CSV
        this.apiLinks = [];

        // Limpa a lista da status e últimas trasmissões
        this.status_lastTransmission = {};

        this.paginateGatewayFilter(this.gatewayInstallations);
      } else {
        Swal.fire({
          icon: 'warning',
          title: this.translateService.instant('map.warning-no-results-label'),
          text: this.translateService.instant('map.warning-no-results-text'),
        });
      }
    });
  });

  public async getStatusCircuitBoxEquipments() {
    /** Fecha a sidebar de filtro **/
    this.closeEditionSideBar();

    /** Fecha a sidebar de mais informações **/
    this.moreInfoController = false;

    /** Reseta os dados de filtro **/
    this.filterForm.reset();

    /** Captura dos valores armazenados no marker selecionado **/
    this.circuitIpc = this.selectedMarker.get('ipc_mac');
    this.circuitCorte = this.selectedMarker.get('corte_mac');
    this.circuitGateway = this.selectedMarker.get('device_serial_number');
    this.ipcSerialNumber = this.selectedMarker.get('ipc_serial');
    this.corteSerialNumber = this.selectedMarker.get('corte_serial');

    /** Verifica se as propriedades dos equipamentos e do gateway possuem valores **/
    if (this.circuitIpc != null || this.circuitCorte != null || this.circuitGateway != null) {

      /**  Retira todas as instalações renderizadas no mapa **/
      this.markers.forEach((marker: google.maps.Marker) => {
        marker.setMap(null);
      });

      /** Limpa a lista de instalações **/
      this.markers = [];

      // Armazena o grupo de mac em formação
      let searchParams = new URLSearchParams();

      searchParams.append('mac', this.circuitCorte);
      searchParams.append('mac', this.circuitIpc);

      // Armazena os grupos de macs
      const paramsDivided: URLSearchParams[] = [];
      paramsDivided.push(new URLSearchParams(searchParams.toString()));

      /** Pega os dados de status e ultimas transmissões dos equipamentos da caixa de circuito **/
      await this.filterStatusAndLastTrasmission(paramsDivided, paramsDivided, "", "circuitBox");

      /** Caso nenhum dado seja encontrado **/
    } else {
      /** Exibe alerta na tela **/
      Swal.fire({
        icon: 'warning',
        title: this.translateService.instant('map.warning-no-results-label'),
        text: this.translateService.instant('map.warning-no-results-text'),
      });
    }
  }

  // Intercepta o clique no botão 'exibir equipamentos' das caixas de circuito e inicia os eventos
  public eventClickCircuitBox = google.maps.event.addListener(this.infoWindowCircuitBox, 'domready', () => {
    document.getElementById('get-circuit-equip')!.addEventListener('click', async () => {
      this.getStatusCircuitBoxEquipments();
    });
  });

  // adiciona listener para o evento closeclick da InfoWindow dos gateways
  public eventCloseInfowindownGateway = this.infoWindowGateway.addListener('closeclick', () => {
    // Se tiver marker selecionado, desativa a animação
    if (this.selectedMarker) {
      this.selectedMarker.setAnimation(null);
    }
  });

  // adiciona listener para o evento closeclick da InfoWindow das instlações
  public eventCloseInfowindownInstallation = this.infoWindowInstallation.addListener('closeclick', () => {

    // Encerra a requisição de tipos de equipamentos e de consumo
    this.subscriptionsEquipmentConsumption.unsubscribe();

    // Se tiver marker selecionado, desativa a animação
    if (this.selectedMarker) {
      this.selectedMarker.setAnimation(null);
    }
  });

  // Intercepta o clique no botão 'próxima instalação' das pétalas que se encontra no mesmo local e inicia os eventos
  public eventClickNextInstallation = google.maps.event.addListener(this.infoWindowInstallation, 'domready', () => {
    document.getElementById('next-instalation')?.addEventListener('click', () => {
      if (this.currentInstallationIndex < this.samePlaceInstalations.length - 1) {
        this.currentInstallationIndex++;
        // Ação a ser executada quando o botão for clicado
        this.getInfoWindowInstallation(this.samePlaceInstalations[this.currentInstallationIndex], this.samePlaceInstalations.length)
      }
    });
  });

  // Intercepta o clique no botão 'próxima instalação' das pétalas que se encontra no mesmo local e inicia os eventos
  public eventClickPreviousInstallation = google.maps.event.addListener(this.infoWindowInstallation, 'domready', () => {
    document.getElementById('previous-instalation')?.addEventListener('click', () => {
      if (this.currentInstallationIndex > 0) {
        this.currentInstallationIndex--;
        // Ação a ser executada quando o botão for clicado
        this.getInfoWindowInstallation(this.samePlaceInstalations[this.currentInstallationIndex], this.samePlaceInstalations.length);
      }

    });
  });

  /** Método utilizado para alterar o tema do mapa para modo claro ou escuro **/
  public changeMapTheme() {

    /** Inverte o resultado para o usuário alternar entre modo escuro/claro **/
    this.themeDark = !this.themeDark;

    // Filtra todas as divisões para poderem ser utilizadas como filtro de instalações
    this.getAllDivisions();

    // Adiciona as caixas de circuito na mudança de tema
    this.getCircuitBoxes();

    // Filtra todos os concetradores para poderem ser utilizados como filtro de instalações
    this.getGateways();

    this.filteredGateways = this.allGateways;

    /** Altera o mapa para o tema escuro **/
    this.map = new google.maps.Map(document.getElementById('map')!, {
      zoom: 15,
      minZoom: 2,
      center: new google.maps.LatLng(-25.419736312579452, -49.26531751521734),
      styles: this.themeDark ? mapStyleDark : mapStyleLight,
      mapTypeControl: true, // false Remove os botões de tipo de mapa
      zoomControl: false, // Remover os botões de zoom
      fullscreenControl: false, // Remove o botão de tela cheia
    });

    /** Variável utilizada para manipular quando a label deverá ser exibida **/
    let labelVisible = false;

    // Adiciona um ouvinte de evento de zoom ao mapa
    google.maps.event.addListener(this.map, 'zoom_changed', () => {

      /** Variável que armazena o zoom atual **/
      let mapZoom = this.map.getZoom();

      /** Variável que armazena o zoom em que a label vai ser exibida **/
      let labelController = mapZoom >= 14; // Zoom maior ou igual a 14

      /** Verifica se houve uma mudança no estado da label **/
      if (labelController !== labelVisible) {

        /** Atualiza o estado da label **/
        labelVisible = labelController;

        /** Percorre a lista com todos os markers de gateways **/
        this.gatewayMarkers.forEach((marker: google.maps.Marker) => {
          /** Caso o zoom seja maior ou igual a 14 **/
          if (labelVisible) {
            /** A label dos gateways é exibida **/
            marker.setLabel({
              text: '' + marker.getTitle(),
              color: 'black',
              fontSize: '12px',
              className: 'labels',
            });
            /** Caso contrário **/
          } else {
            /** A label dos gateways é removida **/
            marker.setLabel(null);
          }
        });
      }
    });

    /** Caso seja a empresa Iluminação Paulistana**/
    if (this.companyId === 'Q29tcGFueToxNg==') {

      /** Carrega o arquivo geoJson que contém as delimitações das subprefeituras de SP **/
      this.map.data.loadGeoJson("assets/subprefeitura-map/subprefeituras.geojson");

      /** Define as estilizações da delimitação **/
      this.map.data.setStyle((feature: any) => {
        const fillColor = feature.getProperty('fill');
        const fillOpacity = feature.getProperty('fill-opacity');

        /** Retorna com as propriedades que foram definidas e define o estilo que for padrão para todas as bordas **/
        return {
          fillColor: fillColor,
          fillOpacity: fillOpacity,
          strokeOpacity: 1,
          strokeWeight: 5,
          strokeColor: 'black'
        };
      });
    }

    /**   
     * Salva a alteração no localStorage do navegador 
      * para toda vez que trocar de página ou sair do sistema a preferência se manter 
     * **/
    /** Caso o usuário troque para o modo claro **/

    /**   
     * Salva a alteração no localStorage do navegador 
      * para toda vez que trocar de página ou sair do sistema a preferência se manter 
     * **/

    let currentTheme = this.themeDark ? "modeDark" : "modeLight"
    localStorage.setItem("Theme:", currentTheme);
  }

  /**
     * Atualiza em tempo real o status do gateway no mapa
     * escrevendo-se num canal websocket.
     * @param serialNumberList
  */
  private watchGatewaysFilter(serialNumberList: Array<string>): void {
    let token: string | null = localStorage.getItem('token');
    this.mapjsService.watchGatewaysRequest(serialNumberList, token);
  }

  /** 
   *  Métodos relacionados aos markers do mapa em geral (Gateways e Equipamentos)
   * 
   * **/


  // funçao para truncar valores da lat e lng 
  public truncateDecimalPlaces(number: any) {
    // contar quantas casas depois do ponto, pois a quantidade de numeros depois do ponto da lat 
    //lng são variaveis  em cada instalação
    let decimal = (number.toString().split('.')[1] || "").length
    return parseFloat(number.toFixed(decimal - 1));
  }

  // funçao para verificar instalações iguais ao marker selecionado e alterar caso seja diferente
  private countInstallation() {

    // Verifica se o marcador selecionado já está na lista
    const isSelectedMarkerInList = this.samePlaceInstalations.includes(this.selectedMarker);

    if (!isSelectedMarkerInList) {
      // filtra as instalações que tem a mesma lat e lng

      let samePlaceInstallations = this.markers.filter(marker => {

        let hexInstallation = latLngToCell(this.selectedMarker.getPosition()!.lat(), this.selectedMarker.getPosition()!.lng(), 13);
        let hexMarker = latLngToCell(marker.getPosition()!.lat(), marker.getPosition()!.lng(), 13);
        return hexMarker == hexInstallation;
      });
      // sort para sempre deixar o marker selecionando na primeira posição da lista 
      let sortedInstallations = samePlaceInstallations.sort((a, b) => {
        if (a === this.selectedMarker) {
          return -1;
        } else if (b === this.selectedMarker) {
          return 1;
        } else {
          return 0;
        }
      });
      //atualiza as variaveis globais
      this.currentInstallationIndex = 0;
      this.samePlaceInstalations = sortedInstallations;
    }
  }

  //função que gera a janela de informações da instalação selecionada
  private getInfoWindowInstallation(marker: any, lengthIns: number) {

    this.countInstallation();

    //remove a animação do marker quando tem mais de um pétala na mesma lat e lng 
    if (this.selectedMarker) {
      this.selectedMarker.setAnimation(null);
    }

    let equipmentInfo = this.status_lastTransmission[marker.get('device_mac')];
    marker.set('last_transmission', equipmentInfo?.last_timestamp ? equipmentInfo.last_timestamp : null);
    marker.set('status', equipmentInfo?.status === 1 ? true : false);

    // Formata os dados para a janela de informações(InfoWindown) do marker
    let gatewayReference = this.allGateways.find(gateway => gateway.id == marker.get('gateway_id'));
    let divisionReference = this.allDivisions.find(division => division.id == marker.get("division_id"));
    let application = marker.get("applications");
    let key = marker.get("has_magnetic_key") ? "Possui chave" : "Não possui";
    marker.set("gateway_reference", gatewayReference ? gatewayReference!.reference : "Não possui");
    let division = divisionReference ? divisionReference!.reference : "Não possui";
    let lastTransmission = marker.get('last_transmission') ? tzConvertUTC2LocalMap(marker.get('last_transmission')) : "Não informado";
    let status = marker.get('status') ? "Ligado" : "Desligado";

    // Define o campo de status no infoWindow de acordo com a cor do ícone (sem tx ou ligado/desligado)
    if (marker.get('device_mac') == null) {
      status = "Sem equipamento"
    } else if (marker.get('last_transmission') != null) {

      const transmissionTimeDiff = this.formatDatetime(marker.get('last_transmission')!)

      if (transmissionTimeDiff! > 6 && transmissionTimeDiff! < 24) {
        status = "Sem transmissão (+6h)";
      } else if (transmissionTimeDiff! > 24) {
        status = "Sem transmissão (+24h)";
      }

    } else {
      status = "Nunca transmitiu"
    }

    let applicationIcon = ""

    /** Atualiza o campo da propriedade de consumo com uma string de carregamento, 
     * até que a requisição de consumo com os dados referente a medição sejam carregados e então atualizados **/
    this.individualConsumption = "Carregando...";

    switch (application) {
      case 'water':
        application = 'Água';
        applicationIcon =
          `
          <div style="background-image: url('../../../../assets/meters-imgs/water-meter.png'); width: 200px;height:263px; margin-left:55px;"></div>
          <div style="background: white; box-shadow: inset 0 0 0 5px #62616a; border: 8px solid #999999;; border-radius: 50%; margin-top: -159px; margin-left: 0px; width: 163px; height: 154px; display: flex; align-items: center;justify-content: center;flex-direction: column;flex-wrap: nowrap;">  
            <div style="display: flex;align-items: center;justify-content: center; background-color: #62616a; width: 136px; height: 45px; border-radius: 12px;">
              <h1 class="consumption-text" style="color: black;font-size: 22px;font-weight: 600;border-radius: 7px;font-family: 'DS-Digital', sans-serif;margin-top: 15px;background: -webkit-linear-gradient(360deg, rgb(255 255 255) 20%, rgb(255 245 245 / 76%) 20%, rgb(255 255 255) 40%, rgb(255 255 255) 40%, rgb(255 255 255) 60%, rgb(255 255 255) 60%, rgb(255 255 255 / 79%) 80%, #ea685ef0 80%);">${this.individualConsumption}</h1>      
            </div>
          </div>
          <div style="background-image: url('../../../../assets/meters-imgs/pointer-40px.png'); border-radius: 50%;width: 35px;height: 35px; margin-left: 51px; margin-top: -52px;"></div>
          <div style="background: white;border: 13px solid black;border-radius: 50%;width: 5px;height: 5px;margin-top: -109px;margin-left: 62px;"></div> 
          <div style="width: 0px;margin-left: 63px;height: 0px;margin-top: -25px;border-radius: 62px;border: 12px solid;border-color: black white black white;"></div>
          <div style="background-image: url('../../../../assets/meters-imgs/pointer-20px.png'); border-radius: 50%;width: 20px;height: 20px; margin-left: 93px; margin-top: 58px;"></div>
        `
        break;
      /// Utilizado para iluminação
      // case 'light':
      //   application = 'Iluminação';
      //   applicationIcon =
      //     `
      //       <div style="margin-left: auto;margin-right: auto;background-color: #9997be;width: 234px;height: 191px;justify-content: center;border-radius: 5px;border: 10px solid;border-color: #67658d; margin-top: 20px;"> 
      //         <div style="display: flex;justify-content: center;align-content: center;flex-direction: column;flex-wrap: nowrap;align-items: center; align-items: center;justify-content: center;">
      //           <div style="background-image: url('../../../../assets/meters-imgs/light.png');width: 52px;height: 52px;border: solid 2px #67658d;border-radius: 38px;margin-top: 15px;"></div>
      //         </div>
      //         <div style="display: flex;justify-content: center;align-content: center;flex-direction: column;flex-wrap: nowrap;align-items: center; align-items: center;justify-content: center;">
      //           <div style="background-color: #e0dff2;width: 208px;height: 64px;border-radius: 15px;margin-top: 27px; display: flex; align-items: center;justify-content: center;">
      //             <div style="display: flex;align-items: center;justify-content: center;background-color: #62616a;width: auto;height: 48px;border-radius: 12px;">  
      //               <h1 class="consumption-text" style="text-align: center;color: black; border-radius: 5px;width: auto;font-size: 22px;font-weight: 600;font-family: 'DS-Digital', sans-serif; margin-top: 13px; background: -webkit-linear-gradient(360deg, rgb(246 235 235 / 66%) 20%, rgb(255 245 245 / 41%) 20%, rgba(241, 228, 228, 0.9360119047619048) 40%, rgba(196, 186, 186, 0.6923144257703081) 40%, rgba(182, 157, 157, 1) 60%, rgba(224, 208, 208, 1) 60%, rgba(180, 163, 163, 1) 80%, #f44336e8 80%);"> ${this.individualConsumption} </h1>
      //             </div>
      //           </div>
      //         </div>
      //       </div>
      //       <div style="background-color: #9997be;width: 223px;height: 54px;margin-left: auto;position: relative;margin-right: auto;margin-top: 0px;border-left-radius: 5px;border-bottom-left-radius: 5px;border-bottom-right-radius: 5px; border-bottom: 12px solid #67658d; display: flex;flex-direction: row;justify-content: space-between;align-items: flex-end;">
      //         <div style="background-color: white;width: 15px;height: 15px;border-radius: 15px;border: solid 2px #373453;"></div> 
      //         <div style="background-color: white;width: 15px;height: 15px;border-radius: 15px;border: solid 2px #373453;display: flex;align-content: center;justify-content: flex-start;align-items: center;">
      //           <div style="background: yellow;width: 30px;height: 30px;margin-top: 69px;margin-right: auto;margin-left: -77px;">
      //             <div style="background: black;width: 25px;height: 67px;margin-top: 26px;margin-right: auto;margin-left: -74px;"></div>
      //             <div style="background: black;width: 25px;height: 55px;margin-top: -64px;margin-right: auto;margin-left: 3px;"></div>
      //             <div style="background: black;width: 104px;height: 26px;margin-top: 0px;margin-right: auto;margin-left: 3px;border-bottom-left-radius: 5px;border-bottom-right-radius: 5px;border-top-right-radius: 5px;"></div>
      //             <div style="background: black;width: 105px;height: 25px;margin-top: -26px;margin-right: auto;margin-left: -154px;border-bottom-left-radius: 5px;border-bottom-right-radius: 5px;border-top-left-radius: 5px;"></div> 
      //             <div style="background: black;width: 23px;height: 92px;margin-top: -84px;margin-right: auto;margin-left: -33px;border-bottom-left-radius: 5px;border-bottom-right-radius: 5px;"></div>
      //           </div>
      //           <div style="background: yellow;width: 30px;height: 30px;margin-top: 69px;margin-right: auto;margin-left: -202px;"></div>
      //           <div style="background: yellow;width: 30px;height: 30px;margin-top: 69px;margin-right: auto;margin-left: -85px;"></div>
      //         </div>
      //       </div>
      //     `
      //   break;

      case 'gas':
        application = 'Gás';
        applicationIcon =
          `
            <div style="display: flex;align-items: center;justify-content: center;background-color: yellow;width: 276px;height: 131px;border-radius: 38px;margin-top: 60px;">
              <div style="background-color: #E4D00A;width: 200px;height:132px;display: flex;align-items: center;justify-content: center;">
                <div style="border-top: solid 5px;background-color: #E4D00A;width: 175px;height: 174px;display: flex;align-items: center;justify-content: center;margin-top: -42px;">     
                  <div style="background-color: white;width: 200px;height: 165px;border-radius: 137px;display: flex;align-items: center;justify-content: space-around;align-content: center; border: solid 3px #e49f0a;">
                    <div style="background-color: #62616a;width: 156px;height: 45px;display: flex; border-radius: 12px;margin-top: -21px;flex-wrap: wrap;justify-content: space-around;"> 
                      <h1 class="consumption-text" style="text-align: center;color: black; border-radius: 5px;width: auto;font-size: 25px;font-weight: 600;font-family: 'DS-Digital', sans-serif; margin-top: 5px; background: -webkit-linear-gradient(360deg, rgb(255 255 255) 20%, rgb(255 245 245 / 76%) 20%, rgb(255 255 255) 40%, rgb(255 255 255) 40%, rgb(255 255 255) 60%, rgb(255 255 255) 60%, rgb(255 255 255 / 79%) 80%, #ea685ef0 80%);"> ${this.individualConsumption} </h1>
                      <div style="background-image: url('../../../../assets/meters-imgs/gas-meter.png');width: 73px;height: 42px;flex-direction: column;justify-content: center;align-items: center;"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style="margin-left: auto;margin-right: auto;background-color: #eef200;width: 30px;height: 64px;border-radius: 11px;">
              <div style="margin-left: -60px;margin-right: 50px;background-color: #c00a0a;width: 152px;height: 18px;border-radius: 11px;margin-top: 27px;"></div>    
              <div style="margin-left: -80px;background-color: red;width: 31px;height: 20px;border-radius: 21px;margin-top: -27px;"></div>
              <div style="margin-left: -80px;background-color: red;width: 31px;height: 20px;border-radius: 21px;margin-top: -2px;"></div>
              <div style="margin-left: 0px;background-color: red;width: 31px;height: 20px;border-radius: 21px;margin-top: -42px;"></div>
              <div style="margin-left: 0px;background-color: red;width: 31px;height: 20px;border-radius: 21px;margin-top: -2px;"></div>
              <div style="margin-left: 65px;background-color: red;width: 31px;height: 20px;border-radius: 21px;margin-top: -36px;"></div>
              <div style="margin-left: 65px;background-color: red;width: 31px;height: 20px;border-radius: 21px;margin-top: -2px;"></div>
            </div>
          `
        break;

      case 'electricity':
        application = 'Energia';
        applicationIcon =
          `
            <div style="margin-left: auto;margin-right: auto;background-color: #373453;width: 200px;height: 156px;display: flex;align-items: center;justify-content: center;border-radius: 5px;border: 10px solid;border-color: #67658d; margin-top:20px;"> 
              <div style="background-color: #e0dff2;width: 160px;height: 60px;border-radius: 15px;display: flex;flex-direction: column;justify-content: center;align-items: center;">
                <div style="display: flex;align-items: center;justify-content: center;background-color: #62616a;width:145px;height: 48px;border-radius: 12px;">  
                    <h1 class="consumption-text" style="text-align: center;color: black; border-radius: 5px;width: auto;font-size: 22px;font-weight: 600;font-family: 'DS-Digital', sans-serif; margin-top: 13px;  background: -webkit-linear-gradient(360deg, rgb(255 255 255) 20%, rgb(255 245 245 / 76%) 20%, rgb(255 255 255) 40%, rgb(255 255 255) 40%, rgb(255 255 255) 60%, rgb(255 255 255) 60%, rgb(255 255 255 / 79%) 80%, #ea685ef0 80%);"> ${this.individualConsumption} </h1>
                </div>
              </div>
            </div>
            <div style="display: flex;align-items: center;justify-content: center;">
              <div style="background-image: url('../../../../assets/meters-imgs/ray-yellow-background.png'); width: 25px; height: 25px; margin-left: auto;margin-right: auto; margin-top: -58px;border: solid 1px #af9a9a;border-radius: 38px;"></div>
            </div>
            <div style="background-color: #373453; width:180px;height: 46px; margin-left: auto; margin-right: auto;border-bottom-left-radius: 5px;border-bottom-right-radius: 5px;display: flex; flex-direction: row; justify-content: space-around; align-items: flex-start; flex-wrap: nowrap; ">  
              <div style="background-color: #67658d;width: 30px;height: 120px;">
                <div style="background-color: #9e9cc3;width: 8px;height: 96px;margin-right: auto;margin-left: auto;border-radius: 5px;"></div>
              </div>
              <div style="background-color: #67658d;width: 30px;height: 120px;">
                <div style="background-color: #9e9cc3;width: 8px;height: 96px;margin-right: auto;margin-left: auto;border-radius: 5px;"></div>
              </div>
            </div>
            <div style="background-color: white;width: 15px;height: 15px;border-radius: 15px;border: solid 2px #373453; margin-left: 48px; margin-top: -17px;"></div>
            <div style="background-color: white;width: 15px;height: 15px;border-radius: 15px;border: solid 2px #373453; margin-left: 208px; margin-top: -17px;"></div>
          `
        break;

      default:
        application = 'Não informado'
        this.individualConsumption = 'Não informado'
        break;
    }

    this.infoWindowInstallation.setContent(
      `
        <style> @import url('https://fonts.cdnfonts.com/css/ds-digital'); </style>

        <div class="consumption-text" style="display: flex; justify-content: center; flex-direction: column; font-family: "Play"; src: url("src\assets\fonts\Play-Regular.ttf");">
          <h1 style="font-size: 16px; color: #4c942a; font-weight: bold; width: 300px;">Informações do Equipamento</h1>

          ${lengthIns === 1 ? `<h3 style="font-weight: bold;">${marker.getTitle()}</h3> `
        : `<div style="display: flex; justify-content: space-between; align-items: center;  flex-direction: row;">
                <button  id="previous-instalation" class="btn btn-light rounded-circle align-middle"  ${this.currentInstallationIndex === 0 ? 'disabled' : ''} >
                  <img style="width: 1rem;"  src="../../../../assets/imgs/arrow_back.svg" alt="arrow_back">
                </button>
                <h3 style="font-weight: bold;" class="m-0">${marker.getTitle()}</h3> 
                <button  id="next-instalation" class="btn btn-light rounded-circle align-middle ${this.currentInstallationIndex === lengthIns - 1 ? 'disabled' : ''}" >
                  <img style="width: 1rem;" src="../../../../assets/imgs/arrow_right.svg" alt="arrow_next">
                </button>
              </div>
          `}

          <p style="font-weight: bold; font-size: 13px;"><b style="font-size: 14px; font-family:'Play'; src: url('src\assets\fonts\Play-Regular.ttf'); color: #4c942a;">STATUS</b>: ${status}</p>
          <p style="font-weight: bold; font-size: 13px;"><b style="font-size: 14px; font-family:'Play'; src: url('src\assets\fonts\Play-Regular.ttf'); color: #4c942a;">SUB DIVISÃO</b>: ${division}</p>
          <p style="font-weight: bold; font-size: 13px;"><b style="font-size: 14px; font-family:'Play'; src: url('src\assets\fonts\Play-Regular.ttf'); color: #4c942a;">CHAVE MAGNÉTICA</b>: ${key}</p>
          <p style="font-weight: bold; font-size: 13px;"><b style="font-size: 14px; font-family:'Play'; src: url('src\assets\fonts\Play-Regular.ttf'); color: #4c942a;">CONCENTRADOR</b>: ${marker.get("gateway_reference")}</p>
          <p style="font-weight: bold; font-size: 13px;"><b style="font-size: 14px; font-family:'Play'; src: url('src\assets\fonts\Play-Regular.ttf'); color: #4c942a;">ÚLTIMA TRANSMISSÃO</b>: ${lastTransmission} </p>
          <p style="font-weight: bold; font-size: 13px;"><b style="font-size: 14px; font-family:'Play'; src: url('src\assets\fonts\Play-Regular.ttf'); color: #4c942a;">MEDIÇÃO</b>: ${this.individualConsumption} </p>
          <p style="font-weight: bold; font-size: 13px;"><b style="font-size: 14px; font-family:'Play'; src: url('src\assets\fonts\Play-Regular.ttf'); color: #4c942a;">APLICAÇÃO</b>: ${application} </p>
          <button class="btn btn-success" id="more-information">MAIS INFORMAÇÕES</button>

          ${applicationIcon}

        </div>
      `
    );

    // abre a janela de informações no marker
    this.infoWindowInstallation.open(this.map, marker);

    // Atualiza variavel com o marker selecionado
    this.selectedMarker = marker;

    // configura a animação do marker
    this.selectedMarker.setAnimation(google.maps.Animation.BOUNCE);
  }

  // Gera os markers de instalações   
  private makeMarkers(installations: any, typeFilter: string) {

    // Verifica se foi selecionado algum STATUS no filtro para deixar apenas os markers
    // que possuem o mesmo status filtrado
    switch (this.filterForm.value.status) {
      case "on":
        {
          installations = installations.filter(
            (installation: any) => {
              // Verifica se há transmissão recente e se o status é de ligado
              const transmission = this.status_lastTransmission[installation.properties.device_mac]
              const lastTransmission = this.formatDatetime(transmission?.last_timestamp)

              const hasRecentTransmission: boolean = lastTransmission != null && lastTransmission < 6;

              return transmission?.status == 1 && hasRecentTransmission
            })

          break;
        }
      case "off":
        {
          installations = installations.filter(
            (installation: any) => {
              // Verifica se há transmissão recente e se o status é de desligado
              const transmission = this.status_lastTransmission[installation.properties.device_mac]
              const lastTransmission = this.formatDatetime(transmission?.last_timestamp)

              const hasRecentTransmission: boolean = lastTransmission != null && lastTransmission < 6;

              return transmission?.status == 0 && hasRecentTransmission
            })

          break;
        }
      case "6hours":
        {
          installations = installations.filter(
            (installation: any) => {
              // Verifica se a última transmissão é entre 6 e 24h
              const diff = this.formatDatetime(this.status_lastTransmission[installation.properties.device_mac]?.last_timestamp)
              return diff !== null && diff > 6 && diff < 24
            }
          )
          break;
        }
      case "24hours":
        {
          installations = installations.filter(
            (installation: any) => {
              // Verifica se já faz mais de 24h desde a última transmissão
              const diff = this.formatDatetime(this.status_lastTransmission[installation.properties.device_mac]?.last_timestamp)
              return diff !== null && diff > 24
            }
          )
          break;
        }
      case "never":
        {
          installations = installations.filter(
            (installation: any) =>
              // Verifica se o equipamento nunca transmitiu
              this.status_lastTransmission[installation.properties.device_mac]?.last_timestamp == null
          )
          break;
        }
      default:
    }

    // Habilita o botão de filtro caso não tenha resultados
    if (!installations.length) {
      this.filterLoading = false;
    }

    this.totalInstallations = installations.length

    // Para cada instalação retornada
    installations.forEach((installation: any) => {

      setTimeout(() => {

        let samePlaceInstallations = this.markers.filter(marker => {

          let hexInstallation = latLngToCell(installation.geometry.coordinates[1], installation.geometry.coordinates[0], 13);
          let hexMarker = latLngToCell(marker.getPosition()!.lat(), marker.getPosition()!.lng(), 13);
          return hexMarker == hexInstallation;
        });

        let marker: google.maps.Marker = new google.maps.Marker({
          position: {
            lat: installation.geometry.coordinates[1],
            lng: installation.geometry.coordinates[0]
          },
          title: installation.properties.reference, // Define um título
          clickable: true, // Permite que o marker seja clicado
          map: this.map,
        });

        if ((samePlaceInstallations.length + 1) > 1) {
          marker.setLabel({
            text: ((samePlaceInstallations.length + 1) > 1) ? ((samePlaceInstallations.length + 1)).toString() : " ",
            fontSize: "15px",
            fontWeight: "bold",
            color: "black",
            className: ((samePlaceInstallations.length + 1) > 1) ? "bg-light p-1 pt-0 pb-0 rounded-circle" : " "
          },
          );
        }

        // Armazena as informações de última transmissão e status da instalação
        let equipmentInfo = this.status_lastTransmission[installation.properties.device_mac];

        // Armazena os dados das instalações em propriedades no marker
        marker.set('id', installation.properties.id);
        marker.set('division_id', installation.properties.division_id);
        marker.set('gateway_id', installation.properties.gateway_id);
        marker.set('equipment_type', installation.properties.equipment_type);
        marker.set('status', equipmentInfo?.status === 1 ? true : false);
        marker.set('has_magnetic_key', installation.properties.possible_magnectic_key);
        marker.set('device_mac', installation.properties.device_mac != 'NULL' ? installation.properties.device_mac : null);
        marker.set('last_transmission', equipmentInfo?.last_timestamp ? equipmentInfo.last_timestamp : null);
        marker.set('applications', installation.properties.applicationtag)

        let iconUrl = this.getIconByTransmission(marker, "installation");
        let icon = {
          url: iconUrl,
          size: new google.maps.Size(38, 38),
          scaledSize: new google.maps.Size(38, 38)
        }

        // Atualize o marcador existente com o novo ícone
        marker.setIcon(icon);

        // listener de click é adicionado ao marker
        marker.addListener('click', async () => {

          // Remove animação do marker selecionado anteriormente
          if (this.selectedMarker) {
            this.selectedMarker.setAnimation(null);
          }

          // fecha wheel
          this.wheelView = false;

          // Atualiza variavel com o marker selecionado
          this.selectedMarker = marker;

          // Fecha o infoWindown do concentrador
          this.infoWindowGateway.close();

          // Fecha janela de informações da instalação
          this.infoWindowInstallation.close();

          // Desativa a animação do marker
          this.selectedMarker.setAnimation(null);

          this.getInfoWindowInstallation(marker, (samePlaceInstallations.length + 1));

          // Encerra as requisições de tipos de equipamentos e de consumo caso o usuário alterne entre os markers antes da requisição ser finalizada
          this.subscriptionsEquipmentConsumption.unsubscribe();

          // Realiza a requisição que busca o tipo de equipamento e traz os dados de consumo
          this.getEquipmentTypeVersion(installation);

          // redefine as opções do mapa para os valores do marker selecionado
          this.map.setOptions({
            center: new google.maps.LatLng(marker.getPosition()!),
            zoom: 18
          });
        });

        // Adicione o ouvinte de evento para o clique com botão direito no marcador
        marker.addListener("rightclick", () => {

          // Remove a animação do marker selecionado anteriormente
          if (this.selectedMarker) {
            this.selectedMarker.setAnimation(null);
          }

          // configura a animação do marker
          marker.setAnimation(google.maps.Animation.BOUNCE);

          // Atualiza variavel com o marker selecionado
          this.selectedMarker = marker;

          // Fecha janela de informações do concentrador
          this.infoWindowGateway.close();

          // Fecha janela de informações da instalação
          this.infoWindowInstallation.close();

          // Abre o wheel
          this.wheelView = true;

          // redefine as opções do mapa para os valores do marker selecionado
          this.map.setOptions({
            center: new google.maps.LatLng(marker.getPosition()!),
            zoom: 18
          });
        });

        // Adiciona o marker a uma lista para ser manipulado futuramente
        this.markers.push(marker);

        // Verifica se todas as instalações foram carregadas
        if (this.markers.length === installations.length) {

          this.filterFormsLoading = false;
          this.filterLoading = false;

          // Atualiza a centralização do mapa de acordo com o último ponto da lista
          if (this.markers.length) {
            if (!this.isRunning) {
              this.map.setOptions({
                center: new google.maps.LatLng(this.markers[this.markers.length - 1].getPosition()!),
                zoom: 15
              });
            }
          }
        }
      }, 0.1);
    });
  }

  // Boolean responsavel por saber se o companyId pertence ou nao as roles permitidas para o usuario logado
  private userBelongsCompany(role: any) {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(role, 'application/xml');
    const roles = Array.from(xmlDoc.getElementsByTagName('role')).map(role => role.textContent!);

    // Verifica se o companyId está na lista de permissões, caso null, retorna valor true 
    const hasPermission = this.companyId === null ? true : roles.includes(window.atob(this.companyId).replace(/\D/g, ''));
    return hasPermission
  }

  /** Método que realiza o filtro de tipos de equipamentos **/
  public getEquipmentTypeVersion(installation: any) {
    /** Realiza a requisição que busca os tipos de equipamentos, passando o mac como parâmetro **/
    this.subscriptionsEquipmentConsumption = this.mapjsService.getEquipmentByMacAddress(installation.properties.device_mac, this.companyId)
      .valueChanges
      .subscribe({
        next: (res: any) => {
          /** Armazena a versão do tipo de equipamento **/
          const version = `${installation.properties.equipment_type} ${res.data.equipment.edges[0].node.equipmentType.major}.${res.data.equipment.edges[0].node.equipmentType.minor}.${res.data.equipment.edges[0].node.equipmentType.revision}`;

          /** Chama o método que realiza a requisição de consumo, passando as informações da instalação e da versão do tipo de equipamentos **/
          this.getConsumptionData(installation, version);
        },
        /** Caso ocorra algum erro na requisição **/
        error: (error: any) => {

          /** Exibe o erro no console do navegador **/
          console.log('Error on getEquipmentByMacAddress', error);

          /** Variável utilizada para armazenar o conteúdo do infowindow **/
          let contentString: any = this.infoWindowInstallation.getContent();

          /** Atualiza o valor da propriedade do consumo **/
          this.individualConsumption = 'Não informado';

          /** Atualiza apenas o texto interno da tag <h1> com a classe consumption-text **/
          contentString = contentString.replace(
            /(<h1[^>]*class=["']consumption-text["'][^>]*>)(.*?)(<\/h1>)/,
            `$1${this.individualConsumption}$3` // Adiciona o texto 'Não informado' no medidor
          );

          /** Atualiza apenas o valor exibido na tag <p> para MEDIÇÃO **/
          contentString = contentString.replace(
            /(<b[^>]*>MEDIÇÃO<\/b>:\s*)([^<]*)(<\/p>)/,
            `$1${this.individualConsumption}$3` // Adiciona o texto 'Não informado' para campo de "MEDIÇÃO"
          );

          /** Atualiza o infowindow com a informação da medição **/
          this.infoWindowInstallation.setContent(contentString);
        }
      });
  }

  /** Método que realiza a requisição de consumo para exibir no infowindow das instalações **/
  public getConsumptionData(installation: any, version: any) {

    /** Variável utilizada para acessar o conteúdo do infowindow **/
    let contentString: any = this.infoWindowInstallation.getContent();

    /**  Variáveis para a data de início e fim **/
    let startDate = new Date();
    let endDate = new Date();

    /**  Subtrai 1 hora de startDate pegando a data incial de uma hora atrás **/
    startDate.setTime(startDate.getTime() - 3600000);

    /** Realiza a requisição de consumo **/
    this.subscriptionsEquipmentConsumption = this.mapjsService.getConsumptionData(
      tzConvertISOStartDate(startDate), // Data inicial
      tzConvertISOEndDate(endDate), // Data final
      installation.properties?.device_mac, // Endereço mac 
      version, // Versão do tipo de equipamento
      btoa(`Installation:${installation?.properties?.id}`) // id da instalação
    ).valueChanges.subscribe({
      next: ((individualConsumption: any) => {

        /** Armazena o valor de potência proporcional (para ser utilizado para ips (light) **/
        // const proportionalPower: any = (individualConsumption?.data?.individualConsumption[0]?.metadata[0]?.proportionalPower / 1000).toFixed(5) || 'Não informado';

        /** Armazena o valor do consumo total (para ser utilizado para read e pulso **/
        let consumptionTotal: any = (individualConsumption?.data?.individualConsumption[0]?.total).toFixed(5) || 'Não informado';

        /** Verifica se o infowindow existe para que possa ter o conteúdo modificado **/
        if (this.infoWindowInstallation) {

          /** Verifica se o consumo retornou sem dados **/
          if (individualConsumption?.data?.individualConsumption.length === 0) {

            /** Atualiza o valor do campo de potência proporcional que será exibido para o usuário **/
            this.individualConsumption = 'Não informado';

            /** Atualiza apenas o texto interno da tag <h1> com a classe consumption-text **/
            contentString = contentString.replace(
              /(<h1[^>]*class=["']consumption-text["'][^>]*>)(.*?)(<\/h1>)/,
              `$1${this.individualConsumption}$3`
            );

            /** Atualiza apenas o valor exibido na tag <p> para MEDIÇÃO **/
            contentString = contentString.replace(
              /(<b[^>]*>MEDIÇÃO<\/b>:\s*)([^<]*)(<\/p>)/,
              `$1${this.individualConsumption}$3`
            );

            /** Atualiza o infowindow **/
            this.infoWindowInstallation.setContent(contentString);

            /** Caso retorne dados na requisição **/
          } else {
            /** Verifica o tipo de aplicação para atualizar o valor da medição com a unidade de medida correta p/ electricity (SGREAD) **/
            if (this.selectedMarker.get("applications") === 'electricity') {

              /** Realiza a divisão do consumo total por 1000 **/
              individualConsumption = (consumptionTotal / 1000).toFixed(5);

              /** Atualiza apenas o texto interno da tag <h1> com a classe consumption-text **/
              contentString = contentString.replace(
                /(<h1[^>]*class=["']consumption-text["'][^>]*>)(.*?)(<\/h1>)/,
                `$1${individualConsumption} kWh$3`  // Adiciona o texto com valor da medição e a unidade de medida no medidor
              );

              /** Atualiza apenas o valor exibido na tag <p> para MEDIÇÃO **/
              contentString = contentString.replace(
                /(<b[^>]*>MEDIÇÃO<\/b>:\s*)([^<]*)(<\/p>)/,
                `$1${individualConsumption} kWh$3` //Adiciona o texto com o valor da medição e a unidade de medida no campo "MEDIÇÃO"
              );
            } else {
              /** Verifica se o tipo de aplicação é water ou gas (SGPULSO) para atualizar o valor da medição com a unidade de medida corretamente **/
              if (this.selectedMarker.get("applications") === 'water' || this.selectedMarker.get("applications") === 'gas') {

                individualConsumption = consumptionTotal;

                /** Atualiza apenas o texto interno da tag <h1> com a classe consumption-text **/
                contentString = contentString.replace(
                  /(<h1[^>]*class=["']consumption-text["'][^>]*>)(.*?)(<\/h1>)/,
                  `$1${individualConsumption} m³$3` // Adiciona o texto com valor da medição e a unidade de medida no medidor
                );

                /** Atualiza apenas o valor exibido na tag <p> para MEDIÇÃO **/
                contentString = contentString.replace(
                  /(<b[^>]*>MEDIÇÃO<\/b>:\s*)([^<]*)(<\/p>)/,
                  `$1${individualConsumption} m³$3` //Adiciona o texto com o valor da medição e a unidade de medida no campo "MEDIÇÃO"
                );
              }
              /// Para ser utilizado nos IP'S (light)
              // else {

              //   individualConsumption = proportionalPower;

              //   /** Atualiza apenas o texto interno da tag <h1> com a classe consumption-text **/
              //   contentString = contentString.replace(
              //     /(<h1[^>]*class=["']consumption-text["'][^>]*>)(.*?)(<\/h1>)/,
              //     `$1${individualConsumption} m³$3` // Adiciona o texto com valor da medição e a unidade de medida no medidor
              //   );

              //   /** Atualiza apenas o valor exibido na tag <p> para MEDIÇÃO **/
              //   contentString = contentString.replace(
              //     /(<b[^>]*>MEDIÇÃO<\/b>:\s*)([^<]*)(<\/p>)/,
              //     `$1${individualConsumption} m³$3`
              //   ); //Adiciona o texto com o valor da medição e a unidade de medida no campo "MEDIÇÃO"
              // }
            }
          }
          /** Atualiza o infowindow com as informações de medição e a unidade de medida correta **/
          this.infoWindowInstallation.setContent(contentString);
        }

        /** Atualiza o valor da propriedade do consumo **/
        this.individualConsumption = individualConsumption;

        /** Caso ocorra algum erro **/
      }), error: ((error: any) => {

        /** Exibe o erro no console **/
        console.log("Error on getConsumptionData", error);

        /** Atualiza o valor da propriedade de consumo **/
        this.individualConsumption = 'Não informado';

        /** Atualiza apenas o texto interno da tag <h1> com a classe consumption-text **/
        contentString = contentString.replace(
          /(<h1[^>]*class=["']consumption-text["'][^>]*>)(.*?)(<\/h1>)/,
          `$1${this.individualConsumption}$3` // Adiciona o texto 'Não informado' no medidor
        );

        /** Atualiza apenas o valor exibido na tag <p> para MEDIÇÃO **/
        contentString = contentString.replace(
          /(<b[^>]*>MEDIÇÃO<\/b>:\s*)([^<]*)(<\/p>)/,
          `$1${this.individualConsumption}$3`// Adiciona o texto 'Não informado' para campo de "MEDIÇÃO"
        );

        /** Atualiza o infowindow com a informação da medição **/
        this.infoWindowInstallation.setContent(contentString);
      })
    });
  }

  // Função responsável por popular a lista de caixas de circuito
  private getCircuitBoxes() {

    // Retira os caixas de circuito renderizados do mapa
    this.circuitBoxMarkers.forEach((marker: google.maps.Marker) => {
      marker.setMap(null);
    });

    // Fecha o wheel(caso esteja aberto)
    this.wheelView = false;

    // Limpa a lista de caixas de circuito
    this.circuitBoxMarkers = [];

    let serialNumberList: Array<string> = new Array<string>();

    this.mapjsService.isCompanyInRoles().subscribe({
      next: (role: any) => {
        // Verifica se o companyId está na lista de permissões
        const hasPermission = this.userBelongsCompany(role)
        if (hasPermission) {
          // Faz a requisição(Foi necessário fazer a conversão do companyId para decimal)
          this.subscriptions = this.mapjsService.getCircuitBox(window.atob(this.companyId!)).subscribe({
            // Tratamento da resposta de sucesso
            next: (response: any) => {
              response.features.forEach((circuitbox: any) => {

                // Armazena as caixas de circuito para serem utilizados na renderização e manipulação do mapa
                this.allCircuitBoxes.push(
                  new CircuitBox(
                    circuitbox.properties?.id,
                    circuitbox.properties?.reference,
                    circuitbox.properties?.device_serial_number,
                    circuitbox.properties?.device_mac_ipc,
                    circuitbox.properties?.device_mac_corte,
                    circuitbox.properties?.device_mac_gateway,
                    circuitbox.properties?.device_serial_ipc,
                    circuitbox.properties?.device_serial_corte,
                    circuitbox.properties?.device_serial_gateway
                  )
                );

                this.createCircuitBoxMarker(circuitbox);

                if (circuitbox.properties.device_serial_gateway != null && circuitbox.properties.device_serial_gateway != undefined) {
                  serialNumberList.push(circuitbox.properties.device_serial_gateway);
                }
              });
            },
            error: (error: any) => {
              console.log("Error on circuitbox query", error);
            }
          })

        } else {
          // Mostra uma mensagem de erro se o companyId não estiver na lista de roles
          Swal.fire({
            icon: 'error',
            title: this.translateService.instant('map.error-access-denied-company-label'),
            text: this.translateService.instant('map.error-access-denied-company-text'),
          });
        }
      },
      error: (error: any) => {
        // Mostra uma mensagem de erro se a chamada para obter permissões falhar
        Swal.fire({
          icon: 'error',
          title: this.translateService.instant('map.error-permission-map-label'),
          text: this.translateService.instant('map.error-permission-map-text'),
        });
        console.log("Error on permission check", error);
      }
    });
  }

  // Função responsável por criar os markers de caixas de circuito
  private createCircuitBoxMarker(circuitbox: any) {

    // Cria um marker para adicionar na lista posteriormente
    let marker: google.maps.Marker = new google.maps.Marker({
      position: {
        lat: circuitbox.geometry.coordinates[1],
        lng: circuitbox.geometry.coordinates[0]
      },
      icon: {
        url: '../../../../assets/mark-icons/circuit-box-red.png',
        size: new google.maps.Size(55, 55),
        scaledSize: new google.maps.Size(55, 55),
      },
      title: circuitbox.properties.reference, // Define um título
      clickable: true, // Permite que o marker seja clicado
      map: this.map, // Adiciona o marker ao mapa
      zIndex: 2
    });

    marker.set('id', circuitbox.properties.id);
    marker.set('ipc_serial', circuitbox.properties.device_serial_ipc);
    marker.set('corte_serial', circuitbox.properties.device_serial_corte);
    marker.set('device_serial_number', circuitbox.properties.device_serial_gateway);
    marker.set('ipc_mac', circuitbox.properties.device_mac_ipc);
    marker.set('gateway_mac', circuitbox.properties.device_mac_gateway);
    marker.set('corte_mac', circuitbox.properties.device_mac_corte);

    /** Realiza requisição de gateways para capturar o id do gateway da caixa de circuito **/
    this.mapjsService.getGatewayId(this.companyId, circuitbox.properties.device_serial_gateway).valueChanges.subscribe({
      next: ((res: any) => {
        res.data.gatewayInstallation.edges.forEach((gateway: any) => {
          this.gatewaysCircuitBox.push(
            new Gateway(
              gateway?.node?.id,
              gateway?.node?.reference,
              gateway?.node?.serialNumber
            )
          );
          /** Salva o id do gateway em uma propriedade global **/
          marker.set('gateway_id', gateway?.node?.id)
        })
        /** Caso ocorra algum erro, exibe ***/
      }), error: (error: any) => {
        console.log("Error on gatewaysId query", error);
      }
    });

    // Adiciona o Listener de click no marker
    marker.addListener('click', async () => {

      let status: string;

      /** Tenta realizar a requisição que retorna o status do ipc antes de prosseguir com o código **/
      try {

        /** Realiza a requisição do status, passando o mac do ipc referente a caixa de circuito selecionada **/
        let response: any = await firstValueFrom(this.mapjsService.getStatus(circuitbox.properties.device_mac_ipc))

        /** Verificação do status retornado **/
        marker.set('circuit-status', response?.status === 1 ? true : false);

        /** Formatação do texto a ser exibido na janela de informação **/
        status = marker.get('circuit-status') ? "Ligado" : "Desligado";

        /** Captura de erros **/
      } catch (error) {
        /** Caso ocorra algum erro, exibe no console do navegador **/
        console.error("erro", error)
        /** Define o texto do status que será exibido na janela de informação **/
        status = 'Sem registro'
      }

      // Se algum marker foi selecionado antes é retirada a animação
      if (this.selectedMarker) {
        this.selectedMarker.setAnimation(null);
        // Remove os markers do mapa
        this.circuitBoxEquipments.forEach((marker: google.maps.Marker) => {
          marker.setMap(null);
        });
      }

      /// Puxa a última transmissão do gateway
      let lastTransmission = marker.get('lastTransmission') ? marker.get('lastTransmission') : "Sem registro";

      // Fecha o whhel(caso esteja aberto)
      this.wheelView = false;

      // Atualiza a variavel do marker selecionado
      this.selectedMarker = marker;

      // Atualiza a InfoWindow
      this.infoWindowCircuitBox.setContent(`
        <div style="display: flex; justify-content: center; flex-direction: column; font-family: "Play"; src: url("src\assets\fonts\Play-Regular.ttf");"></div>
          <h1 style="font-size: 16px; color: #4c942a; font-weight: bold;">Informações da Caixa de Circuito</h1>
          <h3 style="font-weight: bold;">${marker.getTitle()}</h3>
          <p style="font-weight: bold; font-size: 12px;"><span style="font-size: 14px; font-family:'Play'; src: url('src\assets\fonts\Play-Regular.ttf'); color: #4c942a;">STATUS</b>: ${status} </p>
          <p style="font-weight: bold; font-size: 12px;"><span style="font-size: 14px; font-family:'Play'; src: url('src\assets\fonts\Play-Regular.ttf'); color: #4c942a;">Última transmissão</b>: ${lastTransmission}</p>
          <button class="btn btn-success" id="get-circuit-equip">Exibir Equipamentos</button>`
      );

      // abre a janela de informações no marker
      this.infoWindowCircuitBox.open(this.map, marker);

      // Fecha a janela de informações(Caso esteja aberta)
      this.infoWindowInstallation.close();

      // Fecha a janela de informações(Caso esteja aberta)
      this.infoWindowGateway.close();

      // configura a animação do marker
      marker.setAnimation(google.maps.Animation.BOUNCE);

      // redefine as opções do mapa para os valores do marker selecionado
      this.map.setOptions({
        center: new google.maps.LatLng(marker.getPosition()!),
        zoom: 18
      });
    });

    // Adicione o ouvinte de evento para o clique com botão direito no marcador
    marker.addListener("rightclick", () => {

      // Retira a animação do marker selecionado anteriormente
      if (this.selectedMarker) {
        this.selectedMarker.setAnimation(null);
      }

      // configura a animação do marker
      marker.setAnimation(google.maps.Animation.BOUNCE);

      // Atualiza a variavel do marker selecionado
      this.selectedMarker = marker;

      // Fecha a janela de infromações do concentrador
      this.infoWindowGateway.close();

      // Fecha a janela de informações da instalação
      this.infoWindowInstallation.close();

      // Abre o wheel
      this.wheelView = true;

      // redefine as opções do mapa para os valores do marker selecionado
      this.map.setOptions({
        center: new google.maps.LatLng(marker.getPosition()!),
        zoom: 15
      });
    });

    // redefine as opções do mapa para os valores do marker selecionado
    this.map.setOptions({
      center: new google.maps.LatLng(marker.getPosition()!),
      zoom: 10
    });

    this.circuitBoxMarkers.push(marker);
  }

  private createGatewayMarker(gatewayInfo: any) {
    /** Variável utilizada p/ armazenar o caminho do ícone do gateway **/
    let urlImg: any;

    /** Subscrição para o subject que define quando o marker deverá ser atualizado p/ cor azul **/
    this.mapjsService.markerMapSubject$.subscribe((value) => {
      /** Caso o subject emita algum valor (true) **/
      if (value) {
        /** Define a cor dos markers dos gateways p/ azul **/
        urlImg = '../../../../assets/mark-icons/gateway-verde.png'
        /** Caso não emita nenhum valor **/
      } else {
        /** Define a cor dos markers dos gateways p/ preto **/
        urlImg = '../../../../assets/mark-icons/gateway-black.png'
      }
    })

    // Cria um marker para adicionar na lista posteriormente
    let marker: google.maps.Marker = new google.maps.Marker({

      position: {
        lat: gatewayInfo.geometry.coordinates[1],
        lng: gatewayInfo.geometry.coordinates[0]
      },
      icon: {
        url: urlImg,
        size: new google.maps.Size(55, 55),
        scaledSize: new google.maps.Size(55, 55),
      },
      title: gatewayInfo.properties.reference, // Define um título
      clickable: true, // Permite que o marker seja clicado
      map: this.map, // Adiciona o marker ao mapa
      zIndex: 2
    });

    // Armazena os dados do concentrador em propriedades do marker
    marker.set('id', gatewayInfo.properties.id);
    marker.set('device_mac', gatewayInfo.properties.device_mac);
    marker.set('division_id', gatewayInfo.properties.division_id);
    marker.set('device_serial_number', gatewayInfo.properties.device_serial_number)

    // Adiciona o Listener de click no marker
    marker.addListener('click', () => {
      // Se algum marker foi selecionado antes é retirada a animação
      if (this.selectedMarker) {
        this.selectedMarker.setAnimation(null);
      }

      // Armazena a referência do concentrador
      let divisionReference = this.allDivisions.find(division => division.id === gatewayInfo.properties.division_id);

      // Armazena a sub divisão do concentrador
      let division = divisionReference ? divisionReference!.reference : "Não possui";

      // Puxa a última transmissão do gateway
      let lastTransmission = marker.get('lastTransmission') ? marker.get('lastTransmission') : "Sem registro";

      // Puxa o total de instalações
      let totalInstallations = marker.get('num_installations') ? marker.get('num_installations') : "Não informado";

      // Fecha o whhel(caso esteja aberto)
      this.wheelView = false;

      // Atualiza a variavel do marker selecionado
      this.selectedMarker = marker;

      // Fecha a janela de informações(Caso esteja aberta)
      this.infoWindowInstallation.close();

      // Atualiza a InfoWindow
      this.infoWindowGateway.setContent(`
      <div
      style="display: flex; justify-content: center; flex-direction: column; font-family: "Play"; src: url("src\assets\fonts\Play-Regular.ttf");"></div>
          <h1 style="font-size: 16px; color: #4c942a; font-weight: bold;">Informações do Concentrador</h1>
          <h3 style="font-weight: bold;">${marker.getTitle()}</h3>
          <p style="font-weight: bold; font-size: 12px;"><span style="font-size: 14px; font-family:'Play'; src: url('src\assets\fonts\Play-Regular.ttf'); color: #4c942a;">SUB DIVISÃO</b>: ${division}</p>
          <p style="font-weight: bold; font-size: 12px;"><span style="font-size: 14px; font-family:'Play'; src: url('src\assets\fonts\Play-Regular.ttf'); color: #4c942a;">TOTAL DE PONTOS</b>: ${totalInstallations}</p>
          <p style="font-weight: bold; font-size: 12px;"><span style="font-size: 14px; font-family:'Play'; src: url('src\assets\fonts\Play-Regular.ttf'); color: #4c942a;">Última transmissão</b>: ${lastTransmission}</p>
          <button
          class="btn btn-success"
          id="get-installations"
          >TRAZER INSTALAÇÕES</button>`);

      // abre a janela de informações no marker
      this.infoWindowGateway.open(this.map, marker);

      // configura a animação do marker
      marker.setAnimation(google.maps.Animation.BOUNCE);

      // redefine as opções do mapa para os valores do marker selecionado
      this.map.setOptions({
        center: new google.maps.LatLng(marker.getPosition()!),
        zoom: 15
      });
    });

    // Adicione o ouvinte de evento para o clique com botão direito no marcador
    marker.addListener("rightclick", () => {

      // Retira a animação do marker selecionado anteriormente
      if (this.selectedMarker) {
        this.selectedMarker.setAnimation(null);
      }

      // configura a animação do marker
      marker.setAnimation(google.maps.Animation.BOUNCE);

      // Atualiza a variavel do marker selecionado
      this.selectedMarker = marker;

      // Fecha a janela de infromações do concentrador
      this.infoWindowGateway.close();

      // Fecha a janela de informações da instalação
      this.infoWindowInstallation.close();

      // Abre o wheel
      this.wheelView = true;

      // redefine as opções do mapa para os valores do marker selecionado
      this.map.setOptions({
        center: new google.maps.LatLng(marker.getPosition()!),
        zoom: 15
      });
    });

    // redefine as opções do mapa para os valores do marker selecionado
    this.map.setOptions({
      center: new google.maps.LatLng(marker.getPosition()!),
      zoom: 10
    });
    this.gatewayMarkers.push(marker);
  }

  /** 
   *  Métodos relacionados aos gateways.
   * **/

  // Filtra os concentradores p/ serem renderizados no mapa
  private getGateways() {

    // Retira os concentradores renderizados do mapa
    this.gatewayMarkers.forEach((marker: google.maps.Marker) => {
      marker.setMap(null);
    });

    // Fecha o wheel(caso esteja aberto)
    this.wheelView = false;

    // Limpa a lista de concentradores
    this.gatewayMarkers = [];

    let serialNumberList: Array<string> = new Array<string>();

    this.mapjsService.isCompanyInRoles().subscribe({
      next: (role: any) => {

        // Verifica se o companyId está na lista de permissões
        const hasPermission = this.userBelongsCompany(role)
        if (hasPermission) {
          // Faz a requisição(Foi necessário fazer a conversão do companyId para decimal)
          this.subscriptions = this.mapjsService.getGateways(window.atob(this.companyId!)).subscribe({
            // Tratamento da resposta de sucesso
            next: (response: any) => {
              // Cria um set para comparar os gateways cadastrados com os gateways da caixa de circuito mais abaixo
              const circuitBoxGateways = new Set(this.circuitBoxMarkers.map(gateway => gateway.get('device_serial_number')));

              response.features.forEach((gateway: any) => {

                // Armazena os concentradores para serem utilizados na renderização e manipulação do mapa
                this.allGateways.push(
                  new Gateway(
                    gateway.properties?.id,
                    gateway.properties?.reference,
                    gateway.properties?.device_serial_number
                  )
                );
                // impede que o marker seja criado caso esse gateway pertença a uma caixa de circuitos
                if (!circuitBoxGateways.has(gateway.properties?.device_serial_number)) {
                  this.createGatewayMarker(gateway);
                }

                if (gateway.properties.device_serial_number != null && gateway.properties.device_serial_number != undefined) {
                  serialNumberList.push(gateway.properties.device_serial_number);
                }

              });

              this.watchGatewaysFilter(serialNumberList);
            },
            error: (error: any) => {
              console.log("Error on gateways query", error);
            }
          })

        } else {
          // Mostra uma mensagem de erro se o companyId não estiver na lista de roles
          Swal.fire({
            icon: 'error',
            title: this.translateService.instant('map.error-access-denied-company-label'),
            text: this.translateService.instant('map.error-access-denied-company-text'),
          });
        }
      },
      error: (error: any) => {
        // Mostra uma mensagem de erro se a chamada para obter permissões falhar
        Swal.fire({
          icon: 'error',
          title: this.translateService.instant('map.error-permission-map-label'),
          text: this.translateService.instant('map.error-permission-map-text'),
        });
        console.log("Error on permission check", error);
      }
    });
  }

  // Função responsável por manipular os ícones de concentrador de acordo com o status
  private gatewayStatusHandler(gatewayTransmission: GatewayManager) {
    // Recebe o index do marker que possuí o mesmo serial da mensagem
    const circuitMarker = this.circuitBoxMarkers.findIndex(marker => this.compareGateways(gatewayTransmission.serialNumber, marker));

    const gatewayMarker = this.gatewayMarkers.findIndex(marker => this.compareGateways(gatewayTransmission.serialNumber, marker));

    const ovpnTimeDiff = this.formatDatetime(gatewayTransmission.ovpnDateTime?.toString()!)

    const lastTransmission = tzConvertUTC2LocalMap('' + gatewayTransmission.ovpnDateTime)

    // Verifica se o gateway correspondente é de uma caixa de circuito
    if (circuitMarker != -1) {
      let icon;

      this.circuitBoxMarkers[circuitMarker].set('lastTransmission', lastTransmission);
      this.circuitBoxMarkers[circuitMarker].set('transmission', gatewayTransmission);
      this.circuitBoxMarkers[circuitMarker].set('num_installations', gatewayTransmission.devices?.length);

      // Lógica de verificação do ícone que será aplicado levando em conta apenas o status
      if (ovpnTimeDiff! > 1) {

        icon = {
          url: '../../../../assets/mark-icons/circuit-box-red.png',
          size: new google.maps.Size(55, 55),
          scaledSize: new google.maps.Size(55, 55)
        }
        this.circuitBoxMarkers[circuitMarker].set('status', "offline");
      } else {
        icon = {
          url: '../../../../assets/mark-icons/circuit-box-green.png',
          size: new google.maps.Size(55, 55),
          scaledSize: new google.maps.Size(55, 55)
        }

        this.circuitBoxMarkers[circuitMarker].set('status', "online");
      }

      this.circuitBoxMarkers[circuitMarker].setIcon(icon);

    } else {
      // Caso seja encontrado um marker com o mesmo serial number da mensagem
      if (gatewayMarker != -1) {

        let icon;
        const ovpnTimeDiff = this.formatDatetime(gatewayTransmission.ovpnDateTime?.toString()!)
        const lastTransmission = tzConvertUTC2LocalMap('' + gatewayTransmission.ovpnDateTime)

        this.gatewayMarkers[gatewayMarker].set('lastTransmission', lastTransmission);
        this.gatewayMarkers[gatewayMarker].set('macs', gatewayTransmission.devices);
        this.gatewayMarkers[gatewayMarker].set('num_installations', gatewayTransmission.devices?.length);
        this.gatewayMarkers[gatewayMarker].set('transmission', gatewayTransmission);

        /** Subscrição para o subject que define quando o marker do gateway deverá ser atualizado p/ cor verde **/
        this.mapjsService.markerMapSubject$.subscribe((value) => {

          /** Caso o subject emita algum valor (true) **/
          if (value) {
            /** Define a cor dos markers dos gateway p/ verde **/
            icon = {
              url: '../../../../assets/mark-icons/gateway-verde.png',
              size: new google.maps.Size(55, 55),
              scaledSize: new google.maps.Size(55, 55)
            }
            /** Se nenhum valor for emitido, a cor dos gateways permanecerão com o padrão. **/
          } else {

            // Lógica de verificação do ícone que será aplicado levando em conta apenas o status
            if (ovpnTimeDiff! > 1) {
              icon = {
                url: '../../../../assets/mark-icons/gateway-red-light-theme.png',
                size: new google.maps.Size(55, 55),
                scaledSize: new google.maps.Size(55, 55)
              }
              this.gatewayMarkers[gatewayMarker].set('status', "offline");
            } else {
              icon = {
                url: '../../../../assets/mark-icons/gateway-verde.png',
                size: new google.maps.Size(55, 55),
                scaledSize: new google.maps.Size(55, 55)
              }
              this.gatewayMarkers[gatewayMarker].set('status', "online");
            }
          }
        })

        this.gatewayMarkers[gatewayMarker].setIcon(icon);
      }
    }
  }

  // Filtra os concentradores p/ serem renderizados no mapa
  private filterGatewayIcon(gateways: any[]) {

    this.gatewayMarkers.forEach(marker => {

      const selectedGateways = gateways.map(String);
      const isVisible = selectedGateways.includes(marker.get('id').toString());

      if (isVisible) {
        marker.setVisible(true);
      } else {
        marker.setVisible(false);
      }
    });
  }

  // Limpa o filtro e mostra os gateways novamente
  public resetGateways() {

    // Remove os markers do mapa
    this.markers.forEach((marker: google.maps.Marker) => {
      marker.setMap(null);
    });

    this.markers = [];

    this.gatewayMarkers.forEach(marker => {
      marker.setVisible(true);
    });

    this.circuitBoxMarkers.forEach(marker => {
      marker.setVisible(true);
    });

    this.circuitBoxEquipments.forEach(marker => {
      marker.setMap(null);
    })

    this.circuitBoxEquipments = [];

  }

  private compareGateways(messageSerial: any, marker: any) {
    const markerSerial = marker.get('device_serial_number');
    return markerSerial == messageSerial;
  }

  // Função criada para filtrar informações do gateway, até o momento só STatus
  // Mas outros filtros podem ser adicionados nessa mesma função
  public filterGatewaysInfo() {

    let allGateways = this.gatewayMarkers;
    let selectedStatus = this.filterForm.value.gatewayStatus

    if (this.filterForm.value.gatewayStatus) {
      allGateways = allGateways.filter((marker: google.maps.Marker) => {
        return marker.get('status') == selectedStatus
      })
    }

    const filteredStatus = allGateways.map((marker: google.maps.Marker) => marker.get('id'));

    this.filterGatewayIcon(filteredStatus);
  }

  // Função que realiza o filtro no campo de concentradores da sidebar
  public filterGateways(searchTerm: any) {
    this.filteredGateways = this.allGateways.filter(gateway => gateway.reference.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1);
  }

  // Função para realizar o filtro das instalações do gateway (Usa o MAC para busca)
  private filterByGateway(gateways: any[]) {

    let geoURL = this.generateGeoserverURL();

    // Manipula a lista de macs para adicionar na URL da query
    const macsOnGateway = gateways.map(mac => `'${mac}'`).join(', ');

    geoURL += `%20and%20device_mac%20IN%20(${macsOnGateway})`;


    // Monta a URL para a geração do CSV posteriormente
    let csvURL = `${this.geoServerUrl}geoserver/${window.atob(this.companyId!).replace(/\D/g, '')}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=data&maxFeatures=150000&outputFormat=text%2Fcsv&CQL_FILTER=${geoURL}`;

    // Salva a URL em uma lista (Será usada para geração do CSV)
    this.apiLinks.push(csvURL);
    this.lastQuery = csvURL;

    this.mapjsService.isCompanyInRoles().subscribe({
      next: (role: any) => {
        // Verifica se o companyId está na lista de permissões
        const hasPermission = this.userBelongsCompany(role)
        if (hasPermission) {

          //Solicita o filtro para o Geoserver com os parametros utilizados e a ultima empresa selecionada
          //Foi necessário a conversão do base64 pois o Geoserver não trabalha com hexadecimais
          this.subscriptions = this.mapjsService.filterByElements(geoURL, window.atob(this.companyId!)).subscribe({
            next: async (installations: any) => {
              // Armazena o total de instalações
              this.totalInstallations = installations.features.length;

              // Verifica se o retorno do filtro será longo
              if (this.totalInstallations > 80000) {
                // Envia uma msn de warning ao usuário
                setTimeout(() => {
                  if (!this.isRunning) {
                    Swal.fire({
                      icon: 'warning',
                      title: this.translateService.instant('map.warning-filter-delay-label'),
                      text: this.translateService.instant('map.warning-filter-delay-text'),
                    });
                  }
                }, 100);
              }

              // Se o filtro não retornar resultados é encerrada a aplicação
              if (this.totalInstallations === 0) {
                this.filterLoading = false;
                if (!this.isRunning) {
                  Swal.fire({
                    icon: 'warning',
                    title: this.translateService.instant('map.warning-no-results-label'),
                    text: this.translateService.instant('map.warning-no-results-text'),
                  });
                }
                return
              }

              // Solicita o status e últimas trasmissões dos equipamentos filtrados
              await this.filterStatusAndLastTrasmission(this.divideURLSearchParams(installations.features, 10000), installations.features, "", "installations");

            },
            error: (error: any) => {
              this.filterLoading = false;

              // Envia uma msn de erro ao usuário
              if (!this.isRunning) {
                Swal.fire({
                  icon: 'error',
                  title: this.translateService.instant('map.generic-error-label'),
                  text: this.translateService.instant('map.generic-error-text'),
                });
              }

              console.log("Error on Installations filter", error);
            }
          })

        } else {
          // Mostra uma mensagem de erro se o companyId não estiver na lista de roles
          Swal.fire({
            icon: 'error',
            title: this.translateService.instant('map.error-access-denied-company-label'),
            text: this.translateService.instant('map.error-access-denied-company-text'),
          });
        }
      },
      error: (error: any) => {
        // Mostra uma mensagem de erro se a chamada para obter permissões falhar
        Swal.fire({
          icon: 'error',
          title: this.translateService.instant('map.error-permission-map-label'),
          text: this.translateService.instant('map.error-permission-map-text'),
        });
        console.log("Error on permission check", error);
      }
    });
  }

  // Função responsável por criar os markers dos equipamentos da caixa de circuito
  private getCircuitEquipments() {

    /** Realiza a requisição que filtra as intslações vinculadas ao gateway da caixa de circuito **/
    this.mapjsService.getInstallationByGatewayID(this.companyId, this.selectedMarker.get('gateway_id'))
      .valueChanges.subscribe({
        next: ((instalation: any) => {
          instalation?.data?.installation?.edges.forEach((circuitInstallation: any) => {
            /** Cria o markers das intalações **/
            let marker = new google.maps.Marker({
              position: {
                lat: circuitInstallation?.node.site?.geoposition?.latitude,
                lng: circuitInstallation?.node.site?.geoposition?.longitude
              },
              clickable: true,
              map: this.map,
              label: {
                text: circuitInstallation?.node?.reference,
                color: 'black',
                fontSize: '12px',
                className: 'labels',
              }
            });
            /** Seta o ícone para as instalações **/
            marker.setIcon({
              url: '../../../../assets/mark-icons/installation-blue.png', //Marker cor azul
              size: new google.maps.Size(38, 38),
              scaledSize: new google.maps.Size(38, 38)
            });

            /** Adiciona o marker ao array referente aos equipamentos da caixa de circuito **/
            this.circuitBoxEquipments.push(marker);
          })
        })
        /** Caso ocorra algum erro na requisição **/
      }), (error: any) => {
        console.log("Error on getInstallationByGatewayID", error)
      }

    // Obtém as informações de última transmissão do IPC e do Corte
    const transmissionIpc = this.status_lastTransmission[this.circuitIpc];
    const transmissionCorte = this.status_lastTransmission[this.circuitCorte];

    // Lat Long do marker da caixa de circuito (utilizado para reposiconar os outros 3 markers)
    let circuitBoxLatLong = this.selectedMarker.getPosition();

    let ipcMarker = this.createCircuitMarker(
      circuitBoxLatLong!.lat() - 0.00025,
      circuitBoxLatLong!.lng(),
      "IPC - " + this.selectedMarker.getTitle(),
      "IPC",
    );

    let corteMarker = this.createCircuitMarker(
      circuitBoxLatLong!.lat() - 0.00025,
      circuitBoxLatLong!.lng() - 0.00027,
      "Corte - " + this.selectedMarker.getTitle(),
      "Corte"
    );

    let gatewayMarker = this.createCircuitMarker(
      circuitBoxLatLong!.lat() - 0.00025,
      circuitBoxLatLong!.lng() + 0.000299,
      "Concentrador - " + this.selectedMarker.getTitle(),
      "Gateway"
    );

    gatewayMarker.set("last_transmission", this.selectedMarker.get('lastTransmission'));

    // Armazena as informações de última transmissão e status do IPC e do Corte
    ipcMarker.set('last_transmission', transmissionIpc?.last_timestamp ? transmissionIpc.last_timestamp : null);
    ipcMarker.set('device_mac', this.circuitIpc)
    ipcMarker.set('status', transmissionIpc?.status === 1 ? true : false);
    corteMarker.set('last_transmission', transmissionCorte?.last_timestamp ? transmissionCorte.last_timestamp : null);
    corteMarker.set('device_mac', this.circuitIpc)
    corteMarker.set('status', transmissionCorte?.status === 1 ? true : false);

    // Cria os ícones de acordo com a transmissão
    ipcMarker.setIcon({
      url: this.getIconByTransmission(ipcMarker, "SG-IPC"),
      size: new google.maps.Size(38, 38),
      scaledSize: new google.maps.Size(38, 38)
    });

    corteMarker.setIcon({
      url: this.getIconByTransmission(corteMarker, "SG-corte"),
      size: new google.maps.Size(38, 38),
      scaledSize: new google.maps.Size(38, 38)
    });

    // Obtém o status do concentrador através do marker de caixa de circuito
    let gatewayStatus = this.selectedMarker.get('status');

    if (gatewayStatus === 'offline') {
      gatewayMarker.setIcon({
        url: '../../../../assets/mark-icons/gateway-red-light-theme.png',
        size: new google.maps.Size(38, 38),
        scaledSize: new google.maps.Size(38, 38)
      })
    } else {
      gatewayMarker.setIcon({
        url: '../../../../assets/mark-icons/gateway-verde.png',
        size: new google.maps.Size(38, 38),
        scaledSize: new google.maps.Size(38, 38)
      })
    }

    // Adiciona os 3 markers na lista para exibir no mapa
    this.circuitBoxEquipments.push(corteMarker);
    this.circuitBoxEquipments.push(ipcMarker);
    this.circuitBoxEquipments.push(gatewayMarker);

    // listener de click é adicionado ao marker
    corteMarker.addListener('click', () => {

      // Remove animação do marker selecionado anteriormente
      if (this.selectedMarker) {
        this.selectedMarker.setAnimation(null);
      }

      // fecha wheel
      this.wheelView = false;

      // Fecha as infoWindows
      this.infoWindowGateway.close();
      this.infoWindowCircuitBox.close();
      this.infoWindowInstallation.close();
      this.infoWindowCircuitDevices.close();

      let lastTransmission = corteMarker.get('last_transmission') ? tzConvertUTC2LocalMap(corteMarker.get('last_transmission')) : "Não informado";
      let status = corteMarker.get('status') ? "Ligado" : "Desligado";

      // Atualiza variavel com o marker selecionado
      this.selectedMarker = corteMarker;

      // cria a InfoWindow
      this.infoWindowCircuitDevices.setContent(`
      <div
      style="display: flex; justify-content: center; flex-direction: column; font-family: "Play"; src: url("src\assets\fonts\Play-Regular.ttf");">
        <h1 style="font-size: 16px; color: #4c942a; font-weight: bold;">Informações do Corte</h1>
        <p style="font-weight: bold; font-size: 13px;"><b style="font-size: 14px; font-family:'Play'; src: url('src\assets\fonts\Play-Regular.ttf'); color: #4c942a;">CAIXA DE CIRCUITO</b>: ${this.selectedMarker.getTitle()}</p>
        <p style="font-weight: bold; font-size: 13px;"><b style="font-size: 14px; font-family:'Play'; src: url('src\assets\fonts\Play-Regular.ttf'); color: #4c942a;">NÚMERO DE SÉRIE </b>: ${this.corteSerialNumber}</p>
        <p style="font-weight: bold; font-size: 13px;"><b style="font-size: 14px; font-family:'Play'; src: url('src\assets\fonts\Play-Regular.ttf'); color: #4c942a;">STATUS</b>: ${status}</p>
        <p style="font-weight: bold; font-size: 13px;"><b style="font-size: 14px; font-family:'Play'; src: url('src\assets\fonts\Play-Regular.ttf'); color: #4c942a;">ÚLTIMA TRANSMISSÃO</b>:
        ${lastTransmission}</p>
      </div>`);

      this.infoWindowCircuitDevices.open(this.map, corteMarker);

      // redefine as opções do mapa para os valores do marker selecionado
      this.map.setOptions({
        center: new google.maps.LatLng(corteMarker.getPosition()!),
        zoom: 18
      });

    });

    // listener de click é adicionado ao marker
    ipcMarker.addListener('click', () => {

      // Remove animação do marker selecionado anteriormente
      if (this.selectedMarker) {
        this.selectedMarker.setAnimation(null);
      }

      // fecha wheel
      this.wheelView = false;

      // Fecha as infoWindows
      this.infoWindowGateway.close();
      this.infoWindowCircuitBox.close();
      this.infoWindowInstallation.close();
      this.infoWindowCircuitDevices.close();
      let lastTransmission = ipcMarker.get('last_transmission') ? tzConvertUTC2LocalMap(ipcMarker.get('last_transmission')) : "Não informado";
      let status = ipcMarker.get('status') ? "Ligado" : "Desligado";

      // Atualiza variavel com o marker selecionado
      this.selectedMarker = ipcMarker;

      // cria a InfoWindow
      this.infoWindowCircuitDevices.setContent(`
      <div
      style="display: flex; justify-content: center; flex-direction: column; font-family: "Play"; src: url("src\assets\fonts\Play-Regular.ttf");">
        <h1 style="font-size: 16px; color: #4c942a; font-weight: bold;">Informações do IPC</h1>
        <p style="font-weight: bold; font-size: 13px;"><b style="font-size: 14px; font-family:'Play'; src: url('src\assets\fonts\Play-Regular.ttf'); color: #4c942a;">CAIXA DE CIRCUITO</b>: ${this.selectedMarker.getTitle()}</p>
        <p style="font-weight: bold; font-size: 13px;"><b style="font-size: 14px; font-family:'Play'; src: url('src\assets\fonts\Play-Regular.ttf'); color: #4c942a;">NÚMERO DE SÉRIE</b>: ${this.ipcSerialNumber}</p>
        <p style="font-weight: bold; font-size: 13px;"><b style="font-size: 14px; font-family:'Play'; src: url('src\assets\fonts\Play-Regular.ttf'); color: #4c942a;">STATUS</b>: ${status}</p>
        <p style="font-weight: bold; font-size: 13px;"><b style="font-size: 14px; font-family:'Play'; src: url('src\assets\fonts\Play-Regular.ttf'); color: #4c942a;">ÚLTIMA TRANSMISSÃO</b>:
        ${lastTransmission}</p>
      </div>`);

      this.infoWindowCircuitDevices.open(this.map, ipcMarker);

      // redefine as opções do mapa para os valores do marker selecionado
      this.map.setOptions({
        center: new google.maps.LatLng(ipcMarker.getPosition()!),
        zoom: 18
      });
    });

    // listener de click é adicionado ao marker
    gatewayMarker.addListener('click', () => {

      // Remove animação do marker selecionado anteriormente
      if (this.selectedMarker) {
        this.selectedMarker.setAnimation(null);
      }

      // fecha wheel
      this.wheelView = false;

      // Fecha as infoWindows
      this.infoWindowGateway.close();
      this.infoWindowCircuitBox.close();
      this.infoWindowInstallation.close();
      this.infoWindowCircuitDevices.close();

      let lastTransmission = gatewayMarker.get('last_transmission') ? gatewayMarker.get('last_transmission') : "Não informado";

      // Atualiza variavel com o marker selecionado
      this.selectedMarker = gatewayMarker;

      // cria a InfoWindow
      this.infoWindowCircuitDevices.setContent(`
      <div
      style="display: flex; justify-content: center; flex-direction: column; font-family: "Play"; src: url("src\assets\fonts\Play-Regular.ttf");">
        <h1 style="font-size: 16px; color: #4c942a; font-weight: bold;">Informações do Gateway</h1>
        <p style="font-weight: bold; font-size: 13px;"><b style="font-size: 14px; font-family:'Play'; src: url('src\assets\fonts\Play-Regular.ttf'); color: #4c942a;">CAIXA DE CIRCUITO</b>: ${this.selectedMarker.getTitle()}</p>
        <p style="font-weight: bold; font-size: 13px;"><b style="font-size: 14px; font-family:'Play'; src: url('src\assets\fonts\Play-Regular.ttf'); color: #4c942a;">ÚLTIMA TRANSMISSÃO</b>:
        ${lastTransmission}</p>
      </div>`);

      this.infoWindowCircuitDevices.open(this.map, gatewayMarker);

      // redefine as opções do mapa para os valores do marker selecionado
      this.map.setOptions({
        center: new google.maps.LatLng(gatewayMarker.getPosition()!),
        zoom: 18
      });
    });
  }

  // Cria um objeto do tipo marker com as informações desejadas
  private createCircuitMarker(lat: number, lng: number, title: string, labelText: string): google.maps.Marker {
    return new google.maps.Marker({
      position: { lat, lng },
      title,
      clickable: true,
      map: this.map,
      label: {
        text: labelText,
        color: 'black',
        fontSize: '12px',
        className: 'labels',
      }
    });
  }

  // Verifica a última transmissão recebida e escohe um ícone da cor correspondente
  // no parâmetro "type" deve-se enviar o padrão dos nomes dos arquivos dos ícones
  private getIconByTransmission(marker: google.maps.Marker, type: string) {
    let formattedLastTransmission = this.formatDatetime(marker.get('last_transmission'))
    let iconUrl = "";

    /** Subscrição para o subject que define quando o marker deverá ser atualizado p/ cor azul **/
    this.mapjsService.markerMapSubject$.subscribe((value) => {
      /** Caso o subject emita algum valor (true) **/
      if (value) {
        /** Altera a cor do marker p/ azul **/
        iconUrl = '../../../../assets/mark-icons/' + type + '-blue.png';

        /** Verificação p/ chave magnética **/
        if (marker.get('has_magnetic_key') === true && formattedLastTransmission! > 1) {
          /** Altera a cor do marker p/ cinza **/
          iconUrl = '../../../../assets/mark-icons/' + type + '-blue.png';
        }
      } else {
        if (marker.get('device_mac') == null) {
          // Caso não possua mac atrelado - Icone preto
          iconUrl = '../../../../assets/mark-icons/' + type + '-black.png';
        } else {
          /** Verifica se possui ultima transmissão **/
          if (marker.get('last_transmission')) {
            if (formattedLastTransmission! > 6 && formattedLastTransmission! < 24) {
              // Caso não possua transmissão entre 6 e 24h - Icone azul
              iconUrl = '../../../../assets/mark-icons/' + type + '-blue.png';
            }
            else if (formattedLastTransmission! > 24) {
              // Caso não possua transmissão acima de 24h - Icone roxo
              iconUrl = '../../../../assets/mark-icons/' + type + '-purple.png';
            }
            /** Caso a ultima transmissão seja menor que 6 horas e status for ligado **/
            else {
              if (marker.get('status') === true) {
                // amarela
                iconUrl = '../../../../assets/mark-icons/' + type + '-yellow.png';
              }
              /** Caso contrário equipamento com status desligado**/
              else {
                // cinza
                iconUrl = '../../../../assets/mark-icons/' + type + '-gray.png';
              }
            }

            /** Caso nunca tenha transmitido **/
          } else {
            //vermelho
            iconUrl = '../../../../assets/mark-icons/' + type + '-red.png';
          }
        }
      }
    }).unsubscribe();

    return iconUrl;
  }

  // Função que realiza a paginação na requisição do botão de "Trazer-Instalações"
  private paginateGatewayFilter(macs: []): void {
    const paginationSize: number = 100;
    // Realiza novamente a requisição de 100 em 100 equipamentos
    for (let i = 0; i < macs.length; i += paginationSize) {
      const macsOnGateway: any[] = macs.slice(i, i + paginationSize);
      this.filterByGateway(macsOnGateway);
    }
  }

  // Função que retorna uma URL para a busca no geoserver de acordo com os filtros selecionados
  private generateGeoserverURL(): string {

    // URL utilizada no filtro(Geoserver)
    let url = `is_active=true`;

    // Verifica se o campo sub dibvisões foi utilizado
    if (this.filterForm.value.division && this.filterForm.value.division.length) {
      // Armazena o valor na URL
      url += `%20and%20division_id%20IN%20(${this.filterForm.value.division.join(',%20')})`;
    }

    // Verifica se o campo Concentrador foi utilizado
    if (this.filterForm.value.gateway && this.filterForm.value.gateway.length) {
      // Armazena o valor na URL
      url += `%20and%20gateway_id%20IN%20(${this.filterForm.value.gateway.join(',%20')})`;
      // Filtra apenas os ícones de gateway selecionados
      this.filterGatewayIcon(this.filterForm.value.gateway);
    }

    // Verifica se o campo id foi utilizado(utilizado apenas para favoritos)
    if (this.filterForm.value.id && this.filterForm.value.id.length) {
      // Armazena o valor na URL
      url += `%20and%20"id"%20IN%20(${this.filterForm.value.id.join(',%20')})`;
    }

    // Verifica se o campo Referência foi utilizado
    if (this.filterForm.value.reference) {
      // Armazena o valor na URL
      url += `%20and%20reference%20ILIKE%20%27%25${this.filterForm.value.reference}%25%27`;
    }

    // Verifica se o campo serial number foi utilizado
    if (this.filterForm.value.serialNumber) {
      url += `%20and%20device_serial_number%20=%20${this.filterForm.value.serialNumber}`;
    }

    // Verifica se o campo Chave Magnética foi utilizado
    if (this.filterForm.value.magneticKey != null) {
      // Armazena o valor na URL
      url += `%20and%20possible_magnectic_key%20=%20${this.filterForm.value.magneticKey}`;
    }
    // Verifica se o campo Bairro foi utilizado
    if (this.filterForm.value.district) {
      url += `%20and%20district%20ILIKE%20%27%25${this.filterForm.value.district}%25%27`
    }

    // Verifica se o campo Rua foi utilizado
    if (this.filterForm.value.street) {
      url += `%20and%20street%20ILIKE%20%27%25${this.filterForm.value.street}%25%27`
    }

    if (this.filterForm.value.circuitBox) {
      url = `%20ILIKE%20%27%25${this.filterForm.value.circuitBox}%25%27`
    }

    // armazena a URL(Poderá ser utilizada no Download em CSV)
    this.lastQuery = url;

    return url;
  }


  // Faz a paginação dos macs para o filtro de status e ultimas transmissões
  private divideURLSearchParams(params: any, maxSize: number): URLSearchParams[] {
    // Armazena os grupos de macs
    const paramsDivided: URLSearchParams[] = [];

    // Armazena o grupo de mac em formação
    let currentParams = new URLSearchParams();

    // Contador responsável por validar o tamanho de cada grupo
    let appendCount = 0;

    // A partir do filtro retornado pelo Geoserver é feito um loop com o retorno das informações
    params.forEach((installation: any) => {

      // Itera o grupo em formação e o contador
      currentParams.append('mac', installation?.properties?.device_mac);
      appendCount++;

      // Verifica se o grupo alcançou o número máximo
      if (appendCount === maxSize) {
        // Se a URLSearchParams atual exceder o tamanho máximo, adicione-a à lista
        paramsDivided.push(new URLSearchParams(currentParams.toString()));

        // Reinica o contador e o grupo em formação
        currentParams = new URLSearchParams();
        appendCount = 0;
      }
    });

    // Certifique-se de adicionar qualquer URLSearchParams parcial restante
    if (appendCount > 0) {
      // Armazena os grupos de macs
      paramsDivided.push(new URLSearchParams(currentParams.toString()));
    }

    return paramsDivided;
  }

  //Função chamada para filtros referentes aos campos(Referência, status, chave magnética, sub divisão e concentrador)
  public filterByElements() {

    // Verifica se o filtro por formas esta aplicado(caso esteja a operação é cancelada para não dar conflitos)
    if (this.filterFormsLoading) {
      return
    }

    // URL utilizada no filtro(Redis)
    let redisUrl = "";

    // Verifica se foi filtrado por status Ligado/Desligado e adiciona o parametro na consulta da API
    if (this.filterForm.value.status != null) {
      if (this.filterForm.value.status == 'on') {
        redisUrl = `?status=1`
      } else if (this.filterForm.value.status == 'off') {
        redisUrl = `?status=0`
      }
    }

    //Ativa o loading de carregamento
    this.filterLoading = true;

    let geoURL = this.generateGeoserverURL();

    this.mapjsService.isCompanyInRoles().subscribe({

      next: (role: any) => {

        // Verifica se o companyId está na lista de permissões
        const hasPermission = this.userBelongsCompany(role);

        /** Verifica se possui a permissão e o usuário está filtrando alguma caixa de circuito **/
        if (hasPermission && this.filterForm.value.circuitBox) {

          /** Fecha o wheel(caso esteja aberto) **/
          this.wheelView = false;

          /** Realiza o filtro da caixa de circuito no geoserver passando a referencia por parâmetro na url **/
          this.mapjsService.getCircuitBoxReference(window.atob(this.companyId!), geoURL).subscribe({
            next: (circuitBox: any) => {

              /**  Retira todos os gateways renderizadas no mapa (caso existam) **/
              this.gatewayMarkers.forEach((marker: google.maps.Marker) => {
                marker.setVisible(false);
              });

              /** Retira todas as instalações renderizadas no mapa **/
              this.markers.forEach((marker: google.maps.Marker) => {
                marker.setMap(null);
              });

              /** Itera para cada elemento da resposta da requisição **/
              circuitBox.features.forEach((circuitbox: any) => {

                /** Itera na lista dos markers de caixas de circuito **/
                this.circuitBoxMarkers.forEach((marker: google.maps.Marker) => {
                  /** Verifica se o numero de série do gateway a ser filtrado, é diferente do numero de série do gateway a ser pesquisado **/
                  if (marker.get('device_serial_number') !== circuitbox.properties.device_serial_gateway) {
                    /** Oculta todos os markers diferente da caixa de circuito a ser filtrada **/
                    marker.setVisible(false);

                    /** Caso contrário **/
                  } else {
                    /** Atualiza a propriedade de markers selecionados para o marker atual **/
                    this.selectedMarker = marker;
                    /** Exibe os equipamentos da caixa de circuito **/
                    this.getStatusCircuitBoxEquipments();
                    /** Desativa o loading **/
                    this.filterLoading = false;
                  }
                });
              });

              /** Variável que armazena a quantidade de elementos encontrados ao realizar o filtro **/
              let totalCircuitBox: number = circuitBox.features.length;

              /** Caso não encontre nenhuma caixa de circuito com a referência informada no filtro **/
              if (totalCircuitBox === 0) {
                /** Desativa o loading **/
                this.filterLoading = false;
                /** Verifica se não está no modo demonstração **/
                if (!this.isRunning) {
                  /** Exibe alerta para o usuário **/
                  Swal.fire({
                    icon: 'warning',
                    title: this.translateService.instant('map.warning-no-results-label'),
                    text: this.translateService.instant('map.warning-no-results-text'),
                  });
                }
              }
            },
            /** Caso ocorra algum erro na requisição que filtra a caixa de circuito por referência **/
            error: (error: any) => {

              /** Desativa o loading **/
              this.filterLoading = false;

              /** Verifica se o modo demonstração está desativado **/
              if (!this.isRunning) {
                /** Exibe um alerta na tela **/
                Swal.fire({
                  icon: 'error',
                  title: this.translateService.instant('map.generic-error-label'),
                  text: this.translateService.instant('map.generic-error-text'),
                });
              }
              /** Exibe mensagem com o erro no console do navegador **/
              console.log("Error on circuitBox filter", error);
            }
          });
          /** Caso o usuário esteja filtrando por qualquer outro campo do menu lateral **/
        } else {
          if (hasPermission) {
            // Fecha o wheel(caso esteja aberto)
            this.wheelView = false;

            // Retira todas as instalações renderizadas no mapa
            this.markers.forEach((marker: google.maps.Marker) => {
              marker.setMap(null);
            });

            /** Verifica se algum outro campo fora o de caixa de circuito possui algum valor, nos inputs do menu lateral **/
            if (
              this.filterForm?.value?.reference ||
              this.filterForm?.value?.magneticKey != null ||
              this.filterForm?.value?.gateway?.length !== 0 && this.filterForm?.value?.gateway ||
              this.filterForm?.value.status != null ||
              this.filterForm?.value?.division?.length !== 0 && this.filterForm?.value?.division ||
              this.filterForm?.value?.street ||
              this.filterForm?.value?.district
            ) {
              /** Caso algum campo esteja preenchido e filtrado, as caixas de circuito serão ocultadas no mapa  **/
              this.circuitBoxMarkers.forEach((marker: google.maps.Marker) => {
                marker.setVisible(false);
              });
            }
            /** Caso contrário, as caixas serão novamente exibidas **/
            else {
              this.circuitBoxMarkers.forEach((marker: google.maps.Marker) => {
                marker.setVisible(true);
              });
            }

            // Limpa a lista de instalações
            this.markers = [];

            // Limpa a lista de querys usadas para a geração do CSV
            this.apiLinks = [];

            //Solicita o filtro para o Geoserver com os parametros utilizados e a ultima empresa selecionada
            //Foi necessário a conversão do base64 pois o Geoserver não trabalha com exadecimais
            this.subscriptions = this.mapjsService.filterByElements(geoURL, window.atob(this.companyId!)).subscribe({
              next: async (installations: any) => {

                // Monta a URL usada para filtro do CSV
                let csvURL = `${this.geoServerUrl}geoserver/${window.atob(this.companyId!).replace(/\D/g, '')}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=data&maxFeatures=150000&outputFormat=text%2Fcsv&CQL_FILTER=${geoURL}`;

                // Adiciona a URL do CSV na lista que é usada para gerar o arquivo final
                this.apiLinks.push(csvURL);

                this.lastQuery = csvURL;

                // Armazena o total de instalações
                this.totalInstallations = installations.features.length;

                // Verifica se o retorno do filtro será longo
                if (this.totalInstallations > 80000) {
                  // Envia uma msn de warning ao usuário
                  setTimeout(() => {
                    if (!this.isRunning) {
                      Swal.fire({
                        icon: 'warning',
                        title: this.translateService.instant('map.warning-filter-delay-label'),
                        text: this.translateService.instant('map.warning-filter-delay-text'),
                      });
                    }
                  }, 100);
                }

                // Se o filtro não retornar resultados é encerrada a aplicação
                if (this.totalInstallations === 0) {
                  this.filterLoading = false;
                  if (!this.isRunning) {
                    Swal.fire({
                      icon: 'warning',
                      title: this.translateService.instant('map.warning-no-results-label'),
                      text: this.translateService.instant('map.warning-no-results-text'),
                    });
                  }
                  return
                }

                // Limpa a lista da status e últimas trasmissões
                this.status_lastTransmission = {};

                // Solicita o status e últimas trasmissões dos equipamentos filtrados
                await this.filterStatusAndLastTrasmission(this.divideURLSearchParams(installations.features, 10000), installations.features, redisUrl, "installations");
                /* if (typeof Worker !== 'undefined') {
                  // Create a new
                  const worker = new Worker(new URL('./map.worker', import.meta.url));
                  worker.onmessage = async ({ data }) => {
           
                  };
                  worker.postMessage(installations.features);
                } else {
                  // Web Workers are not supported in this environment.
                  // You should add a fallback so that your program still executes correctly.
                } */
              },
              error: (error: any) => {
                this.filterLoading = false;

                // Envia uma msn de erro ao usuário
                if (!this.isRunning) {
                  Swal.fire({
                    icon: 'error',
                    title: this.translateService.instant('map.generic-error-label'),
                    text: this.translateService.instant('map.generic-error-text'),
                  });
                }
                console.log("Error on Installations filter", error);
              }
            })
          } else {
            // Mostra uma mensagem de erro se o companyId não estiver na lista de roles
            Swal.fire({
              icon: 'error',
              title: this.translateService.instant('map.error-access-denied-company-label'),
              text: this.translateService.instant('map.error-access-denied-company-text'),
            });
          }
        }
      },
      error: (error: any) => {
        // Mostra uma mensagem de erro se a chamada para obter permissões falhar
        Swal.fire({
          icon: 'error',
          title: this.translateService.instant('map.error-permission-map-label'),
          text: this.translateService.instant('map.error-permission-map-text'),
        });
        console.log("Error on permission check", error);
      }
    });
  }

  // filtra os alertas da instalação selecionada
  private getAlerts(marker: google.maps.Marker) {

    // Faz a requisição, foi necessário converter o id da instalação para base64
    this.subscriptions = this.mapjsService.getAlert(this.companyId!, { ids: btoa(`Installation:${marker.get("id")}`) }).valueChanges.subscribe({
      // Tratamento da resposta de sucesso
      next: (response: any) => {
        response.data.alert.edges.forEach((properties: any) => {
          // Adiciona todos os alertas na lista
          this.alerts.push(new Alert(
            properties.node?.id,
            properties.node?.alertRule?.name,
            tzConvertUTC2LocalMap(properties.node?.alertDatetime),
            properties.node?.status
          ));
        });
      },
      // Tratamento de erro
      error: (error: any) => {
        // Informa uma mensagem de erro ao usuário
        this.errorLibService.errorAlert(error);
        console.log("Error on Alert query", error);
      }
    })
  }

  // Faz a requisição de consumo detalhado
  private getDetailConsuption(marker: google.maps.Marker) {
    // Aplica o loading na interface
    this.detailConsuptionLoading = true;

    // Faz a requisição a partir dos dados informados
    this.subscriptions = this.mapjsService.getDetailConsuption(
      btoa(`Installation:${marker.get('id')}`)
    ).valueChanges.subscribe({
      // Tratamento da resposta de sucesso
      next: (response: any) => {
        // Desativa o loading
        this.detailConsuptionLoading = false;

        // Atualiza o endereço
        this.address = `${response.data.node.site?.street}, ${response.data.node.site?.number}. ${response.data.node.site?.district}, ${response.data.node.site?.city}`;

        // Atualiza o serial number
        this.serialNumber = response.data.node.device?.serialNumber;
        this.lat = response.data.node.site.geoposition.latitude;
        this.long = response.data.node.site.geoposition.longitude;
      },
      error: (error: any) => {
        // Desativa o loading
        this.detailConsuptionLoading = false;

        // Informa uma mensagem de erro ao usuário
        this.errorLibService.errorAlert(error);
        console.log("Error on query DatailConsuption", error);
      }
    })
  }

  // Trata os dados necessários e faz a requisição p/ completar os campos de mais informações
  private getMoreInformations(marker: google.maps.Marker) {
    // Limpa o endereço
    this.address = "";

    // Limpa o serial number
    this.serialNumber = "";

    // Limpa a lista de alertas
    this.alerts = [];

    // Armazena a geolocalização do marker
    this.lat = "";
    this.long = "";

    // Armazena a referencia do concentrador do marker
    this.gateway = marker.get("gateway_reference");

    // Faz a requisição consumo detalhado
    this.getDetailConsuption(marker);

    // Faz a requisição de alertas
    this.getAlerts(marker);
  }

  // Fecha a aba de mais informações e e reseta o formulário de filtro
  public closeMoreInformation() {
    this.moreInfoController = false;
    this.filterForm.reset();
  }

  // Filtra todas as sub divisões p/ serem utilizadas como filtro
  private getAllDivisions() {
    this.subscriptions = this.mapjsService.getAllDivisions(this.companyId!).valueChanges.subscribe({
      next: (divisions: any) => {
        divisions.data.division.edges.forEach((division: any) => {
          this.allDivisions.push(
            new Division(
              window.atob(division.node?.id).replace(/\D/g, ''),
              division.node.reference
            )
          )
        })
      },
      error: (error: any) => {
        this.errorLibService.errorAlert(error);
        console.log("Error on division query", error);
      }
    })
  }

  /**
  * 
  * Métodos relacionados ao poligono
  * 
  **/

  // Filtro por poligono
  public filterBypolygons() {

    // Verifica se o filtro toggle esta habilitado e se não existe algum outro filtro em andamento
    if (this.polygonSlideToggle.value && !this.filterLoading) {
      // Cria a URL com as coordenadas do poligono
      let url = `is_active=true%20and%20WITHIN%20(geoposition,POLYGON((${this.formatPolygonCordinates(this.polygonCoords)})))`;
      this.urlFilter = url;

      // Habilita o o loading
      this.filterFormsLoading = true;

      // Fecha o wheel(Caso esteja aberto)
      this.wheelView = false;

      // Limpa as instalações renderizadas no mapa
      this.markers.forEach((marker: google.maps.Marker) => {
        marker.setMap(null);
      });

      // Limpa a lista de instalações
      this.markers = [];

      // Limpa a lista de URLs para a geração do CSV
      this.apiLinks = [];

      // Cria a url (Para ser usada no CSV)
      let csvURL = `${this.geoServerUrl}geoserver/${window.atob(this.companyId!).replace(/\D/g, '')}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=data&maxFeatures=150000&outputFormat=text%2Fcsv&CQL_FILTER=${url}`;

      // Salva a url em uma lista para gerar o CSV posteriormente 
      this.apiLinks.push(csvURL);
      this.lastQuery = csvURL;

      this.mapjsService.isCompanyInRoles().subscribe({
        next: (role: any) => {
          // Verifica se o companyId está na lista de permissões
          const hasPermission = this.userBelongsCompany(role)
          if (hasPermission) {
            // Solicita o filtro ao Geoserver
            this.subscriptions = this.mapjsService.filterByPolygons(url, window.atob(this.companyId!)).subscribe({
              next: async (installations: any) => {
                // Armazena o total de instalações retornadas pela API
                this.totalInstallations = installations.features.length;

                // Caso o filtro não retorne conteúdo a aplicação é encerrada
                if (this.totalInstallations === 0) {
                  this.filterFormsLoading = false;
                  return
                }

                // Se o filtro não retornar resultados é encerrada a aplicação
                if (this.totalInstallations === 0) {
                  this.filterFormsLoading = false;
                  return
                }

                // Limpa a lista da status e últimas trasmissões
                this.status_lastTransmission = {};

                await this.filterStatusAndLastTrasmission(this.divideURLSearchParams(installations.features, 10000), installations.features, "", "installations");
              },
              error: (error: any) => {
                console.log("Error on installations query", error);

                // Envia uma msn de erro ao usuário
                Swal.fire({
                  icon: 'error',
                  title: this.translateService.instant('map.generic-error-label'),
                  text: this.translateService.instant('map.generic-error-text'),
                });
              }
            });

          } else {
            // Mostra uma mensagem de erro se o companyId não estiver na lista de roles
            Swal.fire({
              icon: 'error',
              title: this.translateService.instant('map.error-access-denied-company-label'),
              text: this.translateService.instant('map.error-access-denied-company-text'),
            });
          }
        },
        error: (error: any) => {
          // Mostra uma mensagem de erro se a chamada para obter permissões falhar
          Swal.fire({
            icon: 'error',
            title: this.translateService.instant('map.error-permission-map-label'),
            text: this.translateService.instant('map.error-permission-map-text'),
          });
          console.log("Error on permission check", error);
        }
      });

    }
    // Caso o poligono não esteja selecionado e os valores de rua/bairro estejam preenchidos
    else if (!this.polygonSlideToggle.value && this.filterForm.value.street || this.filterForm.value.district) {
      this.filterByElements();
      // Caso o poligono não esteja renderizado no mapa uma msn é mostrada
    } else if (!this.filterLoading) {
      Swal.fire({
        icon: 'warning',
        title: this.translateService.instant('map.warning-polygon-filter-label'),
        text: this.translateService.instant('map.warning-polygon-filter-text'),
      });
    }
  }

  // Renderiza ou remove o poligono na tela
  public openPolygon() {

    this.wheelView = false;

    this.polygonIsSelected = !this.polygonIsSelected
    // Desabilita os campos de rua/bairro caso o filtro por poligono esteja ativo
    if (this.polygonIsSelected) {
      this.filterForm.controls['street'].disable();
      this.filterForm.controls['district'].disable();
    } else {
      this.filterForm.controls['street'].enable();
      this.filterForm.controls['district'].enable();
    }
    // Se o toggle for desmarcado o poligono é removido do mapa
    if (!this.polygonSlideToggle.value) {
      this.polygon.setMap(null);
    }
    else {
      // Adiciona o toggle no mapa
      this.polygon.setMap(this.map);

      // Pega as coordenadas do centro do mapa
      this.polygonCoords = this.getRandomLatLngs(this.map.getCenter());

      // Seta as coordenadas do poligono com o valor centralizado
      this.polygon.setPath(this.polygonCoords);

      // Adicione um ouvinte de evento para o evento 'set_at' que ocorre quando uma vértice é movida ou alterada
      google.maps.event.addListener(this.polygon.getPath(), "set_at", () => {
        // Atualiza os valores dos vetices
        this.updatePolygonPaths();
      });

      // Adicione um ouvinte de evento para o evento 'insert_at' que ocorre quando uma nova vértice é adicionada
      google.maps.event.addListener(this.polygon.getPath(), "insert_at", () => {
        // Atualiza os valores dos vertices
        this.updatePolygonPaths();
      });
      this.polygon.addListener('rightclick', () => {
        // Lógica a ser executada quando ocorre um clique direito no polígono
        console.log('Clique direito detectado no polígono');
        this.wheelView = true;
      });
      // Adicione um ouvinte de evento de clique ao mapa caso seja fora do poligono
      this.map.addListener('click', () => {
        this.wheelView = false
      })
    }
  }

  // formata as coordenadas (já calculadas) p/ string - será utilizada na requisição ao Geoserver pois o formato exigido é diferente do entregue pelo google.Maps
  private formatPolygonCordinates(coordinates: google.maps.LatLng[]): string {
    // Converter o array de coordenadas para a string desejada
    let coordinateString = coordinates.map(coord => `${coord.lng()} ${coord.lat()}`).join(',');

    // Obter o primeiro ponto para fechar o polígono
    const firstCoordinate = coordinates[0];
    coordinateString += `, ${firstCoordinate.lng()} ${firstCoordinate.lat()}`;

    return coordinateString;
  }

  // Calcula o latLong do centro do mapa e aplica um valor de distância aleatório para dar forma ao poligono
  private getRandomLatLngs(centerLatLng: any) {

    // Definir um raio para gerar coordenadas aleatórias próximas ao centro (em graus)
    const radius = 0.02;

    // Armazena as coordenadas centrais do mapa
    const latCenter = centerLatLng.lat();
    const lngCenter = centerLatLng.lng();

    // Gera as coordenadas aleatórias(próximas ao centro)
    const latOffset = (Math.random() - 0.5) * radius * 2; // Número aleatório entre -radius e +radius
    const lngOffset = (Math.random() - 0.5) * radius * 2; // Número aleatório entre -radius e +radius

    const lat1 = latCenter + latOffset;
    const lng1 = lngCenter + lngOffset;

    const lat2 = latCenter + latOffset;
    const lng2 = lngCenter - lngOffset;

    const lat3 = latCenter - latOffset;
    const lng3 = lngCenter + lngOffset;

    const lat4 = latCenter - latOffset;
    const lng4 = lngCenter - lngOffset;

    // converte as coordenas para obj google.maps.LatLng
    const randomLatLng1 = new google.maps.LatLng(lat1, lng1);
    const randomLatLng2 = new google.maps.LatLng(lat2, lng2);
    const randomLatLng3 = new google.maps.LatLng(lat3, lng3);
    const randomLatLng4 = new google.maps.LatLng(lat4, lng4);

    return [randomLatLng4, randomLatLng2, randomLatLng1, randomLatLng3];
  }

  // Atualiza as coordenadas do poligono
  private updatePolygonPaths() {
    // Limpa as coordenadas anteriores
    let newCordinates = [];

    // Para cada vertice do poligo é criado um obj google.maps.LatLng(formato utilizado pelo google.Map)
    for (let i = 0; i < this.polygon.getPath().getLength(); i++) {
      const vertex = this.polygon.getPath().getAt(i);
      newCordinates.push(
        new google.maps.LatLng(
          vertex.lat(),
          vertex.lng()
        )
      );
    }

    // Atualiza as coordenadas do poligono
    this.polygonCoords = newCordinates;
  }

  /**
   * Métodos relacionados aos favoritos. 
  **/

  // Adiciona a instalação na lista de selecionados
  public selectInstallation() {

    // Verifica se é o poligono selecionado.
    if (
      ((this.selectedMarker && this.selectedMarker.getAnimation() !== 1) ||
        this.selectedMarker === undefined)
    ) {
      // Armazena as instalações selecionadas
      this.selectedInstallations = localStorage.getItem('selectedInstallations') ? JSON.parse(localStorage.getItem('selectedInstallations')!) : [];

      let url = `is_active=true%20and%20WITHIN%20(geoposition,POLYGON((${this.formatPolygonCordinates(this.polygonCoords)})))`;

      this.mapjsService.isCompanyInRoles().subscribe({
        next: (role: any) => {
          // Verifica se o companyId está na lista de permissões
          const hasPermission = this.userBelongsCompany(role)
          if (hasPermission) {
            // Solicita o filtro ao Geoserver
            this.subscriptions = this.mapjsService.filterByPolygons(url, window.atob(this.companyId!)).subscribe({
              next: async (installations: any) => {
                installations.features.forEach((feature: any) => {
                  // Verifica se a instalação já esta selecionada
                  if (
                    !this.selectedInstallations.some((installation) => installation.id === feature.properties.id)
                  ) {

                    // Mostra a barra de selecionados
                    this.selectedInstallationsView = true;

                    // Adiciona a instalação na lista de selecionados
                    this.selectedInstallations.push(
                      new Installation(
                        feature.properties.id,
                        feature.properties.reference,
                        feature.properties.device_mac,
                        true
                      )
                    );
                  }
                });

                //Armazena apenas as instalações com a propriedade check=true(Vai como marcado para o componente)
                this.selectedOptions = this.selectedInstallations.filter(
                  (installation: Installation) => installation.check === true);
                console.log(this.selectedInstallations)
                // Salva as instalações marcadas
                localStorage.setItem('selectedInstallations', JSON.stringify(this.selectedOptions));
              },
              error: (error: any) => {
                console.log("Error on installations query", error);

                // Envia uma msn de erro ao usuário
                Swal.fire({
                  icon: 'error',
                  title: this.translateService.instant('map.generic-error-label'),
                  text: this.translateService.instant('map.generic-error-text'),
                });
              }
            });
          } else {
            // Mostra uma mensagem de erro se o companyId não estiver na lista de roles
            Swal.fire({
              icon: 'error',
              title: this.translateService.instant('map.error-access-denied-company-label'),
              text: this.translateService.instant('map.error-access-denied-company-text'),
            });
          }
        },
        error: (error: any) => {
          // Mostra uma mensagem de erro se a chamada para obter permissões falhar
          Swal.fire({
            icon: 'error',
            title: this.translateService.instant('map.error-permission-map-label'),
            text: this.translateService.instant('map.error-permission-map-text'),
          });
          console.log("Error on permission check", error);
        }
      });

      // Retorna true p/ a função this.reportSelectInstallation() interceptar o valor
      return true
    }
    else if (
      (this.selectedMarker.get('equipment_type') ||
        this.selectedMarker.get('equipment_type') === null
      ) && this.selectedMarker.getAnimation() === 1) {

      // Armazena as instalações selecionadas
      this.selectedInstallations = localStorage.getItem('selectedInstallations') ? JSON.parse(localStorage.getItem('selectedInstallations')!) : [];

      // Verifica se a instalação já esta selecionada
      if (
        !this.selectedInstallations.some((installation) => installation.id === this.selectedMarker.get('id'))
      ) {

        // Mostra a barra de selecionados
        this.selectedInstallationsView = true;

        // Adiciona a instalação na lista de selecionados
        this.selectedInstallations.push(
          new Installation(
            this.selectedMarker.get('id'),
            this.selectedMarker.getTitle()!,
            this.selectedMarker.get('device_mac'),
            true
          )
        );

        //Armazena apenas as instalações com a propriedade check=true(Vai como marcado para o componente)
        this.selectedOptions = this.selectedInstallations.filter(
          (installation: Installation) => installation.check === true);

        // Salva as instalações marcadas
        localStorage.setItem('selectedInstallations', JSON.stringify(this.selectedOptions));
      }

      // Retorna true p/ a função this.reportSelectInstallation() interceptar o valor
      return true
    }
    // Caso seja um concentrador
    else {

      // URL p/ a requisição
      let url = `is_active=true%20and%20gateway_id%20IN%20(${this.selectedMarker.get('id')}%20)`;

      // Armazena as instalações selecionadas
      this.selectedInstallations = localStorage.getItem('selectedInstallations') ? JSON.parse(localStorage.getItem('selectedInstallations')!) : [];

      this.mapjsService.isCompanyInRoles().subscribe({
        next: (role: any) => {
          // Verifica se o companyId está na lista de permissões
          const hasPermission = this.userBelongsCompany(role)
          if (hasPermission) {
            // Filtra todas as instalações do concentrador selecionado
            this.subscriptions = this.mapjsService.filterByElements(url, window.atob(this.companyId!)).subscribe({
              next: (response: any) => {
                response.features.forEach((feature: any) => {
                  // Verifica se a instalação já esta selecionada
                  if (
                    !this.selectedInstallations.some((installation) => installation.id === feature.properties.id)
                  ) {

                    // Mostra a barra de selecionados
                    this.selectedInstallationsView = true;

                    // Adiciona a instalação na lista de selecionados
                    this.selectedInstallations.push(
                      new Installation(
                        feature.properties.id,
                        feature.properties.reference,
                        feature.properties.device_mac,
                        true
                      )
                    );
                  }
                });

                //Armazena apenas as instalações com a propriedade check=true(Vai como marcado para o componente)
                this.selectedOptions = this.selectedInstallations.filter(
                  (installation: Installation) => installation.check === true);

                // Salva as instalações marcadas
                localStorage.setItem('selectedInstallations', JSON.stringify(this.selectedOptions));
              },
              // Tratamento de erro
              error: (error: any) => {
                console.log('Filter instalations by geoserver', error);

                // Informa uma mensagem de erro ao usuário
                Swal.fire({
                  icon: 'error',
                  title: this.translateService.instant('map.generic-error-label'),
                  text: this.translateService.instant('map.error-select-installation-text'),
                });

                // Retorna true p/ a função this.reportSelectInstallation() interceptar o valor
                return false
              }
            })
          } else {
            // Mostra uma mensagem de erro se o companyId não estiver na lista de roles
            Swal.fire({
              icon: 'error',
              title: this.translateService.instant('map.error-access-denied-company-label'),
              text: this.translateService.instant('map.error-access-denied-company-text'),
            });
          }
        },
        error: (error: any) => {
          // Mostra uma mensagem de erro se a chamada para obter permissões falhar
          Swal.fire({
            icon: 'error',
            title: this.translateService.instant('map.error-permission-map-label'),
            text: this.translateService.instant('map.error-permission-map-text'),
          });
          console.log("Error on permission check", error);
        }
      });

      // Retorna true p/ a função this.reportSelectInstallation() interceptar o valor
      return true
    }
  }

  public removeFavorite(favoriteSelected: any) {
    let newFavoriteList: any;
    if (this.companyId !== null) {
      let newObjFav = { ...this.favoriteList.favorites }
      newObjFav[this.companyId] = new Array(...newObjFav[this.companyId].slice(0, favoriteSelected), ...newObjFav[this.companyId].slice(favoriteSelected + 1));
      newFavoriteList = { favorites: newObjFav }
    }

    let payload = {
      favorite: newFavoriteList.favorites
    };

    this.store.dispatch((addFavorite(payload)));

    localStorage.setItem('favorites', JSON.stringify(newFavoriteList));
  }

  public viewOnFavorite(favorite: Favorite) {
    this.favoriteSelected = favorite;
    this.viewFavorite = true;
  }

  public viewOffFavorite() {
    this.viewFavorite = false;
  }

  public filterByFavorite(favorite: Favorite) {
    let installationsIds: string[] = [];

    favorite.installations.forEach((installation) => {
      installationsIds.push(installation.id);
    });

    // Fecha a sidebar de filtro
    this.closeEditionSideBar()
    // Fecha a sidebar de mais informações
    this.moreInfoController = false;
    // Reseta os dados de filtro
    this.filterForm.reset();

    // aplica o filtro referente ao concentrador selecionado
    this.filterForm.patchValue({
      id: installationsIds
    });
    this.filterByElements();
  }

  public selectLastQuery() {
    // Fecha o componente se estiver aberto
    this.selectedInstallationsView = false;
    // Remove  a instalação armazenada anteriormente 
    localStorage.removeItem('selectedInstallations')
    // Armazena as instalações selecionadas
    this.selectedInstallations = localStorage.getItem('selectedInstallations') ? JSON.parse(localStorage.getItem('selectedInstallations')!) : [];

    this.mapjsService.isCompanyInRoles().subscribe({
      next: (role: any) => {
        // Verifica se o companyId está na lista de permissões
        const hasPermission = this.userBelongsCompany(role)
        if (hasPermission) {
          // Refaz o último filtro
          this.subscriptions = this.mapjsService.filterByElements(this.urlFilter, window.atob(this.companyId!)).subscribe({
            next: (response: any) => {

              response.features.forEach((feature: any) => {

                // Verifica se a instalação já esta selecionada
                if (
                  !this.selectedInstallations.some((installation) => installation.id === feature.properties.id)
                ) {

                  // Mostra a barra de selecionados
                  this.selectedInstallationsView = true;

                  // Adiciona a instalação na lista de selecionados
                  this.selectedInstallations.push(
                    new Installation(
                      feature.properties.id,
                      feature.properties.reference,
                      feature.properties.device_mac,
                      true
                    )
                  );
                }
              });

              //Armazena apenas as instalações com a propriedade check=true(Vai como marcado para o componente)
              this.selectedOptions = this.selectedInstallations.filter(
                (installation: Installation) => installation.check === true);

              // Salva as instalações marcadas
              localStorage.setItem('selectedInstallations', JSON.stringify(this.selectedOptions));
            },
            // Tratamento de erro
            error: (error: any) => {
              console.log('Filter instalations by geoserver', error);

              // Informa uma mensagem de erro ao usuário
              Swal.fire({
                icon: 'error',
                title: this.translateService.instant('map.generic-error-label'),
                text: this.translateService.instant('map.error-select-installation-text'),
              });
            }
          })

        } else {
          // Mostra uma mensagem de erro se o companyId não estiver na lista de roles
          Swal.fire({
            icon: 'error',
            title: this.translateService.instant('map.error-access-denied-company-label'),
            text: this.translateService.instant('permissions.error-access-denied-company-text'),
          });
        }
      },
      error: (error: any) => {
        // Mostra uma mensagem de erro se a chamada para obter permissões falhar
        Swal.fire({
          icon: 'error',
          title: this.translateService.instant('map.error-permission-map-label'),
          text: this.translateService.instant('map.error-permission-map-text'),
        });
        console.log("Error on permission check", error);
      }
    });
  }

  // Fecha a sidebar de filtro
  public closeEditionSideBar() {
    this.filterController = false;
  }

  // Abre a sidebar de filtro e reseta o formulário
  public openSidebarEdit() {
    this.filterController = true;
    this.filterForm.reset();
  }

  /** Formatação do fuso horário **/
  private formatDatetime(datetimeString: any): number | null {
    // Transforma a string recebeida em um DateTime para manipulação pela Luxon
    const receivedDate = DateTime.fromISO(datetimeString)

    // Verifica a diferença para o horário atual em horas
    const differenceInHours = receivedDate.diffNow('hours').negate().hours;

    // Retorna a diferença em horas
    return differenceInHours;
  }

  //Função do modo de demonstração (em desenvolvimento)

  // Fecha a sidebar de filtro
  public closeDemonstrationMode() {
    this.demonstrationController = false;
  }

  // Abre a sidebar de filtro e reseta o formulário
  public openDemonstrationMode() {
    this.demonstrationController = true;
    this.filterForm.reset();
  }

  //Função responsável por realizar os filtros do modo de demonstração
  public demonstrationMode() {

    if (this.isRunning) {

      this.delayInSeconds = this.demonstrationForm.value.delay ? this.demonstrationForm.value.delay : 8

      if (this.index < this.gatewayMarkers.length) {

        // Adiciona as informações do gateway filtrado as variáveis exibidas no HTML
        this.selectedGateway = this.gatewayMarkers[this.index].get('transmission');
        this.gatewayName = "" + this.gatewayMarkers[this.index].getTitle();

        if (this.selectedGateway != undefined) {
          this.elementIsFound = true;
        }

        // Lista de macs que serão filtrados na hora de trazer os equipamentos comunicando pelo gateway 
        const macsToFilter = this.gatewayMarkers[this.index].get('macs') ? this.gatewayMarkers[this.index].get('macs') : []

        this.equipmentCount = macsToFilter.length;

        // Retira todas as instalações renderizadas no mapa
        this.markers.forEach((marker: google.maps.Marker) => {
          marker.setMap(null);
        });

        // Limpa a lista de instalações
        this.markers = [];

        // Limpa a lista da status e últimas trasmissões
        this.status_lastTransmission = {};

        // Filtra as instalações e o marker do gateway filtrado
        this.paginateGatewayFilter(macsToFilter);
        //this.filterGatewayIcon([this.gatewayMarkers[this.index].get('id')])

        this.filterGatewayIcon([this.gatewayMarkers[this.index].get('id')])

        // Centraliza o mapa no cursor do gateway
        this.map.setOptions({
          center: new google.maps.LatLng(this.gatewayMarkers[this.index].getPosition()!),
          zoom: 15,
        });

        this.index++;

      } else {
        // Caso chegue ao fim da lista zera o index para recomeçar do 0
        this.index = 0
      }
      // Timeout para fazer a requisição novamente todas as vezes
      setTimeout(() => {
        this.elementIsFound = false;
        this.demonstrationMode();
      }, this.delayInSeconds * 1000);
    }
  }

  // Função chamada ao clicar no botão de stop
  public stopDemonstration() {
    this.isRunning = false;
    this.index = 0;
    this.resetGateways();
  }

  // Função que da inicio ao modo de demonstração
  public startDemonstration() {
    this.isRunning = true;
    this.index = 0;
    this.resetGateways();
    this.demonstrationMode();
  }

  // Lista de links fornecidos pela API
  public apiLinks: string[] = [];

  // Solicita o o download em CSV
  public downloadCSV() {

    // Cabeçalho usado para preencher a primeira linha do CSV (adicionar campos novos aqui caso seja editado o csv)
    let header = "FID,reference,division_id,gateway_id,geoposition,is_active,status,last_transmission,device_mac,has_magnetic_key"

    // Lista para armazenar os dados CSV
    const csvData: string[] = [];
    csvData.push(header + "\n");

    // Função para baixar e armazenar os dados CSV
    async function downloadAndStore(link: string): Promise<void> {
      try {

        // acessa as credenciais que estão no  localStorage
        const username = localStorage.getItem('username');
        const password = decrypt(localStorage.getItem("geoserverPassword")!, localStorage.getItem("token")!);

        const encodedCredentials = btoa(`${username}:${password}`);

        // configura o header da requisição
        const headers = {
          'Content-Type': 'application/json',
          'Accept': '*/*',
          'Authorization': `Basic ${encodedCredentials}`
        };

        // requisão do csv usando autenticação 
        const response = await axios.get(link, { headers });

        if (response.status === 200) {
          // Remove o cabeçalho e adiciona os dados na lista
          csvData.push(response.data.replace(header + "\r\n", ""));
        } else {
          console.error(`Falha ao baixar o arquivo do link: ${link}`);
        }
      } catch (erro) {
        console.error(`Erro ao realizar a solicitação para o link: ${link}`, erro);
      }
    }

    // Baixar dados CSV para cada link
    let downloadData = async () => {
      await Promise.all(this.apiLinks.map(downloadAndStore));
    };

    // Aguardar o download dos dados

    downloadData().then(() => {
      // Salvar a string em um arquivo CSV
      const blob = new Blob(csvData, { type: 'text/csv' });

      // Cria o link para download
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = 'installations.csv';

      // Adicionar o link ao corpo do documento e simula o click para baixar o arquivo
      document.body.appendChild(downloadLink);
      downloadLink.click();

      // Remover o link do corpo do documento
      document.body.removeChild(downloadLink);

      console.log('Arquivo "installations.csv" criado com sucesso.');
    }).catch((erro) => {
      console.error('Erro ao baixar dados ou criar o arquivo CSV:', erro);
    });
  }
}